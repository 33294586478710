// React
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';

// Moment
import moment from 'moment';

// Formik
import { Formik } from 'formik';

// Services
import {
  fetchMeetingsList as fetchMeetingsListService,
  saveMeetingsParticipants as saveMeetingsParticipantsService,
  saveMeetingsBeneficiaries as saveMeetingsBeneficiariesService,
  saveMeetingSubject as saveMeetingSubjectService,
} from '@services/actionServices';
import { fetchProgramBeneficiaries as fetchProgramBeneficiariesService } from '@services/programServices';

// Actions
import { clearProgramBeneficiaries as clearProgramBeneficiariesAction } from '@actions/programActions';
import { clearMeetingsList as clearMeetingsListAction } from '@actions/actionActions';

// Data
import { WORKING_WITH_FAMILY } from '@constants/ActionBuildInType';

// Assets
import AddCircleIcon from '@mui/icons-material/AddCircle';
import NoteAddRoundedIcon from '@mui/icons-material/NoteAddRounded';

// Elements
import { Box, Grid } from '@mui/material';
import MyAccordion from '@components/atoms/Accordion/Accordion';
import Heading from '@components/atoms/Heading/Heading';
import NoteItem from '@components/atoms/NoteItem/NoteItem';
import TableTemplate from '@templates/TableTemplate';
import DialogBeneficiaryNotes from '@components/templates/DialogBeneficiaryNotes/DialogBeneficiaryNotes';
import DialogBeneficiaryMeeting from '@components/templates/DialogBeneficiaryMeeting/DialogBeneficiaryMeeting';
import MultiSelectChips from '@components/molecules/MultiSelectChips/MultiSelectChips';

// Styles
import Input from '@components/atoms/Input/Input';
import { StyledButton, StyledLink } from './ActionMeetings.styles';

// Input

// Component
const ActionMeetings = ({
  clearMeetingsList,
  fetchMeetingsList,
  meetings,
  clearProgramBeneficiaries,
  fetchProgramBeneficiaries,
  details,
  program_details,
  saveMeetingsParticipants,
  saveMeetingBeneficiaries,
  saveMeetingSubject,
}) => {
  const { id } = useParams();
  const [openDialogNoteMeeting, setOpenDialogNoteMeeting] = useState(false);
  const [openDialogBeneficiaryMeeting, setOpenDialogBeneficiaryMeeting] = useState(false);
  const [currentDate, setCurrentDate] = useState(null);
  const [currentParticipantId, setCurrentParticipantId] = useState(null);
  const [databaseBeneficiaries, setDatabaseBeneficiaries] = useState();
  const [currentBeneficiaryID, setCurrentBeneficiaryID] = useState(null);
  const [currentNoteDate, setcurrentNoteDate] = useState(null);
  const [currentOrganizations, setCurrentOrganizations] = useState([]);

  const headCells = [
    { type: 'name', label: 'Imię' },
    { type: 'surname', label: 'Nazwisko' },
  ];

  if (details && details.build_in_type !== WORKING_WITH_FAMILY) {
    headCells.push({ type: 'organization', label: 'Instytucja / organizacja' });
  }

  useEffect(() => {
    clearMeetingsList();
    clearProgramBeneficiaries();
  }, [clearMeetingsList, clearProgramBeneficiaries]);

  useEffect(() => {
    if (details && details.build_in_type !== WORKING_WITH_FAMILY) {
      const newData = [];

      if (
        meetings &&
        Object.keys(meetings).length > 0 &&
        meetings.dates &&
        meetings.dates.length > 0
      ) {
        meetings.dates.map(({ participants }) => {
          if (participants) {
            participants.map(({ organization }) => {
              if (organization && !newData.includes(organization)) {
                newData.push({
                  name: organization,
                });
              }

              return true;
            });
          }

          return true;
        });

        setCurrentOrganizations(newData);
      }
    }
  }, [meetings, details]);

  useEffect(() => {
    if (program_details && program_details.program_beneficiaries.length > 0) {
      const newData = [];

      program_details.program_beneficiaries.map((el) => {
        newData.push({
          id: el?.id,
          value: el?.id,
          name: `${el?.beneficiary_name} ${el?.beneficiary_last_name}`,
          cwp_id: el?.cwp_id,
          cwa_id: el?.cwa_id,
        });

        return true;
      });

      setDatabaseBeneficiaries(newData);
    }
  }, [program_details.program_beneficiaries]);

  const triggerFetchMeetingsList = useCallback(
    (myid) => fetchMeetingsList(myid),
    [fetchMeetingsList],
  );

  useEffect(() => {
    triggerFetchMeetingsList(id);
  }, [id, triggerFetchMeetingsList]);

  const triggerFetchProgramBeneficiaries = useCallback(
    (myid) => fetchProgramBeneficiaries(myid),
    [fetchProgramBeneficiaries],
  );

  useEffect(() => {
    if (details && details.program) {
      triggerFetchProgramBeneficiaries(details.program.id);
    }
  }, [details, details.program, triggerFetchProgramBeneficiaries]);

  return (
    meetings &&
    Object.keys(meetings).length > 0 && (
      <>
        <Box mt={3}>
          {meetings?.dates.map((el) => (
            <MyAccordion
              key={el.attendance_list_id}
              title={`Spotkanie ${moment(el.date).format('DD/MM/YYYY')} ${el.subject ?? ''}`}
            >
              <Formik
                initialValues={{
                  beneficiaries: el.beneficiaries ?? [],
                  participants: el.participants ?? [],
                  subject: el.subject ?? '',
                  notes: [],
                }}
                enableReinitialize
                onSubmit={(values) => {
                  console.log(values);
                }}
              >
                {({ values, setFieldValue }) => (
                  <>
                    <Grid container spacing={4}>
                      <Grid item xs={6}>
                        {values.beneficiaries.length > 0 &&
                          values.beneficiaries.map((item) => (
                            <Grid key={item.id} container spacing={2}>
                              <Grid item xs={3}>
                                <Heading.Subtitle2>Dotyczy Beneficjenta</Heading.Subtitle2>
                              </Grid>
                              <Grid item xs={9}>
                                <Heading.Body2>
                                  <StyledLink to={`/beneficjenci/${item.id}`}>
                                    {item.name}
                                  </StyledLink>
                                </Heading.Body2>
                              </Grid>
                              <Grid item xs={3}>
                                <Heading.Body2>Notatki</Heading.Body2>
                              </Grid>
                              <Grid item xs={9}>
                                <Box>
                                  {item.notes &&
                                    item.notes.map((it) => <NoteItem key={it.id} item={it} />)}
                                </Box>
                                <StyledButton
                                  startIcon={<NoteAddRoundedIcon />}
                                  onClick={() => {
                                    setCurrentBeneficiaryID(item);
                                    setcurrentNoteDate(el.date);
                                    setOpenDialogNoteMeeting(true);
                                  }}
                                >
                                  Dodaj notatkę
                                </StyledButton>
                              </Grid>
                            </Grid>
                          ))}
                      </Grid>
                      <Grid item xs={6}>
                        <Grid container spacing={2} style={{ marginBottom: '15px' }}>
                          <Grid item xs={8}>
                            <Input
                              type="text"
                              label="Temat spotkania"
                              variant="filled"
                              name="subject"
                              value={values.subject}
                              onChange={(e) => setFieldValue('subject', e.target.value)}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <StyledButton
                              variant="contained"
                              onClick={() =>
                                saveMeetingSubject(
                                  details.id,
                                  el.attendance_list_id,
                                  values.subject,
                                )
                              }
                            >
                              Zapisz
                            </StyledButton>
                          </Grid>
                        </Grid>
                        <MultiSelectChips
                          data={databaseBeneficiaries}
                          isEditable
                          values_data={values.beneficiaries}
                          fieldName="beneficiaries"
                          label="Wybierz beneficjentów"
                          labelChosen="Przypisani beneficjenci"
                          setFieldValue={(name, value) => {
                            return saveMeetingBeneficiaries(el.attendance_list_id, value);
                          }}
                        />
                        <div>
                          <TableTemplate
                            headCells={headCells}
                            data={values.participants}
                            noFilter
                            noSort
                            rowActions={[
                              {
                                name: 'edytuj uczestnika',
                                action: (row, index) => {
                                  setCurrentParticipantId(index);
                                  setCurrentDate(el);
                                  setOpenDialogBeneficiaryMeeting(true);
                                },
                              },
                              {
                                name: 'usuń uczestnika',
                                action: (row) => {
                                  values.participants.splice(row.id, 1);
                                  setFieldValue('participants', values.participants);
                                  saveMeetingsParticipants(
                                    el.attendance_list_id,
                                    values.participants,
                                  );
                                },
                              },
                            ]}
                          />
                        </div>
                        <StyledButton
                          startIcon={<AddCircleIcon />}
                          onClick={() => {
                            setCurrentParticipantId(null);
                            setCurrentDate(el);
                            setOpenDialogBeneficiaryMeeting(true);
                          }}
                        >
                          Dodaj uczestnika
                        </StyledButton>
                      </Grid>
                    </Grid>
                    <DialogBeneficiaryMeeting
                      open={openDialogBeneficiaryMeeting}
                      setOpenFn={setOpenDialogBeneficiaryMeeting}
                      currentDate={currentDate}
                      currentParticipantId={currentParticipantId}
                      currentOrganizations={currentOrganizations}
                      hideOrganizations={
                        !(details && details.build_in_type !== WORKING_WITH_FAMILY)
                      }
                    />
                    <DialogBeneficiaryNotes
                      beneficiaryId={currentBeneficiaryID?.id}
                      cwpid={currentBeneficiaryID?.cwp_id}
                      cwaid={currentBeneficiaryID?.cwa_id}
                      meetingData={currentBeneficiaryID?.meeting_id}
                      noteDate={currentNoteDate}
                      open={openDialogNoteMeeting}
                      setOpenFn={(v) => {
                        setOpenDialogNoteMeeting(v);
                        fetchMeetingsList(id);
                      }}
                      fetchNotesAfterSave={false}
                      buildInType={details?.build_in_type}
                    />
                  </>
                )}
              </Formik>
            </MyAccordion>
          ))}
        </Box>
      </>
    )
  );
};

ActionMeetings.propTypes = {
  clearMeetingsList: PropTypes.func,
  fetchMeetingsList: PropTypes.func,
  meetings: PropTypes.objectOf(PropTypes.any),
  clearProgramBeneficiaries: PropTypes.func,
  fetchProgramBeneficiaries: PropTypes.func,
  details: PropTypes.objectOf(PropTypes.any),
  program_details: PropTypes.objectOf(PropTypes.any),
  saveMeetingSubject: PropTypes.func,
  saveMeetingsParticipants: PropTypes.func,
  saveMeetingBeneficiaries: PropTypes.func,
};

ActionMeetings.defaultProps = {
  clearMeetingsList: null,
  fetchMeetingsList: null,
  meetings: {},
  clearProgramBeneficiaries: null,
  fetchProgramBeneficiaries: null,
  details: null,
  program_details: [],
  saveMeetingSubject: null,
  saveMeetingsParticipants: null,
  saveMeetingBeneficiaries: null,
};

const mapStateToProps = ({ actionReducer, programReducer }) => ({
  meetings: actionReducer.meetings,
  details: actionReducer.action,
  program_details: programReducer.beneficiaries,
});

const mapDispatchToProps = (dispatch) => ({
  clearMeetingsList: () => dispatch(clearMeetingsListAction()),
  fetchMeetingsList: (id) => dispatch(fetchMeetingsListService(id)),
  clearProgramBeneficiaries: () => dispatch(clearProgramBeneficiariesAction()),
  fetchProgramBeneficiaries: (id) => dispatch(fetchProgramBeneficiariesService(id)),
  saveMeetingSubject: (id, meeting_id, data) =>
    dispatch(saveMeetingSubjectService(id, meeting_id, data)),
  saveMeetingBeneficiaries: (id, data) => dispatch(saveMeetingsBeneficiariesService(id, data)),
  saveMeetingsParticipants: (id, participants) =>
    dispatch(saveMeetingsParticipantsService(id, participants)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ActionMeetings);
