// React
import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Services
import {
  fetchProgramsList as fetchProgramListService,
  fetchProgram as fetchProgramService,
  fetchProgramOrganizations as fetchProgramOrganizationsService,
  fetchProgramTaskCoordinators as fetchProgramTaskCoordinatorsService,
} from '@services/programServices';
import {
  fetchTask as fetchTaskService,
  saveTaskDescription as saveTaskDescriptionService,
  removeTask as removeTaskService,
} from '@services/taskServices';

// Actions
import { clearTaskAction } from '@actions/taskActions';
import { clearProgramAction } from '@actions/programActions';

// Formik
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';

// Data
import {
  COORDINATOR,
  PROGRAM_COORDINATOR,
  TASK_COORDINATOR,
  LECTURER_SENSITIVE_DATA,
  LECTURER,
} from '@constants/roles';

import { TaskType } from '@constants/selectLists';

// Elements
import { Grid, CardContent, CardActions, Menu, MenuItem } from '@mui/material';
import Heading from '@components/atoms/Heading/Heading';
import Input from '@components/atoms/Input/Input';
import Select from '@components/atoms/Select/Select';
import EditButton from '@components/atoms/EditButton/EditButton';
import Button from '@components/atoms/Button/Button';
import MultiSelectChips from '@components/molecules/MultiSelectChips/MultiSelectChips';
import GuardedComponent from '@components/molecules/GuardedComponent/GuardedComponent';
import DialogConfirm from '@components/templates/DialogConfirm/DialogConfirm';

// Styles
import {
  StyledLink,
  StyledInput,
  StyledButton,
  StyledMoreVertIcon,
  StyledIconButton,
  StyledCard,
  StyledBlueLink,
} from './TaskDescription.styles';
import './TaskDescription.css';

// Component
const TaskDescription = ({
  clearTask,
  fetchTask,
  clearProgram,
  fetchProgramList,
  programs,
  fetchProgram,
  program,
  fetchProgramTaskCoordinators,
  task_coordinators,
  fetchProgramOrganizations,
  program_organizations,
  saveTaskDescription,
  removeTask,
  isNew,
  details,
  me,
}) => {
  const history = useHistory();
  const { id, program_id } = useParams();
  const [isEditable, setIsEditable] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialogConfirmRemove, setOpenDialogConfirmRemove] = useState(false);

  useEffect(() => {
    clearTask();
    clearProgram();

    return () => {
      clearTask();
      clearProgram();
    };
  }, [clearTask, clearProgram]);

  const triggerFetchTask = useCallback(
    (myid) => {
      if (myid !== undefined && !isNew) {
        fetchTask(myid);
        return true;
      }
      setIsEditable(true);
      return false;
    },
    [fetchTask, isNew],
  );

  useEffect(() => {
    if (isNew) {
      setIsEditable(true);
      if (!program_id) {
        fetchProgramList();
      }
      return;
    }
    setIsEditable(false);
    triggerFetchTask(id);
  }, [id, isNew, program_id, fetchProgramList, triggerFetchTask]);

  useEffect(() => {
    if (program_id) {
      fetchProgram(program_id);
      fetchProgramOrganizations(program_id);
    }
  }, [program_id, fetchProgram, fetchProgramOrganizations]);

  useEffect(() => {
    if (task_coordinators.length === 0 && (program_id || details.program.id)) {
      fetchProgramTaskCoordinators(program_id || details.program.id);
    }
  }, [program_id, details.program.id, task_coordinators.length, fetchProgramTaskCoordinators]);

  useEffect(() => {
    if (!isNew && details.program.id && (!details.owner || details.owner.id === 0)) {
      fetchProgramOrganizations(details.program.id);
    }
  }, [isNew, details.program.id, details.owner, fetchProgramOrganizations]);

  const orgarnizations_list = isNew ? program_organizations : details.program_organizations;
  const definedProgram = program_id ? program : details.program;

  return (
    <>
      <Formik
        initialValues={{
          program: details.program && !isNew ? details.program.id : program_id,
          name: details.name && !isNew ? details.name : '',
          description: details.description && !isNew ? details.description : '',
          owner: details.owner && !isNew ? details.owner.name : '',
          coordinator: details.coordinator && !isNew ? details.coordinator : null,
          organizations: details.organizations && !isNew ? details.organizations : [],
          task_owner_id: details.owner && !isNew && details.owner.id ? details.owner.id : 0,
          task_type: details.type && !isNew ? details.type : 0,
        }}
        validationSchema={Yup.object({
          name: Yup.string()
            .min(2, 'Minimum 2 znaki')
            .max(255, 'Maksymalnie 255 znaków')
            .required('pole wymagane!'),
          program: Yup.number()
            .required('pole wymagane!')
            .test('Is positive?', 'pole wymagane!', (value) => value > 0),
          task_owner_id: Yup.number()
            .required('pole wymagane!')
            .test('Is positive?', 'pole wymagane!', (value) => value > 0),
          description: Yup.string()
            .min(2, 'Minimum 2 znaki')
            .max(1000, 'Maksymalnie 1000 znaków')
            .required('pole wymagane!'),
          task_type: Yup.number()
            .required('pole wymagane!')
            .test('Is positive?', 'pole wymagane!', (value) => value > 0),
        })}
        enableReinitialize
        onSubmit={(values) => {
          saveTaskDescription(id === undefined || isNew ? 'new' : id, values).then((data) => {
            if (data && (id === undefined || isNew) && data.data.id) {
              triggerFetchTask(data.data.id);
              history.push(`/zadania/${data.data.id}`);
            }
          });
        }}
      >
        {({ values, setFieldValue, handleSubmit, handleChange }) => (
          <>
            <Grid className="description" container alignItems="flex-start" spacing={3}>
              <Grid item xs={12} container justifyContent="flex-end">
                {!isNew && id !== undefined && (
                  <GuardedComponent
                    allowed_user_roles={[COORDINATOR]}
                    program_id={parseInt(
                      (details.program && details.program.id) ||
                        (definedProgram && definedProgram.id) ||
                        values.program,
                      10,
                    )}
                    allowed_program_roles={[PROGRAM_COORDINATOR, TASK_COORDINATOR]}
                  >
                    <EditButton isEditable={isEditable || false} setIsEditable={setIsEditable} />
                  </GuardedComponent>
                )}
              </Grid>
              <Grid item container spacing={2} xs={6}>
                <Grid item xs={12}>
                  <Heading.Subtitle2>Informacje ogólne</Heading.Subtitle2>
                </Grid>
                {definedProgram && definedProgram.id && (
                  <Grid item xs={12}>
                    <Heading.Subtitle2>
                      Zadanie jest cześcią programu{' '}
                      <StyledLink to={`/programy/${definedProgram.id}`}>
                        {definedProgram.name}
                      </StyledLink>
                    </Heading.Subtitle2>
                  </Grid>
                )}
                {programs && programs.length > 0 && (!definedProgram || !definedProgram.id) && (
                  <Grid item xs={12}>
                    <Select
                      disabled={!isEditable}
                      data={programs.map((item) => {
                        return { value: item.id, name: item.name };
                      })}
                      selectValue={values.program}
                      onChange={(field, value) => {
                        setFieldValue(field, value);
                        setFieldValue('task_owner_id', 0);
                        fetchProgramOrganizations(value).then((res) => {
                          if (res.data && res.data.length === 1) {
                            setFieldValue('task_owner_id', res.data[0].id);
                          } else {
                            const foundOrg = res.data.find((el) => {
                              if (me.user_organizations.find((o) => o.id === el.id)) {
                                return true;
                              }
                              return false;
                            });
                            setFieldValue('task_owner_id', foundOrg.id);
                          }
                        });
                        fetchProgramTaskCoordinators(value);
                      }}
                      text="program"
                      label="Nazwa Programu"
                      variant="filled"
                    />
                    <ErrorMessage name="program">
                      {(msg) => <div className="error-txt">{msg}</div>}
                    </ErrorMessage>
                  </Grid>
                )}
                <Grid item xs={6}>
                  <StyledInput
                    disabled={!isEditable || false}
                    type="text"
                    label="Nazwa Zadania"
                    variant="filled"
                    value={values.name}
                    name="name"
                    onChange={handleChange}
                    className="pogrubiona_nazwa"
                  />
                  <ErrorMessage name="name">
                    {(msg) => <div className="error-txt">{msg}</div>}
                  </ErrorMessage>
                </Grid>
                <Grid item xs={6}>
                  <Select
                    name="taskType"
                    disabled={!isEditable || false}
                    label="Kategoria zadania"
                    data={TaskType}
                    selectValue={values.task_type}
                    variant="filled"
                    text="task_type"
                    onChange={setFieldValue}
                  />
                  <ErrorMessage name="task_type">
                    {(msg) => <div className="error-txt">{msg}</div>}
                  </ErrorMessage>
                </Grid>
                <Grid item xs={6}>
                  {details &&
                  values.coordinator &&
                  details.coordinator_object &&
                  details.coordinator_object.id === values.coordinator ? (
                    <StyledCard>
                      <CardContent>
                        <Grid container>
                          <Grid item xs={8} spacing={1} container>
                            <Grid item xs={12}>
                              <Heading.Subtitle2>Koordynator zadania</Heading.Subtitle2>
                            </Grid>
                            <Grid item xs={12}>
                              <Heading.Subtitle3>
                                {details.coordinator_object.name}{' '}
                                {details.coordinator_object.surname}
                              </Heading.Subtitle3>
                            </Grid>
                            <Grid item container direction="column" xs={12}>
                              <Heading.Caption>
                                {details.coordinator_object.user_organizations.map((org) => (
                                  <div key={org.id}>{org.name}</div>
                                ))}
                              </Heading.Caption>
                            </Grid>
                          </Grid>
                        </Grid>
                      </CardContent>
                      <CardActions>
                        <Button>
                          <StyledBlueLink
                            to={`/wiadomosci-dodaj/uzytkownik/${details.coordinator_object.id}`}
                          >
                            WIADOMOŚĆ
                          </StyledBlueLink>
                        </Button>
                        <GuardedComponent
                          allowed_user_roles={[COORDINATOR]}
                          program_id={parseInt(
                            (details.program && details.program.id) ||
                              (definedProgram && definedProgram.id) ||
                              values.program,
                            10,
                          )}
                          allowed_program_roles={[
                            PROGRAM_COORDINATOR,

                            TASK_COORDINATOR,
                            LECTURER_SENSITIVE_DATA,
                            LECTURER,
                          ]}
                        >
                          <Button>
                            <StyledBlueLink to={`/uzytkownicy/${details.coordinator_object.id}`}>
                              PROFIL
                            </StyledBlueLink>
                          </Button>
                        </GuardedComponent>
                        <StyledIconButton
                          onClick={(e) => setAnchorEl(e.currentTarget)}
                          disabled={!isEditable}
                        >
                          <StyledMoreVertIcon />
                        </StyledIconButton>
                        {anchorEl && (
                          <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={() => setAnchorEl(null)}
                            getContentAnchorEl={null}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'right',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'right',
                            }}
                          >
                            <MenuItem
                              onClick={() => {
                                setAnchorEl(null);
                                setFieldValue('coordinator', null);
                              }}
                            >
                              <Heading.Body2>Zmień koordynatora</Heading.Body2>
                            </MenuItem>
                          </Menu>
                        )}
                      </CardActions>
                    </StyledCard>
                  ) : (
                    task_coordinators.length > 0 && (
                      <Select
                        name="coordinator"
                        disabled={!isEditable || false}
                        label="Koordynator zadania"
                        data={task_coordinators}
                        selectValue={values.coordinator}
                        variant="filled"
                        text="coordinator"
                        onChange={setFieldValue}
                      />
                    )
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Input
                    disabled={!isEditable || false}
                    type="text"
                    multiline
                    rows={10}
                    charCounter={1000}
                    setField={setFieldValue}
                    label="Opis zadania"
                    value={values.description}
                    variant="filled"
                    name="description"
                    onChange={handleChange}
                  />
                  <ErrorMessage name="description">
                    {(msg) => <div className="error-txt">{msg}</div>}
                  </ErrorMessage>
                </Grid>
              </Grid>
              <Grid
                item
                container
                spacing={2}
                xs={6}
                style={{ display: isNew ? 'hidden' : 'visible' }}
              >
                <Grid item xs={12}>
                  <Heading.Subtitle2>Organizacje</Heading.Subtitle2>
                </Grid>
                <Grid item xs={8}>
                  {program_organizations && (isNew || !details.owner || details.owner.id === 0) && (
                    <>
                      <Select
                        data={program_organizations.map((org) => {
                          return { value: org.id, name: org.name };
                        })}
                        label="Twórca zadania"
                        selectValue={values.task_owner_id}
                        variant="filled"
                        text="task_owner_id"
                        onChange={setFieldValue}
                      />
                      <ErrorMessage name="task_owner_id">
                        {(msg) => <div className="error-txt">{msg}</div>}
                      </ErrorMessage>
                    </>
                  )}
                  {!isNew && details.owner && (
                    <Heading.Subtitle2>Twórca zadania: {details.owner.name}</Heading.Subtitle2>
                  )}
                </Grid>
                <Grid item xs={8}>
                  <MultiSelectChips
                    data={orgarnizations_list}
                    isEditable={isEditable}
                    values_data={values.organizations}
                    fieldName="organizations"
                    label="Dodaj organizację do zadania"
                    setFieldValue={setFieldValue}
                  />
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <GuardedComponent
                  allowed_user_roles={[COORDINATOR]}
                  program_id={parseInt(
                    (details.program && details.program.id) ||
                      (definedProgram && definedProgram.id) ||
                      values.program,
                    10,
                  )}
                  allowed_program_roles={[PROGRAM_COORDINATOR, TASK_COORDINATOR]}
                >
                  <StyledButton
                    variant="outlined"
                    onClick={handleSubmit}
                    disabled={!isEditable || false}
                  >
                    ZAPISZ
                  </StyledButton>
                  {isNew && (
                    <StyledButton onClick={() => history.push(`/zadania`)}>ANULUJ</StyledButton>
                  )}
                </GuardedComponent>
                {!isNew && (
                  <GuardedComponent
                    allowed_user_roles={[COORDINATOR]}
                    program_id={parseInt(
                      (details.program && details.program.id) ||
                        (definedProgram && definedProgram.id) ||
                        values.program,
                      10,
                    )}
                    allowed_program_roles={[PROGRAM_COORDINATOR]}
                  >
                    <StyledButton
                      variant="outlined"
                      color="secondary"
                      onClick={() => setOpenDialogConfirmRemove(true)}
                    >
                      USUŃ ZADANIE
                    </StyledButton>
                  </GuardedComponent>
                )}
              </Grid>
            </Grid>
            <DialogConfirm
              removeFunction={() =>
                removeTask(id).then(() => {
                  history.push(`/zadania`);
                })
              }
              title="Zadanie ma działania! Usunięcie zadania spowoduje również usunięcie wszystkich działań z tego zadania!"
              open={openDialogConfirmRemove}
              setOpenFn={setOpenDialogConfirmRemove}
            />
          </>
        )}
      </Formik>
    </>
  );
};

TaskDescription.propTypes = {
  clearTask: PropTypes.func,
  fetchTask: PropTypes.func,
  clearProgram: PropTypes.func,
  fetchProgramList: PropTypes.func,
  programs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  ),
  fetchProgram: PropTypes.func,
  program: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  fetchProgramTaskCoordinators: PropTypes.func,
  task_coordinators: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  ),
  fetchProgramOrganizations: PropTypes.func,
  program_organizations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  ),
  saveTaskDescription: PropTypes.func,
  removeTask: PropTypes.func,
  isNew: PropTypes.bool,
  me: PropTypes.shape({
    id: PropTypes.number,
    user_organizations: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      }),
    ),
  }),
  details: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    description: PropTypes.string,
    owner: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    program_organizations: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      }),
    ),
    organizations: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      }),
    ),
    coordinator: PropTypes.number,
    coordinator_object: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      surname: PropTypes.string,
      user_programs: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
        }),
      ),
      user_organizations: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
        }),
      ),
    }),
    type: PropTypes.shape({
      value: PropTypes.number,
      name: PropTypes.string,
    }),
    program: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  }),
};

TaskDescription.defaultProps = {
  clearTask: null,
  fetchTask: null,
  clearProgram: null,
  fetchProgramList: null,
  programs: [],
  fetchProgram: null,
  program: null,
  fetchProgramTaskCoordinators: null,
  task_coordinators: [],
  fetchProgramOrganizations: null,
  program_organizations: null,
  isNew: false,
  saveTaskDescription: null,
  removeTask: null,
  me: {
    id: null,
    user_organizations: [],
  },
  details: {
    id: null,
    name: '',
    description: '',
    owner: { id: null, name: '' },
    program: { name: '', id: null },
    program_organizations: [],
    organizations: [],
    coordinator: null,
    type: {
      value: null,
      display: '',
    },
  },
};

const mapStateToProps = ({ taskReducer, programReducer, userReducer }) => ({
  programs: programReducer.programs,
  program: programReducer.program,
  task_coordinators: programReducer.task_coordinators,
  program_organizations: programReducer.program_organizations,
  details: taskReducer.task,
  me: userReducer.me,
});

const mapDispatchToProps = (dispatch) => ({
  clearTask: () => dispatch(clearTaskAction()),
  fetchTask: (id) => dispatch(fetchTaskService(id)),
  clearProgram: () => dispatch(clearProgramAction()),
  fetchProgramList: () => dispatch(fetchProgramListService()),
  fetchProgram: (id) => dispatch(fetchProgramService(id)),
  fetchProgramTaskCoordinators: (id) => dispatch(fetchProgramTaskCoordinatorsService(id)),
  fetchProgramOrganizations: (id) => dispatch(fetchProgramOrganizationsService(id)),
  saveTaskDescription: (id, data) => dispatch(saveTaskDescriptionService(id, data)),
  removeTask: (id) => dispatch(removeTaskService(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TaskDescription);
