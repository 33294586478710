// Elements
import { ListItemText, ListItemIcon } from '@mui/material';

// Styles
import styled, { css } from 'styled-components';
import { device } from '@theme/mainTheme';

export const StyledListItemText = styled(ListItemText)`
  && {
    span {
      font-size: ${({ theme }) => theme.fontSize.body2};
      color: ${({ theme }) => theme.color.darkGray};
    }
  }
`;

export const StyledListItemIcon = styled(ListItemIcon)`
  && {
    svg {
      font-size: 2.5rem;
    }
  }
`;

export const StyledWrapper = styled.div`
  && {
    transition: 0.4s;
    position: relative;
    ${({ hide }) =>
      hide &&
      css`
        margin-left: 10rem;
      `}
    ${({ filterHide }) =>
      filterHide &&
      css`
        margin-right: 330px; //calc(21vw + 15px);

        @media ${device.laptopL} {
          margin-right: 330px; //calc(20vw + 15px);
        }

        @media ${device.desktop} {
          margin-right: 330px; //calc(15vw + 15px);
        }
      `}

  &:not(:last-child) {
      margin-bottom: 7rem;
    }
  }
    &.mb0:not(:last-child) {
      margin-bottom: 0;
    }
  }
`;

export const StyledOptions = styled.div`
  && {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
  }
`;
