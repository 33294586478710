import styled, { css } from 'styled-components';

const baseStyles = css`
  color: ${({ theme, color }) => color || theme.color.darkGray};
  margin: 0;
  text-align: ${({ align }) => align || 'left'};
`;

const headStyles = css`
  font-family: ${({ theme }) => theme.fontFamily.head};
`;

const baseFont = css`
  font-family: ${({ theme }) => theme.fontFamily.base};
`;

const H1 = styled.h1`
  ${baseStyles};
  ${headStyles};
  font-size: ${({ theme }) => theme.fontSize.h1};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;

const H2 = styled.h2`
  ${baseStyles};
  ${headStyles};
  font-size: ${({ theme }) => theme.fontSize.h2};
  font-weight: ${({ theme }) => theme.fontWeight.light};
`;

const H3 = styled.h3`
  ${baseStyles};
  ${headStyles};
  font-size: ${({ theme }) => theme.fontSize.h3};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;

const H4 = styled.h4`
  ${baseStyles};
  ${headStyles};
  font-size: ${({ theme }) => theme.fontSize.h4};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;

const H5 = styled.h5`
  ${baseStyles};
  ${baseFont};
  font-size: ${({ theme }) => theme.fontSize.h5};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

const H6 = styled.h6`
  ${baseStyles};
  ${baseFont};
  font-size: ${({ theme }) => theme.fontSize.h6};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

const H2StyledAsH6 = styled.h2`
  ${baseStyles};
  ${baseFont};
  font-size: ${({ theme }) => theme.fontSize.h6};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

const SpanStyledAsH6 = styled.span`
  ${baseStyles};
  ${baseFont};
  font-size: ${({ theme }) => theme.fontSize.h6};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

const Subtitle1 = styled.p`
  ${baseStyles};
  ${baseFont};
  font-size: ${({ theme }) => theme.fontSize.body};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

const Subtitle2 = styled.p`
  ${baseStyles};
  ${baseFont};
  font-size: ${({ theme }) => theme.fontSize.body2};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

const Subtitle2Span = styled.span`
  ${baseStyles};
  ${baseFont};
  font-size: ${({ theme }) => theme.fontSize.body2};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

const Subtitle3 = styled.p`
  ${baseStyles};
  ${baseFont};
  font-size: ${({ theme }) => theme.fontSize.body3};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

const Body1 = styled.p`
  ${baseStyles};
  ${baseFont};
  font-size: ${({ theme }) => theme.fontSize.body};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;

const Body2 = styled.p`
  ${baseStyles};
  ${baseFont};
  font-size: ${({ theme }) => theme.fontSize.body2};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;

const Caption = styled.span`
  ${baseStyles};
  ${baseFont};
  font-size: ${({ theme }) => theme.fontSize.caption};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;

const Caption2 = styled.span`
  ${baseStyles};
  ${baseFont};
  font-size: ${({ theme }) => theme.fontSize.overline};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;

const Overline = styled.span`
  ${baseStyles};
  ${baseFont};
  font-size: ${({ theme }) => theme.fontSize.overline};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

const ChartValue = styled.span`
  ${baseStyles};
  ${baseFont};
  position: absolute;
  bottom: calc(100% + 0.5rem);
  left: 0;
  right: 0;
  font-size: ${({ theme }) => theme.fontSize.caption};
  text-align: center;
`;

const ChartDesc = styled.span`
  ${baseStyles};
  ${baseFont};
  position: absolute;
  top: calc(100% + 0.5rem);
  left: 0;
  font-size: ${({ theme }) => theme.fontSize.overline};
`;

const FormatDate = styled.p`
  ${baseStyles};
  ${baseFont};
  font-size: ${({ theme }) => theme.fontSize.body2};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  text-align: right;
`;

const Heading = {
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  Subtitle1,
  Subtitle2,
  Subtitle3,
  Subtitle2Span,
  Body1,
  Body2,
  Caption,
  Caption2,
  Overline,
  ChartValue,
  ChartDesc,
  FormatDate,
  SpanStyledAsH6,
  H2StyledAsH6,
};

export default Heading;
