// React
import React from 'react';
import PropTypes from 'prop-types';

// Moment
// import moment from 'moment';

// PDF
import { Page, Text, View, Document, Font } from '@react-pdf/renderer';

// Fonts
import robotoRegularSRC from '@font/Roboto-Regular.ttf';
import robotoBoldSRC from '@font/Roboto-Bold.ttf';

// Data
// Styles
import { stylesReport as styles } from '@utils/pdfStyles';

Font.register({
  family: 'Roboto',
  fonts: [{ src: robotoRegularSRC }, { src: robotoBoldSRC, fontWeight: 700 }],
});

const renderTableRow = (data, columns, widths) => {
  return data.map((item) => (
    <View style={styles.tableRow} key={item.id}>
      {columns.map((column, index) => (
        <View style={styles.tableCol(widths[index])} key={`${item.id}-${column}`}>
          <Text style={styles.tableCell}>{item[column]}</Text>
        </View>
      ))}
    </View>
  ));
};

const renderTableHeader = (columns, widths) => (
  <View style={[styles.tableRow, styles.tableHeader]}>
    {columns.map((column, index) => (
      <View style={styles.tableCol(widths[index])} key={column}>
        <Text style={styles.headerCell}>{column}</Text>
      </View>
    ))}
  </View>
);

const Report27PDF = ({ data }) => {
  const { administrators_orgs, administrators } = data;
  const adminsOrgsColumns = ['organization', 'date_start', 'date_end', 'was_anonymized'];
  const adminsOrgsColumnsNames = [
    'Organizacja',
    'Data dodania do organizacji',
    'Data zakończenia administrowania',
    'Anonimizajca',
  ];
  const adminsOrgsColumnsWidths = [35, 25, 25, 15];
  const adminsColumns = [
    'program',
    'organization',
    'date_start',
    'date_end',
    'program_end',
    'was_anonymized',
  ];
  const adminsColumnsNames = [
    'Program',
    'Organizacja',
    'Data dodania do programu',
    'Data zakończenia udziału w programie',
    'Data zakończenia programu',
    'Anonimizajca',
  ];
  const adminsColumnsWdiths = [10, 10, 25, 25, 25, 5];
  // Configuration for coadministrators
  const coadminsColumns = [
    'program',
    'organization',
    'date_start',
    'date_end',
    'program_end',
    'was_anonymized',
  ];
  const coadminsColumnsNames = [
    'Program',
    'Organizacje',
    'Data dodania do programu',
    'Data zakończenia udziału w programie',
    'Data zakończenia programu',
    'Anonimizacja',
  ];
  const coadminsColumnsWidths = [10, 30, 20, 20, 15, 5];

  // Configuration for processors
  const processorsColumns = [
    'program',
    'organization_entrusting',
    'organization_processing',
    'custom_data',
    'date_start',
    'date_end',
    'processing_date_end',
    'program_end',
    'was_anonymized',
  ];
  const processorsColumnsNames = [
    'Program',
    'Organizacja Powierzająca',
    'Organizacja Przetwarzająca',
    'Dane',
    'Data rozpoczęcia udziału w programie',
    'Data zakończenia udziału w programie',
    'Data zakończenia przetwarzania',
    'Data zakończenia programu',
    'Anonimizacja',
  ];
  const processorsColumnsWidths = [10, 15, 15, 10, 10, 10, 15, 10, 5];

  return (
    data &&
    Object.keys(data).length > 0 && (
      <Document language="pl">
        <Page size="A4" style={styles.page} orientation="landscape">
          <View style={styles.section}>
            <Text style={styles.title}>Raport przetwarzanych danych </Text>
            <View style={styles.textFlex}>
              <Text style={styles.textBold}>Imię i nazwisko: </Text>
              <Text>
                {data.name} {data.surname}
              </Text>
            </View>
            <View style={styles.textFlex}>
              <Text style={styles.textBold}>PESEL/Paszport: </Text>
              <Text>{data.pesel}</Text>
            </View>
            <View style={styles.textFlex}>
              <Text style={styles.textBold}>Płeć: </Text>
              <Text>{data.gender}</Text>
            </View>
            <View style={styles.textFlex}>
              <Text style={styles.textBold}>Data urodzenia: </Text>
              <Text>{data.data_urodzenia}</Text>
            </View>
            <View style={styles.textFlex}>
              <Text style={styles.textBold}>Data dodania: </Text>
              <Text>{data.data_dodania}</Text>
            </View>
            <View style={styles.textFlex}>
              <Text style={styles.textBold}>Data ostatniej modyfikacji: </Text>
              <Text>{data.data_modyfikacji}</Text>
            </View>
            <View style={styles.textFlex}>
              <Text style={styles.textBold}>Placówka Wsparcia Dziennego: </Text>
              <Text>{data.pwd}</Text>
            </View>
          </View>
        </Page>
        <Page size="A4" style={styles.page} orientation="landscape">
          <View style={styles.section}>
            <Text>Administratorzy danych osobowych (organizacje)</Text>
            <View style={styles.table}>
              {renderTableHeader(adminsOrgsColumnsNames, adminsOrgsColumnsWidths)}
              {renderTableRow(administrators_orgs, adminsOrgsColumns, adminsOrgsColumnsWidths)}
            </View>
          </View>
        </Page>
        <Page size="A4" style={styles.page} orientation="landscape">
          <View style={styles.section}>
            <Text>Administratorzy danych osobowych</Text>
            <View style={styles.table}>
              {renderTableHeader(adminsColumnsNames, adminsColumnsWdiths)}
              {renderTableRow(administrators, adminsColumns, adminsColumnsWdiths)}
            </View>
          </View>
        </Page>
        <Page size="A4" style={styles.page} orientation="landscape">
          <View style={styles.section}>
            <Text>Współadministratorzy danych osobowych</Text>
            <View style={styles.table}>
              {renderTableHeader(coadminsColumnsNames, coadminsColumnsWidths)}
              {renderTableRow(data.coadministrators, coadminsColumns, coadminsColumnsWidths)}
            </View>
          </View>
        </Page>
        <Page size="A4" style={styles.page} orientation="landscape">
          <View style={styles.section}>
            <Text>Przetwarzający dane osobowe</Text>
            <View style={styles.table}>
              {renderTableHeader(processorsColumnsNames, processorsColumnsWidths)}
              {renderTableRow(data.processors, processorsColumns, processorsColumnsWidths)}
            </View>

            {data.processors.map((el) => {
              return (
                <View style={styles.section} key={el.id}>
                  {el.custom_data === 'Wybrane dane' && (
                    <>
                      <Text style={styles.title}>
                        W programie {el.program} organizacja {el.organization_entrusting} powierza
                        organizacji {el.organization_processing} {el.custom_data.toLowerCase()}.
                      </Text>
                      {Object.entries(el.rodo_config).map((it) => {
                        const key = it[0];
                        const value = it[1];
                        return (
                          <View key={key}>
                            <Text style={styles.textBold}>{value.name}: </Text>
                            <Text style={styles.text}>
                              {value.items &&
                                Object.entries(value.items)
                                  .filter(([, item]) => item.value)
                                  .map(([, item]) => item.name)
                                  .join(', ')}
                              {Object.entries(value.items).filter(([, item]) => item.value)
                                .length === 0 && <Text style={styles.text}>-</Text>}
                            </Text>
                          </View>
                        );
                      })}
                    </>
                  )}
                </View>
              );
            })}
          </View>
        </Page>
        <Page size="A4" style={styles.page} orientation="landscape">
          <View style={styles.section}>
            <Text>Dane przetwarzane w organizacjach</Text>
          </View>
          {data.beneficiary_data.cwos.map((el) => {
            return (
              <View style={styles.section} key={el.cwo_id}>
                <Text style={styles.title}>Organizacja: {el.organization}</Text>
                <Text style={styles.textBold}>Dane kontaktowe: </Text>
                <View style={styles.section}>
                  <View style={styles.textFlex}>
                    <Text style={styles.textBold}>Adres: </Text>
                    <Text>
                      {el.contactDetails.data.street} {el.contactDetails.data.houseNumber}/
                      {el.contactDetails.data.apartmentNumber}
                    </Text>
                  </View>
                  <View style={styles.textFlex}>
                    <Text style={styles.textBold}>Kod pocztowy: </Text>
                    <Text>{el.contactDetails.data.zipCode}</Text>
                  </View>
                  <View style={styles.textFlex}>
                    <Text style={styles.textBold}>Miasto: </Text>
                    <Text>{el.contactDetails.data.city}</Text>
                  </View>
                  <View style={styles.textFlex}>
                    <Text style={styles.textBold}>Dzielnica: </Text>
                    <Text>{el.contactDetails.data.district}</Text>
                  </View>
                  <View style={styles.textFlex}>
                    <Text style={styles.textBold}>Telefon: </Text>
                    <Text>{el.contactDetails.data.phone}</Text>
                  </View>
                  <View style={styles.textFlex}>
                    <Text style={styles.textBold}>Email: </Text>
                    <Text>{el.contactDetails.data.email}</Text>
                  </View>
                </View>
                <View style={styles.section}>
                  <Text style={styles.textBold}>Rodzina: </Text>
                  {el.family.data &&
                    el.family.data.map((item) => {
                      return (
                        <View style={styles.section} key={item.id}>
                          <View style={styles.textFlex}>
                            <Text style={styles.textBold}>Imię: </Text>
                            <Text>{item.name}</Text>
                          </View>
                          <View style={styles.textFlex}>
                            <Text style={styles.textBold}>Nazwisko: </Text>
                            <Text>{item.surname}</Text>
                          </View>
                          <View style={styles.textFlex}>
                            <Text style={styles.textBold}>Telefon: </Text>
                            <Text>{item.phone}</Text>
                          </View>
                          <View style={styles.textFlex}>
                            <Text style={styles.textBold}>Email: </Text>
                            <Text>{item.email}</Text>
                          </View>
                          <View style={styles.textFlex}>
                            <Text style={styles.textBold}>Opiekun prawny: </Text>
                            <Text>{item.legalGuardian ? 'Tak' : 'Nie'}</Text>
                          </View>
                          <View style={styles.textFlex}>
                            <Text style={styles.textBold}>Typ opiekuna: </Text>
                            <Text>{item.type.display}</Text>
                          </View>
                        </View>
                      );
                    })}
                </View>

                <View style={styles.section}>
                  <Text style={styles.textBold}>Dane administracyjne: </Text>
                  {el.administrative.data &&
                    el.administrative.data.map((item) => {
                      return (
                        <View style={styles.section} key={item.id}>
                          <View style={styles.textFlex}>
                            <Text style={styles.textBold}>Imię: </Text>
                            <Text>{item.name}</Text>
                          </View>
                          <View style={styles.textFlex}>
                            <Text style={styles.textBold}>Nazwisko: </Text>
                            <Text>{item.surname}</Text>
                          </View>
                          <View style={styles.textFlex}>
                            <Text style={styles.textBold}>Telefon: </Text>
                            <Text>{item.phone}</Text>
                          </View>
                          <View style={styles.textFlex}>
                            <Text style={styles.textBold}>Email: </Text>
                            <Text>{item.email}</Text>
                          </View>
                          <View style={styles.textFlex}>
                            <Text style={styles.textBold}>Opiekun prawny: </Text>
                            <Text>{item.legalGuardian ? 'Tak' : 'Nie'}</Text>
                          </View>
                          <View style={styles.textFlex}>
                            <Text style={styles.textBold}>Typ opiekuna: </Text>
                            <Text>{item.type.display}</Text>
                          </View>
                        </View>
                      );
                    })}
                </View>

                <View style={styles.section}>
                  <Text style={styles.textBold}>Dane edukacyjne: </Text>
                  <View style={styles.section}>
                    <View style={styles.textFlex}>
                      <Text style={styles.textBold}>Placówka edukacyjna: </Text>
                      <Text>
                        {el.educational.data.educationalFacilty
                          ? el.educational.data.educationalFacilty.name
                          : '-'}
                      </Text>
                    </View>
                    <View style={styles.textFlex}>
                      <Text style={styles.textBold}>Nazwa placówki: </Text>
                      <Text>
                        {el.educational.data.educationalFacilty
                          ? el.educational.data.educationalFacilty.facilityName
                          : '-'}
                      </Text>
                    </View>
                    <View style={styles.textFlex}>
                      <Text style={styles.textBold}>Ulica: </Text>
                      <Text>
                        {el.educational.data.educationalFacilty
                          ? el.educational.data.educationalFacilty.street
                          : '-'}
                      </Text>
                    </View>
                    <View style={styles.textFlex}>
                      <Text style={styles.textBold}>Numer domu: </Text>
                      <Text>
                        {el.educational.data.educationalFacilty
                          ? el.educational.data.educationalFacilty.houseNumber
                          : '-'}
                      </Text>
                    </View>
                    <View style={styles.textFlex}>
                      <Text style={styles.textBold}>Numer telefonu: </Text>
                      <Text>
                        {el.educational.data.educationalFacilty
                          ? el.educational.data.educationalFacilty.phone
                          : '-'}
                      </Text>
                    </View>
                    <View style={styles.textFlex}>
                      <Text style={styles.textBold}>Email: </Text>
                      <Text>
                        {el.educational.data.educationalFacilty
                          ? el.educational.data.educationalFacilty.email
                          : '-'}
                      </Text>
                    </View>
                    <View style={styles.textFlex}>
                      <Text style={styles.textBold}>Kod pocztowy: </Text>
                      <Text>
                        {el.educational.data.educationalFacilty
                          ? el.educational.data.educationalFacilty.zipCode
                          : '-'}
                      </Text>
                    </View>
                    <View style={styles.textFlex}>
                      <Text style={styles.textBold}>Miasto: </Text>
                      <Text>
                        {el.educational.data.educationalFacilty
                          ? el.educational.data.educationalFacilty.city
                          : '-'}
                      </Text>
                    </View>
                    <View style={styles.textFlex}>
                      <Text style={styles.textBold}>Dzielnica: </Text>
                      <Text>
                        {el.educational.data.educationalFacilty
                          ? el.educational.data.educationalFacilty.district
                          : '-'}
                      </Text>
                    </View>
                    <View style={styles.textFlex}>
                      <Text style={styles.textBold}>Klasa: </Text>
                      <Text>{el.educational.data.class ? el.educational.data.class : '-'}</Text>
                    </View>
                  </View>
                  <Text style={styles.textBold}>Historia zmian placówek edukacyjnych: </Text>
                  <View style={styles.section}>
                    {el.educational.eduHistory &&
                      el.educational.eduHistory.map((item) => {
                        return (
                          <View style={styles.section} key={item.id}>
                            <View style={styles.textFlex}>
                              <Text style={styles.textBold}>Obecna placówka: </Text>
                              <Text>{item.current_facility}</Text>
                            </View>
                            <View style={styles.textFlex}>
                              <Text style={styles.textBold}>Obecna klasa: </Text>
                              <Text>{item.current_class}</Text>
                            </View>
                            <View style={styles.textFlex}>
                              <Text style={styles.textBold}>Poprzednia placówka: </Text>
                              <Text>{item.previous_facility}</Text>
                            </View>
                            <View style={styles.textFlex}>
                              <Text style={styles.textBold}>Poprzednia klasa: </Text>
                              <Text>{item.previous_class}</Text>
                            </View>
                            <View style={styles.textFlex}>
                              <Text style={styles.textBold}>Data zmiany: </Text>
                              <Text>{item.change_date}</Text>
                            </View>
                            <View style={styles.textFlex}>
                              <Text style={styles.textBold}>Powód zmiany: </Text>
                              <Text>{item.reason_of_update}</Text>
                            </View>
                            <View style={styles.textFlex}>
                              <Text style={styles.textBold}>Typ zmiany: </Text>
                              <Text>{item.type_of_change}</Text>
                            </View>
                          </View>
                        );
                      })}
                  </View>
                </View>
              </View>
            );
          })}
        </Page>
        <Page size="A4" style={styles.page} orientation="landscape">
          <View style={styles.section}>
            <Text>Dane przetwarzane w programach</Text>
          </View>
          {data.beneficiary_data.cwps.map((el) => {
            return (
              <View style={styles.section} key={el.cwp_id}>
                <Text style={styles.title}>Program: {el.program}</Text>
                <View style={styles.section}>
                  <Text style={styles.textBold}>Dane programu: </Text>
                  <View style={styles.section}>
                    <View style={styles.textFlex}>
                      <Text style={styles.textBold}>Data dodania do programu: </Text>
                      <Text>{el.date_added}</Text>
                    </View>
                    <View style={styles.textFlex}>
                      <Text style={styles.textBold}>Data zakończenia udziału w programie: </Text>
                      <Text>{el.date_ended ? el.date_ended : '-'}</Text>
                    </View>
                    <View style={styles.textFlex}>
                      <Text style={styles.textBold}>Typ uczestnika: </Text>
                      <Text>
                        {el.beneficiaryType === 1 ? 'Dziecko' : ''}
                        {el.beneficiaryType === 2 ? 'Dorosły' : ''}
                        {el.beneficiaryType === 3 ? 'Młodzież (13-18)' : ''}
                      </Text>
                    </View>

                    <View style={styles.textFlex}>
                      <Text style={styles.textBold}>Przypisany do programu przez szkołę: </Text>
                      <Text>{el.assigned_by_school ? 'Tak' : 'Nie'}</Text>
                    </View>
                    {el.assigned_by_school && (
                      <View style={styles.textFlex}>
                        <Text style={styles.textBold}>Szkoła: </Text>
                        <Text>{el.who_assigned ? el.who_assigned.name : '-'}</Text>
                      </View>
                    )}

                    {el.approvals && (
                      <>
                        <Text style={styles.textBold}>Zgody: </Text>
                        <View style={styles.textFlex}>
                          <Text style={styles.textBold}>Zgoda na wykorzystanie wizerunku: </Text>
                          <Text>{el.approvals.approvalToUseImage ? 'tak' : 'nie'}</Text>
                        </View>
                        <View style={styles.textFlex}>
                          <Text style={styles.textBold}>Zgoda na przetwarzanie danych: </Text>
                          <Text>{el.approvals.approvalToProcessData ? 'tak' : 'nie'}</Text>
                        </View>
                        <View style={styles.textFlex}>
                          <Text style={styles.textBold}>Zgoda na samodzielny powrót do domu: </Text>
                          <Text>
                            {el.approvals.approvalToIndependentReturnHome ? 'tak' : 'nie'}
                          </Text>
                        </View>
                        <View style={styles.textFlex}>
                          <Text style={styles.textBold}>Zgoda na przekazanie informacji: </Text>
                          <Text>
                            {el.approvals.approvalToTransferOfInformation ? 'tak' : 'nie'}
                          </Text>
                        </View>
                        <View style={styles.textFlex}>
                          <Text style={styles.textBold}>Klauzula informacyjna Prezydenta: </Text>
                          <Text>{el.approvals.presidentsInformationClause ? 'tak' : 'nie'}</Text>
                        </View>
                        <View style={styles.textFlex}>
                          <Text style={styles.textBold}>Klauzula informacyjna Konsorcjum: </Text>
                          <Text>{el.approvals.consortiumInformationClause ? 'tak' : 'nie'}</Text>
                        </View>
                      </>
                    )}

                    <View style={styles.textFlex}>
                      <Text style={styles.textBold}>Anonimizacja: </Text>
                      <Text>{el.was_anonymized ? 'Tak' : 'Nie'}</Text>
                    </View>
                  </View>
                </View>

                <Text style={styles.textBold}>Dane kontaktowe: </Text>
                <View style={styles.section}>
                  <View style={styles.textFlex}>
                    <Text style={styles.textBold}>Adres: </Text>
                    <Text>
                      {el.contactDetails.data.street} {el.contactDetails.data.houseNumber}
                      {el.contactDetails.data.apartmentNumber
                        ? ` / ${el.contactDetails.data.apartmentNumber}`
                        : ''}
                    </Text>
                  </View>
                  <View style={styles.textFlex}>
                    <Text style={styles.textBold}>Kod pocztowy: </Text>
                    <Text>{el.contactDetails.data.zipCode}</Text>
                  </View>
                  <View style={styles.textFlex}>
                    <Text style={styles.textBold}>Miasto: </Text>
                    <Text>{el.contactDetails.data.city}</Text>
                  </View>
                  <View style={styles.textFlex}>
                    <Text style={styles.textBold}>Dzielnica: </Text>
                    <Text>{el.contactDetails.data.district}</Text>
                  </View>
                  <View style={styles.textFlex}>
                    <Text style={styles.textBold}>Telefon: </Text>
                    <Text>{el.contactDetails.data.phone}</Text>
                  </View>
                  <View style={styles.textFlex}>
                    <Text style={styles.textBold}>Email: </Text>
                    <Text>{el.contactDetails.data.email}</Text>
                  </View>
                </View>
                <View style={styles.section}>
                  <Text style={styles.textBold}>Rodzina: </Text>
                  {el.family.data &&
                    el.family.data.map((item) => {
                      return (
                        <View style={styles.section} key={item.id}>
                          <View style={styles.textFlex}>
                            <Text style={styles.textBold}>Imię: </Text>
                            <Text>{item.name}</Text>
                          </View>
                          <View style={styles.textFlex}>
                            <Text style={styles.textBold}>Nazwisko: </Text>
                            <Text>{item.surname}</Text>
                          </View>
                          <View style={styles.textFlex}>
                            <Text style={styles.textBold}>Telefon: </Text>
                            <Text>{item.phone}</Text>
                          </View>
                          <View style={styles.textFlex}>
                            <Text style={styles.textBold}>
                              Email:
                              <Text>{item.email}</Text>
                            </Text>
                          </View>
                          <View style={styles.textFlex}>
                            <Text style={styles.textBold}>Opiekun prawny: </Text>
                            <Text>{item.legalGuardian ? 'Tak' : 'Nie'}</Text>
                          </View>
                          <View style={styles.textFlex}>
                            <Text style={styles.textBold}>Typ opiekuna: </Text>
                            <Text>{item.type.display}</Text>
                          </View>
                        </View>
                      );
                    })}
                </View>

                <View style={styles.section}>
                  <Text style={styles.textBold}>Dane administracyjne: </Text>
                  {el.administrative.data &&
                    el.administrative.data.map((item) => {
                      return (
                        <View style={styles.section} key={item.id}>
                          <View style={styles.textFlex}>
                            <Text style={styles.textBold}>Imię: </Text>
                            <Text>{item.name}</Text>
                          </View>
                          <View style={styles.textFlex}>
                            <Text style={styles.textBold}>Nazwisko: </Text>
                            <Text>{item.surname}</Text>
                          </View>
                          <View style={styles.textFlex}>
                            <Text style={styles.textBold}>Telefon: </Text>
                            <Text>{item.phone}</Text>
                          </View>
                          <View style={styles.textFlex}>
                            <Text style={styles.textBold}>Email: </Text>
                            <Text>{item.email}</Text>
                          </View>
                          <View style={styles.textFlex}>
                            <Text style={styles.textBold}>Opiekun prawny: </Text>
                            <Text>{item.legalGuardian ? 'Tak' : 'Nie'}</Text>
                          </View>
                          <View style={styles.textFlex}>
                            <Text style={styles.textBold}>Typ opiekuna: </Text>
                            <Text>{item.type.display}</Text>
                          </View>
                        </View>
                      );
                    })}
                </View>

                <View style={styles.section}>
                  <Text style={styles.textBold}>Dane edukacyjne: </Text>
                  <View style={styles.section}>
                    <View style={styles.textFlex}>
                      <Text style={styles.textBold}>Placówka edukacyjna: </Text>
                      <Text>
                        {el.educational.data.educationalFacilty
                          ? el.educational.data.educationalFacilty.name
                          : '-'}
                      </Text>
                    </View>
                    <View style={styles.textFlex}>
                      <Text style={styles.textBold}>Nazwa placówki: </Text>
                      <Text>
                        {el.educational.data.educationalFacilty
                          ? el.educational.data.educationalFacilty.facilityName
                          : '-'}
                      </Text>
                    </View>
                    <View style={styles.textFlex}>
                      <Text style={styles.textBold}>Ulica: </Text>
                      <Text>
                        {el.educational.data.educationalFacilty
                          ? el.educational.data.educationalFacilty.street
                          : '-'}
                      </Text>
                    </View>
                    <View style={styles.textFlex}>
                      <Text style={styles.textBold}>Numer domu: </Text>
                      <Text>
                        {el.educational.data.educationalFacilty
                          ? el.educational.data.educationalFacilty.houseNumber
                          : '-'}
                      </Text>
                    </View>
                    <View style={styles.textFlex}>
                      <Text style={styles.textBold}>Numer telefonu: </Text>
                      <Text>
                        {el.educational.data.educationalFacilty
                          ? el.educational.data.educationalFacilty.phone
                          : '-'}
                      </Text>
                    </View>
                    <View style={styles.textFlex}>
                      <Text style={styles.textBold}>Email: </Text>
                      <Text>
                        {el.educational.data.educationalFacilty
                          ? el.educational.data.educationalFacilty.email
                          : '-'}
                      </Text>
                    </View>
                    <View style={styles.textFlex}>
                      <Text style={styles.textBold}>Kod pocztowy: </Text>
                      <Text>
                        {el.educational.data.educationalFacilty
                          ? el.educational.data.educationalFacilty.zipCode
                          : '-'}
                      </Text>
                    </View>
                    <View style={styles.textFlex}>
                      <Text style={styles.textBold}>Miasto: </Text>
                      <Text>
                        {el.educational.data.educationalFacilty
                          ? el.educational.data.educationalFacilty.city
                          : '-'}
                      </Text>
                    </View>
                    <View style={styles.textFlex}>
                      <Text style={styles.textBold}>Dzielnica: </Text>
                      <Text>
                        {el.educational.data.educationalFacilty
                          ? el.educational.data.educationalFacilty.district
                          : '-'}
                      </Text>
                    </View>
                    <View style={styles.textFlex}>
                      <Text style={styles.textBold}>Klasa: </Text>
                      <Text>{el.educational.data.class}</Text>
                    </View>
                  </View>
                  <Text style={styles.textBold}>Historia zmian placówek edukacyjnych: </Text>
                  <View style={styles.section}>
                    {el.educational.eduHistory &&
                      el.educational.eduHistory.map((item) => {
                        return (
                          <View style={styles.section} key={item.id}>
                            <View style={styles.textFlex}>
                              <Text style={styles.textBold}>Obecna placówka: </Text>
                              <Text>{item.current_facility}</Text>
                            </View>
                            <View style={styles.textFlex}>
                              <Text style={styles.textBold}>Obecna klasa: </Text>
                              <Text>{item.current_class}</Text>
                            </View>
                            <View style={styles.textFlex}>
                              <Text style={styles.textBold}>Poprzednia placówka: </Text>
                              <Text>{item.previous_facility}</Text>
                            </View>
                            <View style={styles.textFlex}>
                              <Text style={styles.textBold}>Poprzednia klasa: </Text>
                              <Text>{item.previous_class}</Text>
                            </View>
                            <View style={styles.textFlex}>
                              <Text style={styles.textBold}>Data zmiany: </Text>
                              <Text>{item.change_date}</Text>
                            </View>
                            <View style={styles.textFlex}>
                              <Text style={styles.textBold}>Powód zmiany: </Text>
                              <Text>{item.reason_of_update}</Text>
                            </View>
                            <View style={styles.textFlex}>
                              <Text style={styles.textBold}>Typ zmiany: </Text>
                              <Text>{item.type_of_change}</Text>
                            </View>
                          </View>
                        );
                      })}
                  </View>
                </View>
                <View style={styles.section}>
                  <Text style={styles.textBold}>Działania: </Text>
                  {el.actions &&
                    el.actions.map((task) => {
                      return task.map((item) => {
                        return (
                          <View style={styles.section} key={item.id}>
                            <View style={styles.textFlex}>
                              <Text style={styles.textBold}>Zadanie: </Text>
                              <Text>{item.task}</Text>
                            </View>
                            <View style={styles.textFlex}>
                              <Text style={styles.textBold}>Działanie: </Text>
                              <Text>{item.action}</Text>
                            </View>
                            <View style={styles.textFlex}>
                              <Text style={styles.textBold}>Data rozpoczęcia: </Text>
                              <Text>{item.date_start}</Text>
                            </View>
                            <View style={styles.textFlex}>
                              <Text style={styles.textBold}>Data zakończenia: </Text>
                              <Text>{item.date_end}</Text>
                            </View>
                          </View>
                        );
                      });
                    })}
                </View>
                <View style={styles.section}>
                  <Text style={styles.textBold}>Przedmioty wspierane: </Text>
                  {el.supported_subjects &&
                    el.supported_subjects.map((item) => {
                      return (
                        <View style={styles.section} key={item.subject_id}>
                          <View style={styles.textFlex}>
                            <Text style={styles.textBold}>Przedmiot: </Text>
                            <Text>{item.subject_name}</Text>
                          </View>
                          {item.subject_time_period.map((it) => {
                            return (
                              <View style={styles.section} key={it.supp_subject_period_id}>
                                <View style={styles.textFlex}>
                                  <Text style={styles.textBold}>Semestr: </Text>
                                  <Text>{it.time_period_name}</Text>
                                </View>
                                <View style={styles.textFlex}>
                                  <Text style={styles.textBold}>Ocena: </Text>
                                  <Text>{it.degree}</Text>
                                </View>
                                <View style={styles.textFlex}>
                                  <Text style={styles.textBold}>Pomiar bazowy: </Text>
                                  <Text>{it.base_measurement ? 'tak' : 'nie'}</Text>
                                </View>
                              </View>
                            );
                          })}
                        </View>
                      );
                    })}
                </View>
                <View style={styles.section}>
                  <Text style={styles.textBold}>Wskaźniki: </Text>
                  {Object.entries(el.indicators) &&
                    Object.entries(el.indicators).map((ind) => {
                      const topkey = ind[0];
                      const topvalue = ind[1];
                      return (
                        <View key={topkey}>
                          <Text style={styles.textBold}>{topkey}: </Text>
                          {Object.entries(topvalue).map((indicator) => {
                            const key = indicator[0];
                            const value = indicator[1];
                            return (
                              <View key={key.replace(/\s/g, '')}>
                                <Text style={styles.textBold}>{key}: </Text>
                                {value.map((item) => {
                                  return (
                                    <View style={styles.section} key={item.indicatorId}>
                                      <View style={styles.textFlex}>
                                        <Text style={styles.textBold}>Nazwa: </Text>
                                        <Text>{item.name}</Text>
                                      </View>
                                      <View style={styles.textFlex}>
                                        <Text style={styles.textBold}>Opis: </Text>
                                        <Text>{item.description}</Text>
                                      </View>
                                      <View style={styles.textFlex}>
                                        <Text style={styles.textBold}>Pomiar bazowy: </Text>
                                        <Text>{item.base_measurement ? 'tak' : 'nie'}</Text>
                                      </View>
                                      <View style={styles.textFlex}>
                                        <Text style={styles.textBold}>
                                          Założona liczba pomiarów:
                                        </Text>
                                        <Text>{item.indicatorMeasurementNumber}</Text>
                                      </View>
                                      <View style={styles.textFlex}>
                                        <Text style={styles.textBold}>Typ wskaźnika: </Text>
                                        <Text>{item.indicatorType}</Text>
                                      </View>
                                      {item.periodsColumns.map((col, index) => {
                                        return (
                                          <View style={styles.section} key={col.id}>
                                            <Text style={styles.textBold}>Pomiar: </Text>
                                            <Text>
                                              {col.name}
                                              {col.base_measurement ? '(pomiar bazowy) ' : ''}:{' '}
                                            </Text>
                                            <Text>
                                              {item.periodsValues.data[index].value
                                                ? item.periodsValues.data[index].value
                                                : '-'}
                                            </Text>
                                          </View>
                                        );
                                      })}
                                    </View>
                                  );
                                })}
                              </View>
                            );
                          })}
                        </View>
                      );
                    })}
                </View>
                <View style={styles.section}>
                  <Text style={styles.textBold}>Diagnozy: </Text>
                  {el.diagnosis &&
                    el.diagnosis.map((item) => {
                      return (
                        <View style={styles.section} key={item.id}>
                          <View style={styles.textFlex}>
                            <Text style={styles.textBold}>Nazwa: </Text>
                            <Text>{item.name}</Text>
                          </View>
                          <View style={styles.textFlex}>
                            <Text style={styles.textBold}>Data diagnozy: </Text>
                            <Text>{item.execution_date}</Text>
                          </View>
                          <View style={styles.textFlex}>
                            <Text style={styles.textBold}>Autor: </Text>
                            <Text>{item.author}</Text>
                          </View>
                          <View style={styles.textFlex}>
                            <Text style={styles.textBold}>Status: </Text>
                            <Text>{item.status}</Text>
                          </View>
                        </View>
                      );
                    })}
                </View>
                <View style={styles.section}>
                  <Text style={styles.textBold}>Ankiety diagnstyczno-ewaluacyjne: </Text>
                  {el.surveys &&
                    el.surveys.map((item) => {
                      return (
                        <View style={styles.section} key={item.id}>
                          <View style={styles.textFlex}>
                            <Text style={styles.textBold}>Nazwa: </Text>
                            <Text>{item.name}</Text>
                          </View>
                          <View style={styles.textFlex}>
                            <Text style={styles.textBold}>Data ankiety: </Text>
                            <Text>{item.execution_date}</Text>
                          </View>
                          <View style={styles.textFlex}>
                            <Text style={styles.textBold}>Autor: </Text>
                            <Text>{item.author}</Text>
                          </View>
                          <View style={styles.textFlex}>
                            <Text style={styles.textBold}>Status: </Text>
                            <Text>{item.status}</Text>
                          </View>
                          <View style={styles.textFlex}>
                            <Text style={styles.textBold}>Pomiar: </Text>
                            <Text>{item.measurement_number}</Text>
                          </View>
                        </View>
                      );
                    })}
                </View>
                <View style={styles.section}>
                  <Text style={styles.textBold}>Indywidualne plany pracy: </Text>
                  {el.ipps &&
                    el.ipps.map((item) => {
                      return (
                        <View style={styles.section} key={item.id}>
                          <View style={styles.textFlex}>
                            <Text style={styles.textBold}>Nazwa: </Text>
                            <Text>{item.name}</Text>
                          </View>
                          <View style={styles.textFlex}>
                            <Text style={styles.textBold}>Data planu: </Text>
                            <Text>{item.execution_date}</Text>
                          </View>
                          <View style={styles.textFlex}>
                            <Text style={styles.textBold}>Autor: </Text>
                            <Text>{item.who_added}</Text>
                          </View>
                          <View style={styles.textFlex}>
                            <Text style={styles.textBold}>Status: </Text>
                            <Text>{item.status}</Text>
                          </View>
                          <View style={styles.textFlex}>
                            <Text style={styles.textBold}>Data rozpoczęcia: </Text>
                            <Text>{item.date_start}</Text>
                          </View>
                          <View style={styles.textFlex}>
                            <Text style={styles.textBold}>Data zakończenia: </Text>
                            <Text>{item.date_end}</Text>
                          </View>
                        </View>
                      );
                    })}
                </View>
                <View style={styles.section}>
                  <Text style={styles.textBold}>Dokumenty: </Text>
                  {el.documents &&
                    el.documents.map((item) => {
                      return (
                        <View style={styles.section} key={item.id}>
                          <View style={styles.textFlex}>
                            <Text style={styles.textBold}>Nazwa: </Text>
                            <Text>{item.name}</Text>
                          </View>
                          <View style={styles.textFlex}>
                            <Text style={styles.textBold}>Nazwa pliku: </Text>
                            <Text>{item.originalName}</Text>
                          </View>
                          <View style={styles.textFlex}>
                            <Text style={styles.textBold}>Kto dodał: </Text>
                            <Text>{item.whoAddedDisplay}</Text>
                          </View>
                          <View style={styles.textFlex}>
                            <Text style={styles.textBold}>Data dodania: </Text>
                            <Text>{item.addDateString}</Text>
                          </View>
                        </View>
                      );
                    })}
                </View>
                <View style={styles.section}>
                  <Text style={styles.textBold}>Notatki: </Text>
                  {el.notes &&
                    el.notes.map((item) => {
                      return (
                        <View style={styles.section} key={item.id}>
                          <View style={styles.textFlex}>
                            <Text style={styles.textBold}>Nazwa: </Text>
                            <Text>{item.name}</Text>
                          </View>
                          <View style={styles.textFlex}>
                            <Text style={styles.textBold}>Rodzaj notatki: </Text>
                            <Text>{item.type_name}</Text>
                          </View>
                          <View style={styles.textFlex}>
                            <Text style={styles.textBold}>Kto dodał: </Text>
                            <Text>{item.who_added}</Text>
                          </View>
                          <View style={styles.textFlex}>
                            <Text style={styles.textBold}>Data: </Text>
                            <Text>{item.date}</Text>
                          </View>
                          <View style={styles.textFlex}>
                            <Text style={styles.textBold}>Działanie: </Text>
                            <Text>{item.action ? item.action : '-'}</Text>
                          </View>
                          <View style={styles.textFlex}>
                            <Text style={styles.textBold}>Treść: </Text>
                            <Text>{item.content}</Text>
                          </View>
                        </View>
                      );
                    })}
                </View>
              </View>
            );
          })}
        </Page>
        <Page size="A4" style={styles.page} orientation="landscape">
          <View style={styles.section}>
            <Text>Dane z systemu Librus</Text>
          </View>
          {data.beneficiary_data.librus.map((el) => {
            return (
              <View style={styles.section} key={el.class_register_id}>
                <Text style={styles.title}>Dziennik na rok: {el.period}</Text>
                <View style={styles.section}>
                  <Text style={styles.textBold}>Przedmioty: </Text>
                  {el.class_register_subjects &&
                    el.class_register_subjects.map((item) => {
                      return (
                        <View style={styles.section} key={item.subject.id}>
                          <View style={styles.textFlex}>
                            <Text style={styles.textBold}>Przedmiot: </Text>
                            <Text>{item.subject.name}</Text>
                          </View>
                          <View style={styles.textFlex}>
                            <Text style={styles.textBold}>Oceny roczne: </Text>
                            <Text>{item.ratings.annual ? item.ratings.annual.name : '-'}</Text>
                          </View>
                          <View style={styles.textFlex}>
                            <Text style={styles.textBold}>Oceny semestr pierwszy: </Text>
                            <Text>
                              {item.ratings.semesterFirst.final
                                ? item.ratings.semesterFirst.final.name
                                : '-'}
                            </Text>
                          </View>
                          <View style={styles.textFlex}>
                            <Text style={styles.textBold}>Oceny semestr drugi: </Text>
                            <Text>
                              {item.ratings.semesterSecond.final
                                ? item.ratings.semesterSecond.final.name
                                : '-'}
                            </Text>
                          </View>
                          <View style={styles.textFlex}>
                            <Text style={styles.textBold}>Oceny cząstkowe semestr pierwszy: </Text>
                            {item.ratings.semesterFirst.partial.length > 0 ? (
                              <Text>
                                {item.ratings.semesterFirst.partial
                                  .map((partial) => {
                                    return partial.name;
                                  })
                                  .join(', ')}
                              </Text>
                            ) : (
                              <Text>-</Text>
                            )}
                          </View>
                          <View style={styles.textFlex}>
                            <Text style={styles.textBold}>Oceny cząstkowe semestr drugi: </Text>
                            {item.ratings.semesterSecond.partial.length > 0 ? (
                              <Text>
                                {item.ratings.semesterSecond.partial
                                  .map((partial) => {
                                    return partial.name;
                                  })
                                  .join(', ')}
                              </Text>
                            ) : (
                              <Text>-</Text>
                            )}
                          </View>
                        </View>
                      );
                    })}
                </View>
                <Text style={styles.textBold}>Frekwencja: </Text>
                <View style={styles.section}>
                  <View style={styles.textFlex}>
                    <Text style={styles.textBold}>Frekwencja semestr pierwszy: </Text>
                    <Text>
                      {el.frequency
                        ? `godziny usprawiedliwione: ${el.frequency.justifiedHoursSemesterFirst}, godziny nieusprawiedliwione:  ${el.frequency.unexcusedHoursSemesterFirst}, liczba godzin w semestrze: ${el.frequency.hoursInSemesterFirst}`
                        : '-'}
                    </Text>
                  </View>
                  <View style={styles.textFlex}>
                    <Text style={styles.textBold}>Frekwencja na koniec roku: </Text>
                    <Text>
                      {el.frequency
                        ? `godziny usprawiedliwione: ${el.frequency.justifiedHoursEndYear}, godziny nieusprawiedliwione:  ${el.frequency.unexcusedHoursEndYear}, łączna godzin w semestrze: ${el.frequency.totalNumberHours}`
                        : '-'}
                    </Text>
                  </View>
                </View>
              </View>
            );
          })}
        </Page>
      </Document>
    )
  );
};

Report27PDF.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]),
};

Report27PDF.defaultProps = {
  data: {},
};

export default Report27PDF;
