// React
import React from 'react';

// Assets
import MenuIcon from '@mui/icons-material/Menu';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import MessageRoundedIcon from '@mui/icons-material/MessageRounded';

// Elements
import { SvgIcon, IconButton, AppBar, Toolbar, Badge, LinearProgress } from '@mui/material';

// Styles
import styled, { css } from 'styled-components';

// Icons
function ContrastIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M0,0H24V24H0Z" fill="none" />
      <path d="M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2ZM11,19.93A7.992,7.992,0,0,1,11,4.07ZM13,4.07A8.007,8.007,0,0,1,15.87,5H13ZM13,7h5.24a7.648,7.648,0,0,1,.68,1H13Zm0,3h6.74a8.4,8.4,0,0,1,.19,1H13Zm0,9.93V19h2.87a8.007,8.007,0,0,1-2.87.93ZM18.24,17H13V16h5.92a7.648,7.648,0,0,1-.68,1Zm1.5-3H13V13h6.93a8.4,8.4,0,0,1-.19,1Z" />
    </SvgIcon>
  );
}

export const StyledTooltip = styled.div`
  && {
    padding: 1.5rem 1rem;
    line-height: 1.1;

    > * {
      margin-bottom: 1rem;
    }

    p {
      &:first-child {
        margin-bottom: 1.5rem;
      }

      &:last-child {
        margin-top: 1rem;
      }
    }
  }
`;

const iconStyles = css`
  && {
    width: 2.5rem;
    height: 2.5rem;
    fill: ${({ theme }) => theme.color.darkGray};
  }
`;

// hide on mobile
export const StyledIconButton = styled(IconButton)`
  && {
    @media (max-width: 768px) {
      display: none;
    }
  }
`;

export const StyledNavbar = styled(AppBar)`
  && {
    background-color: ${({ theme }) => theme.color.white};
    box-shadow: none;
  }
`;

export const StyledToolbar = styled(Toolbar)`
  && {
    min-height: 10rem;
    padding: 0 3rem;
    justify-content: space-between;
    @media (max-width: 767px) {
      padding: 0 0 0 1.6rem;
    }
  }
`;

export const StyledMenuIcon = styled(MenuIcon)`
  && {
    width: 3rem;
    height: 3rem;
    fill: ${({ theme }) => theme.color.darkGray};
  }
`;

export const StyledMessageRoundedIcon = styled(MessageRoundedIcon)`
  && {
    ${iconStyles};
  }
`;

export const StyledEmailRoundedIcon = styled(EmailRoundedIcon)`
  && {
    ${iconStyles};
  }
`;

export const StyledFontWrapper = styled.div`
  && {
    display: flex;
    align-items: center;

    & > * {
      margin-left: 0.5rem;
    }
  }
`;

export const StyledIconWrapper = styled.button`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: transparent;
    margin: 0;
    &:hover {
      cursor: pointer;
    }
  }
`;

export const StyledContrastIcon = styled(ContrastIcon)`
  && {
    fill: ${({ theme }) => theme.color.darkGray};
    width: 2.4rem;
    height: 2.4rem;
  }
`;

export const StyledToolbarElement = styled.div`
  && {
    display: flex;
    align-items: center;

    & > * + * {
      @media (min-width: 768px) {
        margin-left: 2.5rem;
      }
    }

    ${({ icons }) =>
      icons &&
      css`
        && {
          & > * + * {
            @media (min-width: 768px) {
              margin-left: 0.5rem;
            }
          }
        }
      `}

    ${({ fluid }) =>
      fluid &&
      css`
        && {
          flex-grow: 1;
        }
      `}
  }
`;

export const StyledBadge = styled(Badge)`
  && {
    span {
      font-size: 1.3rem;
      background-color: ${({ theme }) => theme.color.red};
    }
  }
`;

export const StyledLinearProggressWrapper = styled.div`
  && {
    width: 100%;
    position: absolute;
    z-index: 999;
    left: 0;
    opacity: ${({ isPending }) => (isPending ? 1 : 0)};
    transition: 0.3s;
    bottom: 0;
  }
`;

export const StyledLinearProgress = styled(LinearProgress)`
  && {
    background-color: rgb(227, 25, 27, 0.6);
    height: 1rem;
    & div {
      background-color: ${({ theme }) => theme.color.red};
    }
  }
`;
