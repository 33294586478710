// React
import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Services
import { editProgramPWD as editProgramPWDService } from '@services/programServices';

// Formik
import { Formik } from 'formik';

// Data
import { COORDINATOR, PROGRAM_COORDINATOR } from '@constants/roles';

// Datepicker
import MultipleDatesPicker from '@components/molecules/MuiMultiDatePIcker/index';

// Moment
import moment from 'moment';

// Elements
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  Grid,
} from '@mui/material';
import Input from '@components/atoms/Input/Input';
import Button from '@components/atoms/Button/Button';
import Dialog from '@components/organisms/Dialog/Dialog';
import Checkbox from '@components/atoms/Checkbox/Checkbox';
import EditButton from '@components/atoms/EditButton/EditButton';
import GuardedComponent from '@components/molecules/GuardedComponent/GuardedComponent';
import Heading from '@components/atoms/Heading/Heading';

// Styles
import { StyledGrid, StyledButton, StyledTitle } from './ProgramPWD.styles';

// Component
const ProgramPWD = ({ details, editProgramPWD }) => {
  const { id } = useParams();
  const history = useHistory();

  const [openFreeMultiDates, setOpenFreeMultiDates] = useState(false);
  const [openMultiDates, setOpenMultiDates] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  return (
    <>
      <Formik
        initialValues={{
          motivationalSystem: details.motivationalSystem ? details.motivationalSystem : false,
          free_days: details.free_days ? details.free_days : [],
          additional_days: details.additional_days ? details.additional_days : [],
          numberOfPlacesInFacility: details.numberOfPlacesInFacility
            ? details.numberOfPlacesInFacility
            : 0,
        }}
        enableReinitialize
        onSubmit={(values) => {
          editProgramPWD(id, values);
        }}
      >
        {({ values, handleChange, handleSubmit, setFieldValue }) => (
          <>
            <StyledTitle>
              {id !== undefined && (
                <GuardedComponent
                  allowed_user_roles={[COORDINATOR]}
                  program_id={parseInt(id, 10)}
                  allowed_program_roles={[PROGRAM_COORDINATOR]}
                >
                  <EditButton isEditable={isEditable || false} setIsEditable={setIsEditable} />
                </GuardedComponent>
              )}
            </StyledTitle>
            <StyledGrid container spacing={2}>
              <Grid item xs={6}>
                <Checkbox
                  label="System motywacyjny w PWD"
                  name="motivationalSystem"
                  onChange={handleChange}
                  value={values.motivationalSystem || false}
                  isEditable={isEditable || false}
                />
              </Grid>
              <Grid item xs={6} />
              <Grid item xs={4}>
                <Input
                  label="Liczba miejsc w placówce"
                  variant="filled"
                  name="numberOfPlacesInFacility"
                  type="number"
                  onChange={handleChange}
                  value={values.numberOfPlacesInFacility}
                  disabled={!isEditable || false}
                />
              </Grid>
              <Grid item xs={8} />
              <Grid item xs={12}>
                <Button
                  onClick={() => setOpenFreeMultiDates(!openFreeMultiDates)}
                  disabled={!isEditable || false}
                  variant="outlined"
                >
                  Wybierz wolne dni
                </Button>

                {values.free_days && (
                  <MultipleDatesPicker
                    open={openFreeMultiDates}
                    selectedDates={values.free_days.map((d) => {
                      return new Date(d);
                    })}
                    cancelButtonText="Anuluj"
                    submitButtonText="Zatwierdź"
                    selectedDatesTitle="Wybrane wolne dni"
                    onCancel={() => setOpenFreeMultiDates(false)}
                    onSubmit={(dates) => {
                      const sortedDates = dates.sort((a, b) => {
                        return a - b;
                      });

                      sortedDates.map((d) => {
                        if (d.getHours() < 2) {
                          return d.setTime(d.getTime() + 2 * 60 * 60 * 1000);
                        }
                        return d;
                      });

                      setFieldValue('free_days', sortedDates);
                      setOpenFreeMultiDates(false);
                    }}
                  />
                )}
                {values.free_days && values.free_days.length > 0 && (
                  <>
                    <Box mt={1}>
                      <Heading.Subtitle2>Wybrane wolne dni</Heading.Subtitle2>
                    </Box>
                    {values.free_days.map((item, index) => {
                      return `${index > 0 ? ' / ' : ''}${moment(item, 'YYYY-MM-DD').format(
                        'DD-MM-YYYY',
                      )}`;
                    })}
                  </>
                )}
              </Grid>
              <Grid item xs={12}>
                <Box my={2}>
                  <Button
                    onClick={() => setOpenMultiDates(!openMultiDates)}
                    disabled={!isEditable || false}
                    variant="outlined"
                  >
                    Wybierz weekendy, w których placówka jest otwarta
                  </Button>
                  {values.additional_days && (
                    // <div>
                    //   {values.additional_days.map((d) => {
                    //     return d;
                    //   })}
                    // </div>
                    <MultipleDatesPicker
                      open={openMultiDates}
                      selectedDates={values.additional_days.map((d) => {
                        return new Date(d);
                      })}
                      cancelButtonText="Anuluj"
                      submitButtonText="Zatwierdź"
                      selectedDatesTitle="Wybrane wolne dni"
                      onCancel={() => setOpenMultiDates(false)}
                      onSubmit={(dates) => {
                        const sortedDates = dates.sort((a, b) => {
                          return a - b;
                        });

                        sortedDates.map((d) => {
                          if (d.getHours() < 2) {
                            return d.setTime(d.getTime() + 2 * 60 * 60 * 1000);
                          }
                          return d;
                        });

                        setFieldValue('additional_days', sortedDates);
                        setOpenMultiDates(false);
                      }}
                    />
                  )}
                  {values.additional_days && values.additional_days.length > 0 && (
                    <>
                      <Box mt={1}>
                        <Heading.Subtitle2>Wybrane otwarte dni w weekendy</Heading.Subtitle2>
                      </Box>
                      {values.additional_days.map((item, index) => {
                        return `${index > 0 ? ' / ' : ''}${moment(item, 'YYYY-MM-DD').format(
                          'DD-MM-YYYY',
                        )}`;
                      })}
                    </>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12}>
                <GuardedComponent
                  allowed_user_roles={[COORDINATOR]}
                  program_id={parseInt(id, 10)}
                  allowed_program_roles={[PROGRAM_COORDINATOR]}
                >
                  <StyledButton
                    variant="outlined"
                    disabled={!isEditable || false}
                    onClick={() => setIsPopupOpen(true)}
                  >
                    ZAPISZ
                  </StyledButton>
                  <StyledButton
                    disabled={!isEditable || false}
                    onClick={() => history.push(`/programy`)}
                  >
                    ANULUJ
                  </StyledButton>
                  <Dialog open={isPopupOpen} onClose={() => setIsPopupOpen(false)}>
                    <DialogTitle>
                      <Heading.H5>Ostrzeżenie</Heading.H5>
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        <Heading.Body2>
                          Wprowadzenie zmiany spowodować zmiany we frekwencji w działaniu. Zmiana
                          może spowodować bezpowrotne usunięcie już wprowadzonych danych w
                          usuniętych datach. <br />
                          Czy chcesz na pewno zapisać?
                        </Heading.Body2>
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={() => setIsPopupOpen(false)} color="primary">
                        Anuluj
                      </Button>
                      <Button
                        onClick={() => {
                          handleSubmit();
                          setIsPopupOpen(false);
                        }}
                        color="primary"
                        autoFocus
                      >
                        Zapisz
                      </Button>
                    </DialogActions>
                  </Dialog>
                </GuardedComponent>
              </Grid>
            </StyledGrid>
          </>
        )}
      </Formik>
    </>
  );
};

ProgramPWD.propTypes = {
  details: PropTypes.objectOf(PropTypes.any),
  editProgramPWD: PropTypes.func,
};

ProgramPWD.defaultProps = {
  details: {},
  editProgramPWD: null,
};

const mapStateToProps = ({ programReducer }) => ({
  details: programReducer.program,
});

const mapDispatchToProps = (dispatch) => ({
  editProgramPWD: (id, data) => dispatch(editProgramPWDService(id, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProgramPWD);
