// React
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useParams, useHistory, Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import { Helmet } from 'react-helmet';

// Redux
import { connect } from 'react-redux';

// Date FNS
// import DateFnsUtils from '@date-io/date-fns';
// import polLocale from 'date-fns/locale/pl';

// Services
import { fetchActionList } from '@services/actionServices';
import { fetchCWPList as fetchCWPListService } from '@services/beneficiaryServices';
import { fetchUsersLoginStatistics as fetchUsersLoginStatisticsService } from '@services/userServices';

// Actions
import { fetchSuccess, fetchFailure } from '@actions/appActions';
import { clearUsersLoginStatistics as clearUsersLoginStatisticsAction } from '@actions/userActions';
import { clearCWPList as clearCWPListAction } from '@actions/beneficiaryActions';
import { clearActions as clearActionsAction } from '@actions/actionActions';

// Data
import {
  COORDINATOR,
  PROGRAM_COORDINATOR,
  TASK_COORDINATOR,
  LECTURER_SENSITIVE_DATA,
  LECTURER,
  ORGANIZATION_ADMINISTRATOR,
} from '@constants/roles';
import { getActionsList } from '@constants/other';
import { filterActions, filterPopupIPP, filterPopupDiagnosis } from '@constants/tableFilters';

// Utils
import { isCoordinator, isInstructorOnly, isGuardianOnly } from '@utils/functions';

// Assets
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import SupervisedUserCircleRoundedIcon from '@mui/icons-material/SupervisedUserCircleRounded';
import PollRoundedIcon from '@mui/icons-material/PollRounded';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded';
// import example from '@img/familybig.svg';
import program from '@img/program.svg';
import smallone from '@img/smallone.svg';
import smalltwo from '@img/smalltwonew.svg';
import smallthree from '@img/smallthree.svg';

// Elements
import { Grid } from '@mui/material'; // , Paper
// import { MuiPickersUtilsProvider, Calendar } from '@material-ui/pickers';

import Heading from '@components/atoms/Heading/Heading';
import DashCard from '@components/molecules/DashCard/DashCard';
import DashSmall from '@components/molecules/DashCard/DashSmall';
import Chart from '@components/molecules/Chart/Chart';
// import DashCalendar from '@components/molecules/DashCard/DashCalendar';
import FloatingButton from '@components/atoms/FloatingButton/FloatingButton';
import Dialog from '@components/organisms/Dialog/Dialog';
import GuardedComponent from '@components/molecules/GuardedComponent/GuardedComponent';
import TableTemplate from '@templates/TableTemplate';

// Styles
import { StyledWrapper, StyledBeneficiaryIcon } from './Desktop.styles'; // StyledCalendarGrid,

// Data
const setAlertService = (type, message) => (dispatch) => {
  if (type === 'success') {
    dispatch(
      fetchSuccess({
        type: 'success',
        message,
        isActive: true,
      }),
    );
  }

  if (type === 'danger') {
    dispatch(
      fetchFailure({
        type: 'error',
        message,
        isActive: true,
      }),
    );
  }
};

const headCellsActionPresence = [{ type: 'action_name', label: 'Nazwa działania', nav: true }];

const headCellsDiagnosis = [
  { type: 'beneficiary_name', label: 'Beneficjent', nav: false },
  { type: 'program_name', label: 'Program', nav: false },
];

// Component
const Desktop = ({
  clearActions,
  fetchList,
  actions,
  clearCWPList,
  fetchCWPList,
  cwp_list,
  clearUsersLoginStatistics,
  fetchUsersLoginStatistics,
  login_statistics,
  setAlert,
  me,
}) => {
  const params = useParams();
  const history = useHistory();
  const { type, message } = params;
  const isBig = useMediaQuery({ query: '(max-width: 1750px)' });
  const [openDialogActionPresence, setOpenDialogActionPresence] = useState(false);
  const [openDialogDiagnosis, setOpenDialogDiagnosis] = useState(false);
  const [openDialogIPP, setOpenDialogIPP] = useState(false);
  const intructor_only = isInstructorOnly(me.user_role);
  const guardian_only = isGuardianOnly(me.user_role);
  const coordinator = isCoordinator(me.user_role);
  // const [selectedDate, setSelectedDate] = useState(new Date());

  const actionCellsActionPresence = [
    {
      column: 'Sprawdź obecność',
      action: (row) => history.push(`/dzialania/${row.id}/tab/2-dziennik`),
      button: 'sprawdź',
    },
  ];
  const actionCellsDiagnosis = [
    {
      column: 'Zobacz beneficjenta',
      action: (row) => history.push(`/beneficjenci/${row.beneficiary_id}`),
      button: 'beneficjent',
    },
    {
      column: 'Zobacz program',
      action: (row) => history.push(`/programy/${row.program_id}`),
      button: 'program',
    },
    {
      column: 'Dodaj diagnozę',
      action: (row) => history.push(`/diagnoza-dodaj/${row.id}`),
      button: 'dodaj',
    },
  ];
  const actionCellsIPP = [
    {
      column: 'Zobacz beneficjenta',
      action: (row) => history.push(`/beneficjenci/${row.beneficiary_id}`),
      button: 'beneficjent',
    },
    {
      column: 'Zobacz program',
      action: (row) => history.push(`/programy/${row.program_id}`),
      button: 'program',
    },
    {
      column: 'Dodaj Indywidualny Plan Pracy',
      action: (row) => history.push(`/indywidualny-plan-pracy-dodaj/${row.id}`),
      button: 'dodaj',
    },
  ];

  // const handleDateChange = (date) => {
  //   setSelectedDate(date);
  // };

  const actionList = [
    {
      link: '/wskaznik-dodaj',
      name: 'Wskaźnik',
      icon: <PollRoundedIcon />,
      color: 'yellow',
      root: true,
    },
    {
      link: '/zadania-dodaj',
      name: 'Zadanie',
      icon: <AssignmentRoundedIcon />,
      root: true,
    },
    ...(coordinator
      ? [
          {
            link: '/programy-dodaj',
            name: 'Program',
            icon: <AccountTreeIcon />,
            color: 'blue',
            root: true,
          },
        ]
      : []),
    {
      link: '/beneficjenci-dodaj',
      name: 'Beneficjenta',
      icon: <SupervisedUserCircleRoundedIcon />,
      root: true,
    },
    {
      link: '/uzytkownicy-dodaj',
      name: 'Użytkownika',
      icon: <PeopleAltIcon />,
      color: 'red',
      root: true,
    },
    {
      link: '/organizacje-dodaj',
      name: 'Organizację',
      icon: <AccountBalanceIcon />,
      root: true,
    },
  ];

  useEffect(() => {
    setAlert(type, message);
  }, [setAlert, type, message]);

  const triggerFetchUsersLoginStatistics = useCallback(
    () => fetchUsersLoginStatistics(),
    [fetchUsersLoginStatistics],
  );

  useEffect(() => {
    clearUsersLoginStatistics();
    if (
      me.user_settings &&
      me.user_settings.login &&
      me.user_role?.length > 0 &&
      (me.user_role.includes(ORGANIZATION_ADMINISTRATOR) || me.user_role.includes(COORDINATOR))
    ) {
      // console.log(me.user_role);
      triggerFetchUsersLoginStatistics();
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Pulpit | Centrum wspierania Rodzin, Rodzinna Warszawa</title>
      </Helmet>
      <StyledWrapper container spacing={2} direction="row" alignItems="stretch">
        <h1 style={{ display: 'none' }}>Pulpit Rodzinna Warszawa</h1>
        {!me.user_settings && (
          <Grid item xs={12} md={12} lg={12} xl={12}>
            <Heading.Body1>
              Widgety widoczne na pulpicie należy skonfigurować w{' '}
              <Link to="/ustawienia/tab/1-pulpit">ustawieniach konta</Link>.
            </Heading.Body1>
          </Grid>
        )}
        {me.user_settings && me.user_settings.beneficiaries && !guardian_only && (
          <Grid item xs={12} md={6} lg={4} xl={3}>
            <DashCard
              title="Beneficjenci"
              bigImage={<StyledBeneficiaryIcon />}
              dividerColor="yellow"
              buttons={[
                ...(!intructor_only
                  ? [{ link: '/beneficjenci-dodaj', text: 'DODAJ', label: 'Dodaj beneficjenta' }]
                  : []),
                { link: '/beneficjenci', text: 'ZOBACZ', label: 'Zobacz beneficjentów' },
              ]}
            >
              <Heading.Body1>Lista osób, którym pomagamy</Heading.Body1>
            </DashCard>
          </Grid>
        )}
        {(!me.user_settings || me.user_settings.programs) && (
          <Grid item xs={12} md={6} lg={4} xl={3}>
            <DashCard
              title="Programy"
              image={program}
              dividerColor="red"
              buttons={[
                ...(coordinator
                  ? [{ link: '/programy-dodaj', text: 'DODAJ', label: 'Dodaj program' }]
                  : []),
                { link: '/programy', text: 'ZOBACZ', label: 'Zobacz programy' },
              ]}
            >
              <Heading.Body1>
                Programy, którymi zarządzasz, bądź bierzesz w nich udzał{' '}
              </Heading.Body1>
            </DashCard>
          </Grid>
        )}
        {me.user_settings && me.user_settings.attendance && !guardian_only && (
          <Grid item xs={12} md={6} xl={3}>
            <DashSmall
              shadows="rgba(0, 0, 0, 0.072)"
              dividerColor="yellow"
              image={smallone}
              backcol="#F3F3F3"
              title="Dziennik Obecności"
              margines={isBig ? '-2em' : '-1.7em'}
              sprywyk="SPRAWDŹ"
              // hovcol="#DEDEDE"
              pos="right"
              hideElipsis
              onClick={() => {
                clearActions();
                if (!me.user_settings || (me.user_settings && me.user_settings.attendance)) {
                  fetchList();
                }
                setOpenDialogActionPresence(true);
              }}
            />
            <Dialog
              size="md"
              open={openDialogActionPresence}
              setOpen={setOpenDialogActionPresence}
              title="Lista działań - Dziennik Obecności"
            >
              <>
                <TableTemplate
                  headCells={headCellsActionPresence}
                  data={actions}
                  tableName="Lista działań"
                  filters={filterActions}
                  navCells
                  link="/dzialania"
                  actionCells={actionCellsActionPresence}
                />
              </>
              <></>
            </Dialog>
          </Grid>
        )}
        {!intructor_only && me.user_settings?.diagnosis && (
          <Grid item xs={12} md={6} xl={3}>
            <DashSmall
              shadows="rgba(0, 0, 0, 0.072)"
              dividerColor="yellow"
              image={smalltwo}
              backcol="#F3F3F3"
              title="Diagnozy"
              pos="right"
              margines={isBig ? '-2.3em' : '-2em'}
              sprywyk="DODAJ"
              // hovcol="#DEDEDE"
              hideElipsis
              onClick={() => {
                clearCWPList();

                if (
                  !me.user_settings ||
                  (me.user_settings && (me.user_settings.diagnosis || me.user_settings.ipp))
                ) {
                  fetchCWPList();
                }

                setOpenDialogDiagnosis(true);
              }}
              buttons={[
                {
                  link: '/zadania/tabs/goto/tab/1-diagnozy',
                  text: 'ZOBACZ',
                  label: 'Zobacz diagnozy',
                },
              ]}
            />
            <Dialog
              size="md"
              open={openDialogDiagnosis}
              setOpen={setOpenDialogDiagnosis}
              title="Diagnozy"
            >
              <>
                <TableTemplate
                  headCells={headCellsDiagnosis}
                  data={cwp_list}
                  filters={filterPopupDiagnosis}
                  isnav={false}
                  tableName="Diagnozy"
                  actionCells={actionCellsDiagnosis}
                />
              </>
              <></>
            </Dialog>
          </Grid>
        )}
        {!intructor_only && me.user_settings?.ipp && (
          <Grid item xs={12} md={6} xl={3}>
            <DashSmall
              shadows="rgba(0, 0, 0, 0.072)"
              dividerColor="yellow"
              image={smallthree}
              backcol="#F3F3F3"
              title="Indywidualny plan pracy"
              margines={isBig ? '-2em' : '-1.7em'}
              sprywyk="DODAJ"
              // hovcol="#DEDEDE"
              pos="right"
              hideElipsis
              onClick={() => {
                clearCWPList();

                if (
                  !me.user_settings ||
                  (me.user_settings && (me.user_settings.diagnosis || me.user_settings.ipp))
                ) {
                  fetchCWPList();
                }

                setOpenDialogIPP(true);
              }}
              buttons={[
                { link: '/zadania/tabs/goto/tab/3-ipp', text: 'ZOBACZ', label: 'Zobacz IPP' },
              ]}
            />
            <Dialog
              size="md"
              open={openDialogIPP}
              setOpen={setOpenDialogIPP}
              title="Indywidualny plan pracy"
            >
              <TableTemplate
                headCells={headCellsDiagnosis}
                data={cwp_list}
                filters={filterPopupIPP}
                tableName="Indywidualny plan pracy"
                isnav={false}
                actionCells={actionCellsIPP}
              />
              <></>
            </Dialog>
          </Grid>
        )}
        {/* <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
          {me.user_settings?.calendar && (
            <StyledCalendarGrid item xs={12} xl={12}>
              <DashCalendar>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={polLocale}>
                  <Paper style={{ height: '64.8%', overflow: 'hidden' }}>
                    <Calendar date={selectedDate} onChange={handleDateChange} />
                  </Paper>
                </MuiPickersUtilsProvider>
              </DashCalendar>
            </StyledCalendarGrid>
          )}
        </Grid> */}
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          <Grid container spacing={2} style={{ height: '102.3%' }}>
            {/* {me.user_settings?.summary && (
              <Grid item xs={12} xl={12}>
                <DashCard title="Szybkie podsumowanie">
                  <Heading.Body1>Szybkie podsumowanie</Heading.Body1>
                </DashCard>
              </Grid>
            )} */}
            {me.user_settings?.login &&
              me.user_role?.length > 0 &&
              (me.user_role.includes(ORGANIZATION_ADMINISTRATOR) ||
                me.user_role.includes(COORDINATOR)) && (
                <Grid item xs={12} xl={12}>
                  <DashCard title="Logowania do aplikacji">
                    <Chart chartdata={login_statistics} />
                  </DashCard>
                </Grid>
              )}
          </Grid>
        </Grid>
      </StyledWrapper>
      <GuardedComponent
        allowed_user_roles={[
          COORDINATOR,
          PROGRAM_COORDINATOR,

          TASK_COORDINATOR,
          LECTURER_SENSITIVE_DATA,
          LECTURER,
        ]}
      >
        <FloatingButton tooltip="Dodaj" actions={actionList} />
      </GuardedComponent>
      <GuardedComponent
        allowed_user_roles={[
          COORDINATOR,
          PROGRAM_COORDINATOR,

          TASK_COORDINATOR,
          // LECTURER_SENSITIVE_DATA,
          // LECTURER,
        ]}
      >
        <FloatingButton tooltip="Dodaj działanie typu" secondbutton actions={getActionsList} />
      </GuardedComponent>
    </>
  );
};

Desktop.propTypes = {
  clearActions: PropTypes.func,
  fetchList: PropTypes.func,
  actions: PropTypes.arrayOf(PropTypes.any),
  clearCWPList: PropTypes.func,
  fetchCWPList: PropTypes.func,
  cwp_list: PropTypes.arrayOf(PropTypes.any),
  clearUsersLoginStatistics: PropTypes.func,
  fetchUsersLoginStatistics: PropTypes.func,
  login_statistics: PropTypes.objectOf(PropTypes.any),
  me: PropTypes.shape({
    user_settings: PropTypes.any || PropTypes.shape(PropTypes.any),
    user_role: PropTypes.arrayOf(PropTypes.any),
  }),
  setAlert: PropTypes.func,
};

Desktop.defaultProps = {
  clearActions: null,
  fetchList: null,
  actions: [],
  clearCWPList: null,
  fetchCWPList: null,
  cwp_list: [],
  clearUsersLoginStatistics: null,
  fetchUsersLoginStatistics: null,
  login_statistics: {},
  me: {
    user_settings: {},
    user_role: [],
  },
  setAlert: null,
};

const mapStateToProps = ({ beneficiaryReducer, actionReducer, userReducer }) => ({
  actions: actionReducer.actions,
  cwp_list: beneficiaryReducer.cwp_list,
  login_statistics: userReducer.login_statistics,
  me: userReducer.me,
});

const mapDispatchToProps = (dispatch) => ({
  clearActions: () => dispatch(clearActionsAction()),
  fetchList: () => dispatch(fetchActionList()),
  clearCWPList: () => dispatch(clearCWPListAction()),
  fetchCWPList: () => dispatch(fetchCWPListService()),
  clearUsersLoginStatistics: () => dispatch(clearUsersLoginStatisticsAction()),
  fetchUsersLoginStatistics: () => dispatch(fetchUsersLoginStatisticsService()),
  setAlert: (type, message) => dispatch(setAlertService(type, message)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Desktop);
