import axiosInstance from '@utils/axios';
import { fetchRequest, fetchSuccess, fetchFailure } from '@actions/appActions';
import {
  showActions,
  fetchActionAction,
  fetchActionMembersAction,
  fetchActionPresenceAction,
  fetchLecturersListAction,
  fetchMeetingsListAction,
} from '@actions/actionActions';
import { checkStatusCode } from '@utils/checkStatusCode';

export const fetchActionList = () => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .get('/dzialania/nowa_lista')
    .then((res) => {
      dispatch(fetchSuccess());
      dispatch(showActions(res.data));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err),
          isActive: true,
        }),
      );
    });
};

export const fetchBeneficiaryActionsList = (id) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .get(`/dzialania/beneficjenta/${id}/nowa_lista`)
    .then((res) => {
      dispatch(fetchSuccess());
      dispatch(showActions(res.data));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err),
          isActive: true,
        }),
      );
    });
};

export const fetchPossibleBeneficiaryActionList = (id) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .get(`/dzialania/dlabeneficjenta/${id}/nowa_lista`)
    .then((res) => {
      dispatch(fetchSuccess());
      dispatch(showActions(res.data));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err),
          isActive: true,
        }),
      );
    });
};

export const removeAction = (id) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .post(`/dzialania/${id}/usun_dzialanie`)
    .then((result) => {
      dispatch(
        fetchSuccess({
          type: 'success',
          message: result.data.status,
          isActive: true,
        }),
      );

      return result;
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err, 'Nie udało się usunąć działania'),
          isActive: true,
        }),
      );
    });
};

export const fetchAction = (id) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .get(`/dzialania/${id}`)
    .then((res) => {
      dispatch(fetchSuccess());
      dispatch(fetchActionAction(res.data));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err),
          isActive: true,
        }),
      );
    });
};

export const fetchActionMembers = (id) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .get(`/dzialania/${id}/uczestnicy`)
    .then((res) => {
      dispatch(fetchSuccess());
      dispatch(fetchActionMembersAction(res.data));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err),
          isActive: true,
        }),
      );
    });
};

export const fetchActionPresence = (id, startDate, endDate) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .get(`/dzialania/${id}/dziennik`, { params: { date_start: startDate, date_end: endDate } })
    .then((res) => {
      dispatch(fetchSuccess());
      dispatch(fetchActionPresenceAction(res.data));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err),
          isActive: true,
        }),
      );
    });
};

export const fetchLecturersList = (id) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .get(`/dzialania/${id}/prowadzacy`)
    .then((res) => {
      dispatch(fetchSuccess());
      dispatch(fetchLecturersListAction(res.data));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err),
          isActive: true,
        }),
      );
    });
};

export const fetchMeetingsList = (id) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .get(`/dzialania/${id}/spotkania`)
    .then((res) => {
      dispatch(fetchSuccess());
      dispatch(fetchMeetingsListAction(res.data));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err),
          isActive: true,
        }),
      );
    });
};

export const saveMeetingsParticipants = (id, data) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .post(`/spotkanie/${id}/zapisz_uczestnikow`, data)
    .then((result) => {
      dispatch(
        fetchSuccess({
          type: 'success',
          message: result.data.status,
          isActive: true,
        }),
      );
      dispatch(fetchMeetingsList(result.data.action_id));
      return result;
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err, 'Nie udało się zapisać uczestników'),
          isActive: true,
        }),
      );
    });
};

export const saveMeetingsBeneficiaries = (id, data) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .post(`/spotkanie/${id}/zapisz_beneficjentow`, data)
    .then((result) => {
      dispatch(
        fetchSuccess({
          type: 'success',
          message: result.data.status,
          isActive: true,
        }),
      );
      dispatch(fetchMeetingsList(result.data.action_id));
      return result;
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err, 'Nie udało się zapisać uczestników'),
          isActive: true,
        }),
      );
    });
};

export const saveAttendanceList = (id, data) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .post(`/dzialania/${id}/zapisz_obecnosc`, data)
    .then((result) => {
      dispatch(
        fetchSuccess({
          type: 'success',
          message: result.data.status,
          isActive: true,
        }),
      );
      return result;
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err, 'Nie udało się zapisać obecności'),
          isActive: true,
        }),
      );
    });
};

export const saveEstimatedParticipants = (id, data) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .post(`/dzialania/${id}/zapisz_obecnosc_szacunkowa`, data)
    .then((result) => {
      dispatch(
        fetchSuccess({
          type: 'success',
          message: result.data.status,
          isActive: true,
        }),
      );
      return result;
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err, 'Nie udało się zapisać obecności'),
          isActive: true,
        }),
      );
    });
};

export const editAction = (id, data) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .post(`/dzialania/${id}/edytuj_dzialanie`, data)
    .then((result) => {
      dispatch(
        fetchSuccess({
          type: 'success',
          message: result.data.status,
          isActive: true,
        }),
      );
      return result;
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err, 'Nie udało się edytować działania'),
          isActive: true,
        }),
      );
    });
};

export const addAction = (data) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .post(`/dzialania/dzialanie/dodaj_dzialanie`, data)
    .then((result) => {
      dispatch(
        fetchSuccess({
          type: 'success',
          message: result.data.status,
          isActive: true,
        }),
      );
      return result;
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err, 'Nie udało się dodać działania'),
          isActive: true,
        }),
      );
    });
};

export const addBeneficiaries = (id, data) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .post(`/dzialania/${id}/dodaj_beneficjentow`, data)
    .then((result) => {
      dispatch(
        fetchSuccess({
          type: 'success',
          message: result.data.status,
          isActive: true,
        }),
      );
      dispatch(fetchActionMembers(id));
      return result;
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err, 'Nie udało się dodać beneficjentów'),
          isActive: true,
        }),
      );
    });
};

export const removeBeneficiaryFromAction = (action_id, cwa_id) => (dispatch) => {
  dispatch(fetchRequest());
  const url = `/dzialania/${action_id}/usun_cwa/${cwa_id}`;
  return axiosInstance
    .post(url)
    .then((result) => {
      dispatch(
        fetchSuccess({
          type: 'success',
          message: result.data.status,
          isActive: true,
        }),
      );
      dispatch(fetchActionMembers(action_id));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err, 'Nie udało się zapisać danych beneficjenta'),
          isActive: true,
        }),
      );
    });
};

export const endBeneficiaryParticipationInAction = (action_id, cwa_id) => (dispatch) => {
  dispatch(fetchRequest());
  const url = `/dzialania/${action_id}/zakoncz_cwa/${cwa_id}`;
  return axiosInstance
    .post(url)
    .then((result) => {
      dispatch(
        fetchSuccess({
          type: 'success',
          message: result.data.status,
          isActive: true,
        }),
      );
      dispatch(fetchActionMembers(action_id));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err, 'Nie udało się zapisać danych beneficjenta'),
          isActive: true,
        }),
      );
    });
};

export const saveMeetingBeneficiaries =
  (id, id_meeting, data, edit = false) =>
  (dispatch) => {
    dispatch(fetchRequest());
    return axiosInstance
      .post(
        `/dzialania/${id}/spotkania/${id_meeting}/${edit ? 'edytuj' : 'dodaj'}_beneficjentow`,
        data,
      )
      .then((result) => {
        dispatch(
          fetchSuccess({
            type: 'success',
            message: result.data.status,
            isActive: true,
          }),
        );
        dispatch(fetchMeetingsList(id));
        return result;
      })
      .catch((err) => {
        dispatch(
          fetchFailure({
            type: 'error',
            message: checkStatusCode(err, 'Nie udało się dodać beneficjentów do spotkania'),
            isActive: true,
          }),
        );
      });
  };

export const saveMeetingNote =
  (id, id_meeting, data, edit = false) =>
  (dispatch) => {
    dispatch(fetchRequest());
    return axiosInstance
      .post(`/dzialania/${id}/spotkania/${id_meeting}/${edit ? 'edytuj' : 'dodaj'}_notatke`, data)
      .then((result) => {
        dispatch(
          fetchSuccess({
            type: 'success',
            message: result.data.status,
            isActive: true,
          }),
        );
        dispatch(fetchMeetingsList(id));
        return result;
      })
      .catch((err) => {
        dispatch(
          fetchFailure({
            type: 'error',
            message: checkStatusCode(err, 'Nie udało się dodać notatki do spotkania'),
            isActive: true,
          }),
        );
      });
  };

export const saveMeetingSubject = (id, id_meeting, data) => (dispatch) => {
  dispatch(fetchRequest());
  console.log(data);

  return axiosInstance
    .post(`/dzialania/${id}/spotkania/${id_meeting}/zapisz_temat`, { meetingSubject: data })
    .then((result) => {
      dispatch(
        fetchSuccess({
          type: 'success',
          message: result.data.status,
          isActive: true,
        }),
      );
      dispatch(fetchMeetingsList(id));
      return result;
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err, 'Nie udało się zapisać tematu spotkania'),
          isActive: true,
        }),
      );
    });
};

export const removeNoteFromMeeting = (id, id_meeting, id_note) => (dispatch) => {
  dispatch(fetchRequest());
  const url = `/dzialania/${id}/spotkania/${id_meeting}/usun_notatke/${id_note}`;
  return axiosInstance
    .post(url)
    .then((result) => {
      dispatch(
        fetchSuccess({
          type: 'success',
          message: result.data.status,
          isActive: true,
        }),
      );
      dispatch(fetchMeetingsList(id));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err, 'Nie udało się usunąć notatki ze spotkania'),
          isActive: true,
        }),
      );
    });
};
