// React
import React, { useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import PropTypes from 'prop-types';

// Assets
import more from '@img/more.svg';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Elements
import { CardContent, Collapse, CardActionArea } from '@mui/material';
import Button from '@components/atoms/Button/Button';
import Heading from '@components/atoms/Heading/Heading';

// Styles
import {
  StyledTitle,
  StyledBadge,
  StyledCard,
  StyledCardMedia,
  StyledDivider,
  StyledCardActions,
  StyledIconButton,
} from './DashSmall.styles';
import './Dash.css';

// Component
const DashCard = ({
  children,
  image,
  pos,
  backcol,
  title,
  collapse,
  badge,
  dividerColor,
  actionCard,
  link,
  id,
  margines,
  sprywyk,
  shadows,
  hovcol,
  hideElipsis,
  onClick,
  buttons,
}) => {
  const [expanded, setExpanded] = useState(false);
  const history = useHistory();

  const linkTo = () => {
    if (expanded) {
      history.push(`${link}/${id}`);
    } else {
      setExpanded(true);
    }
  };
  const toggleCard = (e) => {
    e.stopPropagation();
    setExpanded(!expanded);
  };

  return (
    <StyledCard shadows={shadows} style={{ minHeight: '30rem', height: '30rem' }}>
      {actionCard ? (
        <CardActionArea component="div" onClick={linkTo}>
          {badge && <StyledBadge badge={badge} />}
          {image && <StyledCardMedia />}

          {title && (
            <StyledTitle>
              <Heading.H2StyledAsH6>{title}</Heading.H2StyledAsH6>

              {collapse && (
                <StyledIconButton
                  expanded={expanded ? 1 : 0}
                  onClick={toggleCard}
                  aria-expanded={expanded}
                  aria-label="show more"
                >
                  <ExpandMoreIcon />
                </StyledIconButton>
              )}
            </StyledTitle>
          )}
          {collapse ? (
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <CardContent>{children}</CardContent>
            </Collapse>
          ) : (
            <>
              {dividerColor ? <StyledDivider color={dividerColor} /> : null}

              <CardContent>{children}</CardContent>
            </>
          )}
          {!collapse && (
            <StyledCardActions>
              <Button size="small" aria-label="Dodaj">
                Dodaj
              </Button>
              <Button size="small" aria-label="Zobacz">
                Zobacz
              </Button>
            </StyledCardActions>
          )}
        </CardActionArea>
      ) : (
        <>
          {badge && <StyledBadge badge={badge} />}
          {image && (
            <StyledCardMedia
              image={image}
              style={{ backgroundPositionX: pos }}
              backcol={backcol}
              hovcol={hovcol}
            >
              {title && (
                <StyledTitle>
                  <Heading.H2StyledAsH6>{title}</Heading.H2StyledAsH6>
                  {collapse && (
                    <StyledIconButton
                      expanded={expanded ? 1 : 0}
                      onClick={toggleCard}
                      aria-expanded={expanded}
                      aria-label="show more"
                    >
                      <ExpandMoreIcon />
                    </StyledIconButton>
                  )}
                </StyledTitle>
              )}
            </StyledCardMedia>
          )}
          {collapse ? (
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <CardContent>{children}</CardContent>
            </Collapse>
          ) : (
            <>
              <CardContent>{children}</CardContent>
            </>
          )}
          {!collapse && (
            <StyledCardActions style={{ marginTop: margines }}>
              <Button onClick={onClick}>{sprywyk}</Button>
              {buttons.map((button) => {
                return (
                  <Button
                    size="small"
                    key={button.link}
                    component={Link}
                    to={button.link}
                    aria-label={button.label}
                  >
                    {button.text}
                  </Button>
                );
              })}
              {!hideElipsis && <img className="elipsis" src={more} alt="" />}
            </StyledCardActions>
          )}
        </>
      )}
    </StyledCard>
  );
};

DashCard.propTypes = {
  hovcol: PropTypes.string,
  children: PropTypes.element,
  image: PropTypes.string,
  title: PropTypes.string,
  collapse: PropTypes.bool,
  badge: PropTypes.string,
  dividerColor: PropTypes.string,
  actionCard: PropTypes.bool,
  link: PropTypes.string,
  id: PropTypes.number,
  pos: PropTypes.string,
  backcol: PropTypes.string,
  margines: PropTypes.string,
  sprywyk: PropTypes.string,
  shadows: PropTypes.string,
  onClick: PropTypes.func,
  hideElipsis: PropTypes.bool,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
      text: PropTypes.string,
    }),
  ),
};

DashCard.defaultProps = {
  hovcol: '#DEDEDE',
  shadows: 'rgba(0, 0, 0, 0.072)',
  sprywyk: '',
  children: null,
  image: '',
  title: '',
  collapse: false,
  badge: '',
  dividerColor: '',
  actionCard: false,
  link: '',
  id: 0,
  pos: '',
  backcol: '#F3F3F3',
  margines: '',
  onClick: null,
  hideElipsis: false,
  buttons: [],
};

export default DashCard;
