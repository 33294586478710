// React
import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useParams } from 'react-router-dom';

import slugify from 'slugify';

// import { saveAs } from 'file-saver';

// Redux
import { connect } from 'react-redux';

import { Helmet } from 'react-helmet';

// Services
import { fetchActionList as fetchActionListService } from '@services/actionServices';
import {
  fetchProgramsList as fetchProgramsListService,
  fetchActionGroups as fetchActionGroupsService,
} from '@services/programServices';
import { fetchTaskList as fetchTaskListService } from '@services/taskServices';
import {
  fetchIndicatorList as fetchIndicatorsListService,
  fetchPeriodsSemester as fetchPeriodsSemesterService,
} from '@services/indicatorServices';

import { generateReport as generateReportService } from '@services/reportServices';
import { fetchOrganizationList as fetchOrganizationListService } from '@services/organizationServices';
import {
  fetchEducationalFacilitiesList as fetchEducationalFacilitiesListService,
  fetchPWDList as fetchPWDListService,
} from '@services/selectServices';
import { fetchBeneficiaryList as fetchBeneficiaryListService } from '@services/beneficiaryServices';

// Actions
import { clearOrganizations as clearOrganizationsAction } from '@actions/organizationActions';
import { clearPrograms as clearProgramsAction } from '@actions/programActions';
import { clearTasks as clearTasksAction } from '@actions/taskActions';
import {
  clearEducationalFacilitiesList as clearEducationalFacilitiesListAction,
  clearPwdList as clearPwdListAction,
} from '@actions/selectActions';
import { clearActions as clearActionsAction } from '@actions/actionActions';

// Formik
import { Formik, ErrorMessage } from 'formik';

// Elements
import {
  COORDINATOR,
  ORGANIZATION_ADMINISTRATOR,
  ORGANIZATION_EMPLOYEE,
  PROGRAM_COORDINATOR,
  TASK_COORDINATOR,
  ROLE_SONATA_LECTURER_SENSITIVE_DATA,
  ROLE_SONATA_LECTURER,
  // ROLE_SONATA_INSTRUCTOR,
} from '@constants/roles';
import GuardedComponent from '@components/molecules/GuardedComponent/GuardedComponent';

// import { Info } from '@mui/icons-material';
import { Grid, Box, Switch /* , Tooltip, IconButton */ } from '@mui/material';
import Select from '@components/atoms/Select/Select';
import MultiSelectChips from '@components/molecules/MultiSelectChips/MultiSelectChips';
import Heading from '@components/atoms/Heading/Heading';
import Checkbox from '@components/atoms/Checkbox/Checkbox';
import Calendar from '@components/atoms/Calendar/Calendar';

import ReportExcel from '@views/Dashboard/Reports/AddReport/ReportExcel';
import { generateReport27PDF } from '@utils/functions';
// Data
import { groupBy, filterBy, switchBy } from '@constants/reports';
import {
  ActionBuildInType,
  districts,
  indicatorKind,
  indicatorTimePeriod,
  TaskType,
} from '@constants/selectLists';

// Data
import { reportTypes } from '@views/Dashboard/Reports/Reports.data';
// Styles
import {
  StyledTitle,
  StyledGrid,
  StyledButton,
  StyledFormControlLabel,
  // StyledTooltip,
} from './AddReport.styles';

// Component
const AddReport = ({
  clearOrganizations,
  fetchOrganizationList,
  fetchIndicatorsList,
  indicators,
  organizations,
  clearPrograms,
  fetchProgramsList,
  programs,
  tasks,
  clearEducationalFacilitiesList,
  fetchEducationalFacilitiesList,
  educational_facilities,
  clearPwdList,
  fetchPWDList,
  clearTasks,
  fetchTaskList,
  pwd_list,
  clearActions,
  fetchActionList,
  action_list,
  generateRepot,
  fetchPeriodsSemester,
  periodsSemester,
  fetchBeneficiaryList,
  beneficiaries,
  fetchActionGroups,
  actionGroups,
  me,
}) => {
  const [dataIndicators, setDataIndicators] = useState([]);
  const [dataIndicatorsFull, setDataIndicatorsFull] = useState([]);

  const [dataOrganizations, setDataOrganizations] = useState([]);
  const [dataOrganizationsFull, setDataOrganizationsFull] = useState([]);
  const [actionOrganizations, setActionOrganizations] = useState([]);

  const [dataPrograms, setDataPrograms] = useState([]);
  const [dataTasks, setDataTasks] = useState([]);
  const [dataActions, setDataActions] = useState([]);
  const [dataTasksFull, setDataTasksFull] = useState([]);
  const [dataActionsFull, setDataActionsFull] = useState([]);
  // const [actionGroups, setActionGroups] = useState([]);
  const [report, setReport] = useState(false);
  const [wynik, setWynik] = useState(null);

  const [excelData, setExcelData] = useState(false);

  const emptyList = [
    {
      name: 'brak danych',
      value: -1,
      program_id: 0,
      task_id: 0,
    },
  ];

  const filtersDataSelect = [
    dataPrograms,
    dataOrganizations,
    TaskType,
    dataTasks,
    actionOrganizations,
    ActionBuildInType,
    actionGroups,
    dataActions.length > 0 ? dataActions : emptyList,
    educational_facilities,
    pwd_list,
    districts,
    dataIndicators,
    indicatorTimePeriod,
    periodsSemester, // I Pomiar
    periodsSemester, // II Pomiar
    indicatorKind,
    beneficiaries,
    // emptyList, // Intensywność wsparcia
  ];

  const { type } = useParams();

  useEffect(() => {
    let found = false;
    reportTypes.forEach((group) => {
      found = group.items.filter((el) => {
        return (
          type ===
          slugify(el.label, {
            lower: true,
            locale: 'pl',
            trim: true,
          })
        );
      });

      if (found.length) {
        found[0].group = group.label;
        if (
          me.user_role.includes('ROLE_SONATA_COORDINATOR') ||
          me.user_programs.length > 0 ||
          me.user_organizations.length > 0 ||
          found[0].programGuardianAllowed ||
          (found[0].programGuardianAllowed && me.user_guardians && me.user_guardians.length > 0)
        ) {
          setReport(found[0]);
        } else {
          console.log('YOU ARE NOT ALLOWED');
        }
      }
    });
    clearOrganizations();
    clearPrograms();
    clearTasks();
    clearEducationalFacilitiesList();
    clearPwdList();
    clearActions();
  }, []);

  const triggerFetchProgramsList = useCallback(() => fetchProgramsList(), [fetchProgramsList]);

  useEffect(() => {
    if (
      report &&
      (report.hideOptions === false || report.hideOptions === undefined) &&
      report.filtering &&
      report.filtering.indexOf('program') >= 0
    ) {
      triggerFetchProgramsList();
    }
  }, [report, triggerFetchProgramsList]);

  useEffect(() => {
    if (report && (report.hideOptions === false || report.hideOptions === undefined)) {
      const newData = [];

      organizations.map((el) => {
        newData.push({ name: el.name, value: el.id });

        return true;
      });

      setDataOrganizationsFull(newData);
      setActionOrganizations(newData);
      setDataOrganizations(newData);
    }
  }, [report, organizations]);

  useEffect(() => {
    if (report && (report.hideOptions === false || report.hideOptions === undefined)) {
      const newData = [];

      programs.map((el) => {
        newData.push({ name: el.name, value: el.id, organizations_ids: el.organizations_ids });

        return true;
      });

      setDataPrograms(newData);
    }
  }, [programs]);

  useEffect(() => {
    if (report && (report.hideOptions === false || report.hideOptions === undefined)) {
      const newData = [];

      indicators.map((el) => {
        if (!report.indicator_type || el.indicator_type === report.indicator_type) {
          newData.push({ name: el.indicator_name, value: el.id, program_id: el.program_id });
        }
        return true;
      });

      setDataIndicatorsFull(newData);
      setDataIndicators(newData);
    }
  }, [indicators]);

  useEffect(() => {
    if (report && (report.hideOptions === false || report.hideOptions === undefined)) {
      const newData = [];

      tasks.map((el) => {
        newData.push({ name: el.name, value: el.id, program_id: el.program_id });

        return true;
      });
      setDataTasksFull(newData);
      setDataTasks(newData);
    }
  }, [tasks]);

  const triggerFetchPeriodsSemester = useCallback(
    () => fetchPeriodsSemester(),
    [fetchPeriodsSemester],
  );

  useEffect(() => {
    if (report && report.metricType === 'semester') {
      triggerFetchPeriodsSemester();
    }
  }, [report, triggerFetchPeriodsSemester]);

  useEffect(() => {
    if (report && (report.hideOptions === false || report.hideOptions === undefined)) {
      const newData = [];

      action_list.map((el) => {
        newData.push({
          name: el.action_name,
          value: el.id,
          program_id: el.program_id,
          task_id: el.task_id,
          action_type: el.action_type,
          organization_id: el.organization_id,
        });

        return true;
      });

      setDataActionsFull(newData);
      setDataActions(newData);
    }
  }, [action_list]);

  const triggerFetchOrganizationList = useCallback(
    () => fetchOrganizationList(),
    [report, fetchOrganizationList],
  );

  useEffect(() => {
    if (
      report &&
      (report.hideOptions === false || report.hideOptions === undefined) &&
      report.filtering &&
      (report.filtering.indexOf('organization') >= 0 ||
        report.filtering.indexOf('action_organization') >= 0)
    ) {
      triggerFetchOrganizationList();
    }
  }, [triggerFetchOrganizationList]);

  // const triggerFetchActionGroupList = useCallback(() => fetchActionGroups(id), [fetchActionGroups]);

  // useEffect(() => {
  //   if (
  //     report &&
  //     (report.hideOptions === false || report.hideOptions === undefined) &&
  //     report.filtering &&
  //     report.filtering.indexOf('action_group') >= 0
  //   ) {
  //     triggerFetchActionGroupList();
  //   }
  // }, [report, triggerFetchActionGroupList]);

  const triggerFetchIndicatorsList = useCallback(
    () => fetchIndicatorsList(),
    [fetchIndicatorsList],
  );

  useEffect(() => {
    if (
      report &&
      (report.hideOptions === false || report.hideOptions === undefined) &&
      report.filtering &&
      report.filtering.indexOf('program') >= 0
    ) {
      triggerFetchIndicatorsList();
    }
  }, [report, triggerFetchIndicatorsList]);

  const triggerFetchBeneficiariesList = useCallback(
    () => fetchBeneficiaryList(),
    [fetchBeneficiaryList],
  );

  useEffect(() => {
    if (
      report &&
      (report.hideOptions === false || report.hideOptions === undefined) &&
      report.filtering &&
      report.filtering.indexOf('beneficiaries') >= 0
    ) {
      triggerFetchBeneficiariesList();
    }
  }, [report, triggerFetchBeneficiariesList]);

  const triggerFetchTaskList = useCallback(() => fetchTaskList(), [fetchTaskList]);

  useEffect(() => {
    if (
      report &&
      (report.hideOptions === false || report.hideOptions === undefined) &&
      report.filtering &&
      report.filtering.indexOf('task') >= 0
    ) {
      triggerFetchTaskList();
    }
  }, [report, triggerFetchTaskList]);

  const triggerFetchEducationalFacilitiesList = useCallback(
    () => fetchEducationalFacilitiesList(),
    [fetchEducationalFacilitiesList],
  );

  useEffect(() => {
    if (
      report &&
      (report.hideOptions === false || report.hideOptions === undefined) &&
      report.filtering &&
      report.filtering.indexOf('school') >= 0
    ) {
      triggerFetchEducationalFacilitiesList();
    }
  }, [report, triggerFetchEducationalFacilitiesList]);

  const triggerFetchPWDList = useCallback(() => fetchPWDList(), [fetchPWDList]);

  useEffect(() => {
    if (
      report &&
      (report.hideOptions === false || report.hideOptions === undefined) &&
      report.filtering &&
      report.filtering.indexOf('pwd') >= 0
    ) {
      triggerFetchPWDList();
    }
  }, [report, triggerFetchPWDList]);

  const triggerFetchActionList = useCallback(() => fetchActionList(), [fetchActionList]);

  useEffect(() => {
    if (
      report &&
      (report.hideOptions === false || report.hideOptions === undefined) &&
      report.filtering &&
      report.filtering.indexOf('action_name') >= 0
    ) {
      triggerFetchActionList();
    }
  }, [report, triggerFetchActionList]);

  const groupByInit = {};
  const filterByInit = {};
  const switchByInit = {};

  groupBy.map((el) => {
    groupByInit[el.name] = el.value;

    return true;
  });

  filterBy.map((el) => {
    filterByInit[el.name] = el.value;
    // if (el.name === 'action_group') {
    //   if (!Array.isArray(el.value) && el.value !== null) {
    //     filterByInit[el.name] = [el.value];
    //   } else {
    //     filterByInit[el.name] = [];
    //   }
    // }

    return true;
  });

  switchBy.map((el) => {
    switchByInit[el.name] = el.value;

    return true;
  });

  const filterActions = (values) => {
    let filteredActions = dataActionsFull;

    if (values.filterBy.program) {
      filteredActions = filteredActions.filter((a) => a.program_id === values.filterBy.program);
      fetchActionGroups(values.filterBy.program);
    }

    if (values.filterBy.task) {
      filteredActions = filteredActions.filter((a) => a.task_id === values.filterBy.task);
    }

    if (values.filterBy.action_buildintype) {
      const selectedActionName = ActionBuildInType.find(
        (el_type) => el_type.value === values.filterBy.action_buildintype,
      ).name;
      filteredActions = filteredActions.filter((a) => a.action_type === selectedActionName);
    }

    setDataActions(filteredActions);
  };

  const initVal = {
    date_start: '',
    date_end: '',
    groupBy: groupByInit,
    filterBy: filterByInit,
    switchBy: switchByInit,
    type: null,
  };

  return (
    <>
      <Helmet>
        <title>Generuj raport | Centrum wspierania Rodzin, Rodzinna Warszawa</title>
      </Helmet>
      {!report && (
        <StyledTitle>
          <div>
            <Heading.Subtitle1>Brak raportu</Heading.Subtitle1>
          </div>
        </StyledTitle>
      )}
      <StyledTitle>
        <div>
          <Heading.Caption>{report.group}</Heading.Caption>
          <Heading.Subtitle1>{report.label}</Heading.Subtitle1>
          {report.tooltip && <Heading.Caption>{report.tooltip}</Heading.Caption>}
        </div>
      </StyledTitle>

      <StyledGrid container>
        <Grid item xs={10}>
          <StyledGrid container spacing={2}>
            <Formik
              initialValues={initVal}
              enableReinitialize
              onSubmit={(values) => {
                setWynik(null);
                setExcelData(false);
                generateRepot(report.id, values).then((data) => {
                  if (
                    data &&
                    (data.data.data.format === 'pdf' ||
                      (!Array.isArray(data.data.data) && data.data.data.data.length > 0))
                  ) {
                    if (data.data.data.format === 'pdf') {
                      generateReport27PDF(data.data.data);
                    } else {
                      setExcelData(data.data.data);
                    }
                  } else {
                    setWynik('System nie wygenerował raportu przy podanych parametrach.');
                  }
                });
              }}
            >
              {({ values, setFieldValue, handleChange, handleSubmit }) => {
                // console.log(
                //   report && (report.hideOptions === false || report.hideOptions === undefined),
                //   report,
                // );
                return (
                  <>
                    {report &&
                      (report.hideOptions === false || report.hideOptions === undefined) && (
                        <>
                          {(report.hideDates === false || report.hideDates === undefined) && (
                            <>
                              <Grid item md={6}>
                                <Calendar
                                  calendarToolbar={false}
                                  label="Data od"
                                  variant="filled"
                                  disableFuture
                                  name="date_start"
                                  onChange={setFieldValue}
                                  value={values.date_start}
                                />
                                <ErrorMessage name="date_start">
                                  {(msg) => <div className="error-txt">{msg}</div>}
                                </ErrorMessage>
                              </Grid>
                              <Grid item md={6}>
                                <Calendar
                                  calendarToolbar={false}
                                  label="Data do"
                                  variant="filled"
                                  disableFuture
                                  name="date_end"
                                  onChange={setFieldValue}
                                  value={values.date_end}
                                />
                                <ErrorMessage name="date_end">
                                  {(msg) => <div className="error-txt">{msg}</div>}
                                </ErrorMessage>
                              </Grid>
                            </>
                          )}
                          <GuardedComponent
                            allowed_user_roles={[
                              COORDINATOR,
                              ORGANIZATION_ADMINISTRATOR,
                              ORGANIZATION_EMPLOYEE,
                              PROGRAM_COORDINATOR,
                              TASK_COORDINATOR,
                              ROLE_SONATA_LECTURER_SENSITIVE_DATA,
                              ROLE_SONATA_LECTURER,
                            ]}
                          >
                            <>
                              {report.types && (
                                <Grid item md={6}>
                                  <StyledTitle>
                                    <Heading.Subtitle1>Typ raportu</Heading.Subtitle1>
                                  </StyledTitle>
                                  {report.types.length > 0 && (
                                    <Box mt={3}>
                                      <Select
                                        label="Typ raportu"
                                        data={report.types}
                                        variant="filled"
                                        name="type"
                                        text="type"
                                        selectValue={values.type}
                                        onChange={setFieldValue}
                                      />
                                    </Box>
                                  )}
                                </Grid>
                              )}
                            </>
                          </GuardedComponent>
                          {!report.hideGrouping && (
                            <Grid item md={6}>
                              <StyledTitle>
                                <Heading.Subtitle1>Grupuj według</Heading.Subtitle1>
                              </StyledTitle>
                              {groupBy.length > 0 &&
                                groupBy.map(
                                  (el, index) =>
                                    (!report.grouping ||
                                      report.grouping.indexOf(el.name) >= false) && (
                                      <Box key={el.id} mt={index > 0 ? 1 : 3}>
                                        <Checkbox
                                          label={el.label}
                                          name={`groupBy.${el.name}`}
                                          value={
                                            (report.groupingRequired &&
                                              report.groupingRequired.indexOf(el.name)) >= 0 ||
                                            values.groupBy[el.name]
                                          }
                                          onChange={handleChange}
                                          isEditable={
                                            !(
                                              report.groupingRequired &&
                                              report.groupingRequired.indexOf(el.name) >= 0
                                            )
                                          }
                                        />
                                      </Box>
                                    ),
                                )}
                            </Grid>
                          )}
                          {!report.hideFiltering && (
                            <Grid item md={6}>
                              <StyledTitle>
                                <Heading.Subtitle1>Filtruj</Heading.Subtitle1>
                              </StyledTitle>
                              {filterBy.length > 0 &&
                                filterBy.map(
                                  (el, index) =>
                                    (!report.filtering ||
                                      report.filtering.indexOf(el.name) >= false) && (
                                      <Box key={el.id} mt={index > 0 ? 1 : 3}>
                                        {el.name === 'action_group' ? (
                                          <>
                                            <MultiSelectChips
                                              data={filtersDataSelect[index]}
                                              values_data={values.filterBy[el.name]}
                                              isEditable
                                              fieldName={`filterBy.${el.name}`}
                                              name={`filterBy.${el.name}`}
                                              text={`filterBy.${el.name}`}
                                              label={`${el.label}${
                                                report.filteringRequired &&
                                                report.filteringRequired.indexOf(el.name) >= 0
                                                  ? '* (wymagane)'
                                                  : ''
                                              }`}
                                              labelChosen="Wybrane grupy działań"
                                              setFieldValue={setFieldValue}
                                              variant="filled"
                                            />
                                          </>
                                        ) : (
                                          <Select
                                            allowNull
                                            label={`${el.label}${
                                              report.filteringRequired &&
                                              report.filteringRequired.indexOf(el.name) >= 0
                                                ? '* (wymagane)'
                                                : ''
                                            }`}
                                            data={filtersDataSelect[index]}
                                            variant="filled"
                                            name={`filterBy.${el.name}`}
                                            text={`filterBy.${el.name}`}
                                            selectValue={values.filterBy[el.name]}
                                            onChange={(item, value) => {
                                              if (item === 'filterBy.program') {
                                                setFieldValue('filterBy.task_type', null);
                                                setFieldValue('filterBy.task', null);
                                                setFieldValue('filterBy.action_name', null);
                                                setFieldValue('filterBy.action_group', null);
                                                setFieldValue('filterBy.indicator', null);
                                                setFieldValue('filterBy.organization', null);
                                                setFieldValue('filterBy.action_organization', null);

                                                let newDataIndicators = dataIndicatorsFull.filter(
                                                  (t) => t.program_id === value,
                                                );

                                                if (newDataIndicators.length === 0) {
                                                  newDataIndicators = [
                                                    {
                                                      value: null,
                                                      name: 'Brak danych',
                                                      program_id: 0,
                                                    },
                                                  ];
                                                }
                                                setDataIndicators(newDataIndicators);

                                                const selectedProgram = dataPrograms.find(
                                                  (p) => p.value === value,
                                                );

                                                const newDataOrgs = selectedProgram
                                                  ? dataOrganizationsFull.filter((o) =>
                                                      selectedProgram.organizations_ids.includes(
                                                        o.value,
                                                      ),
                                                    )
                                                  : dataOrganizationsFull;

                                                setActionOrganizations(newDataOrgs);
                                                setDataOrganizations(newDataOrgs);

                                                setDataTasks(
                                                  dataTasksFull.filter(
                                                    (t) => t.program_id === value,
                                                  ),
                                                );

                                                const updatedValues = {
                                                  ...values,
                                                  filterBy: {
                                                    ...values.filterBy,
                                                    program: value,
                                                  },
                                                };
                                                filterActions(updatedValues);
                                              }

                                              if (item === 'filterBy.task_type') {
                                                const updatedValues = {
                                                  ...values,
                                                  filterBy: {
                                                    ...values.filterBy,
                                                    task_type: value,
                                                  },
                                                };
                                                filterActions(updatedValues);

                                                setFieldValue('filterBy.task_type', null);

                                                // setDataTasks(
                                                //   dataTasksFull.filter((t) => t.task_type === value),
                                                // );
                                              }

                                              if (item === 'filterBy.task') {
                                                const updatedValues = {
                                                  ...values,
                                                  filterBy: {
                                                    ...values.filterBy,
                                                    task: value,
                                                  },
                                                };
                                                filterActions(updatedValues);
                                                setFieldValue('filterBy.action_name', null);
                                              }
                                              if (item === 'filterBy.action_buildintype') {
                                                const updatedValues = {
                                                  ...values,
                                                  filterBy: {
                                                    ...values.filterBy,
                                                    action_buildintype: value,
                                                  },
                                                };
                                                filterActions(updatedValues);
                                                setFieldValue('filterBy.action_buildintype', null);
                                              }

                                              setFieldValue(item, value);
                                            }}
                                          />
                                        )}
                                      </Box>
                                    ),
                                )}

                              {!report.hideSwitchOptions && (
                                <>
                                  <Box mt={3}>
                                    <Heading.Caption>
                                      Dodatkowe filtry w wybranym programie:
                                    </Heading.Caption>
                                  </Box>
                                  {switchBy.length > 0 &&
                                    switchBy.map((el, index) => (
                                      <Box key={el.id} mt={index > 0 ? 1 : 3}>
                                        <StyledFormControlLabel
                                          name={`switchBy.${el.name}`}
                                          control={
                                            <Switch
                                              checked={values.switchBy[el.name]}
                                              onChange={handleChange}
                                              name={`switchBy.${el.name}`}
                                            />
                                          }
                                          labelPlacement="end"
                                          label={el.label}
                                        />
                                      </Box>
                                    ))}
                                </>
                              )}
                            </Grid>
                          )}
                        </>
                      )}
                    <Grid container xs={12}>
                      <Grid item xs={4} sx={{ paddingLeft: '15px' }}>
                        {report && (
                          <StyledButton variant="outlined" onClick={handleSubmit}>
                            Generuj
                          </StyledButton>
                        )}
                      </Grid>
                      <Grid item xs={8}>
                        {excelData && <ReportExcel data={excelData} filename={report.filename} />}
                        {wynik && <Grid sx={{ paddingLeft: '15px' }}>{wynik}</Grid>}
                      </Grid>
                    </Grid>
                  </>
                );
              }}
            </Formik>
          </StyledGrid>
        </Grid>
      </StyledGrid>
    </>
  );
};

AddReport.propTypes = {
  generateRepot: PropTypes.func,
  clearOrganizations: PropTypes.func,
  fetchOrganizationList: PropTypes.func,
  organizations: PropTypes.arrayOf(PropTypes.any),
  clearPrograms: PropTypes.func,
  fetchProgramsList: PropTypes.func,
  fetchIndicatorsList: PropTypes.func,
  clearTasks: PropTypes.func,
  fetchTaskList: PropTypes.func,
  programs: PropTypes.arrayOf(PropTypes.any),
  indicators: PropTypes.arrayOf(PropTypes.any),
  tasks: PropTypes.arrayOf(PropTypes.any),
  clearEducationalFacilitiesList: PropTypes.func,
  fetchEducationalFacilitiesList: PropTypes.func,
  educational_facilities: PropTypes.arrayOf(PropTypes.any),
  clearPwdList: PropTypes.func,
  fetchPWDList: PropTypes.func,
  pwd_list: PropTypes.arrayOf(PropTypes.any),
  clearActions: PropTypes.func,
  fetchActionList: PropTypes.func,
  action_list: PropTypes.arrayOf(PropTypes.any),
  fetchPeriodsSemester: PropTypes.func,
  periodsSemester: PropTypes.arrayOf(PropTypes.any),
  beneficiaries: PropTypes.arrayOf(PropTypes.any),
  fetchBeneficiaryList: PropTypes.func,
  fetchActionGroups: PropTypes.func,
  actionGroups: PropTypes.arrayOf(PropTypes.any),
  me: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    surname: PropTypes.string,
    user_programs: PropTypes.arrayOf(PropTypes.any),
    user_guardians: PropTypes.arrayOf(PropTypes.any),
    user_organizations: PropTypes.arrayOf(PropTypes.any),
    user_role: PropTypes.arrayOf(PropTypes.string),
  }),
};

AddReport.defaultProps = {
  clearOrganizations: null,
  fetchOrganizationList: null,
  organizations: [],
  clearPrograms: null,
  fetchProgramsList: null,
  fetchIndicatorsList: null,
  clearTasks: null,
  fetchTaskList: null,
  indicators: [],
  programs: [],
  tasks: [],
  periodsSemester: [],
  clearEducationalFacilitiesList: null,
  fetchEducationalFacilitiesList: null,
  educational_facilities: [],
  clearPwdList: null,
  fetchPWDList: null,
  pwd_list: [],
  clearActions: null,
  fetchActionList: null,
  action_list: [],
  generateRepot: null,
  fetchPeriodsSemester: null,
  beneficiaries: null,
  fetchBeneficiaryList: null,
  fetchActionGroups: null,
  actionGroups: [],
  me: {
    id: null,
    name: null,
    surname: null,
    user_programs: [],
    user_organizations: [],
    user_guardians: [],
    user_role: [],
  },
};

const mapStateToProps = ({
  organizationReducer,
  programReducer,
  taskReducer,
  selectReducer,
  actionReducer,
  indicatorReducer,
  beneficiaryReducer,
  userReducer,
}) => ({
  organizations: organizationReducer.organizations,
  programs: programReducer.programs,
  indicators:
    indicatorReducer.indicators.length > 0
      ? indicatorReducer.indicators
      : [{ id: 0, indicator_name: 'Brak danych', program_id: 0 }],
  tasks: taskReducer.tasks,
  educational_facilities: selectReducer.educationalFacilities,
  pwd_list: selectReducer.PWD,
  periodsSemester: selectReducer.periodsSemester,
  action_list:
    actionReducer.actions.length > 0
      ? actionReducer.actions
      : [{ id: 0, action_name: 'Brak danych', program_id: 0, task_id: 0 }],
  beneficiaries: beneficiaryReducer.beneficiaries.map((el) => ({
    name: `${el.name} ${el.surname}`,
    value: el.id,
  })),
  actionGroups: programReducer.actionGroups,
  me: userReducer.me,
});

const mapDispatchToProps = (dispatch) => ({
  clearOrganizations: () => dispatch(clearOrganizationsAction()),
  fetchOrganizationList: () => dispatch(fetchOrganizationListService()),
  clearPrograms: () => dispatch(clearProgramsAction()),
  fetchPeriodsSemester: () => dispatch(fetchPeriodsSemesterService()),
  fetchProgramsList: () => dispatch(fetchProgramsListService()),
  fetchIndicatorsList: () => dispatch(fetchIndicatorsListService()),
  clearTasks: () => dispatch(clearTasksAction()),
  fetchTaskList: () => dispatch(fetchTaskListService()),
  clearEducationalFacilitiesList: () => dispatch(clearEducationalFacilitiesListAction()),
  fetchEducationalFacilitiesList: () => dispatch(fetchEducationalFacilitiesListService()),
  clearPwdList: () => dispatch(clearPwdListAction()),
  fetchPWDList: () => dispatch(fetchPWDListService()),
  clearActions: () => dispatch(clearActionsAction()),
  fetchActionList: () => dispatch(fetchActionListService()),
  generateRepot: (id, values) => dispatch(generateReportService(id, values)),
  fetchBeneficiaryList: () => dispatch(fetchBeneficiaryListService()),
  fetchActionGroups: (id) => dispatch(fetchActionGroupsService(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddReport);
