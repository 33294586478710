import React from 'react';
import { Typography, styled } from '@mui/material';

const BoxRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  justifyContent: 'space-between',
  textAlign: 'center',
  marginBottom: theme.spacing(1),
  '& > *': {
    width: 36,
    margin: `${theme.spacing(0, 1)} !important`,
    [theme.breakpoints.down('xs')]: {
      margin: `0 2px`,
    },
  },
}));

const Week = (props) => <Typography variant="overline" color="textSecondary" {...props} />;

// Definiujemy polskie skrócone nazwy dni (od poniedziałku do niedzieli)
const polishWeekdaysShort = ['pon', 'wt', 'śr', 'czw', 'pt', 'sob', 'nd'];

const WeekHeader = () => {
  return (
    <BoxRoot>
      {polishWeekdaysShort.map((day) => (
        <Week key={day}>{day}</Week>
      ))}
    </BoxRoot>
  );
};

export default WeekHeader;
