// Styles
import styled, { css } from 'styled-components';
import { device } from '@theme/mainTheme';

export const StyledWrapper = styled.main`
  && {
    margin-left: 10rem;
    margin-top: 10rem;
    padding: 1rem 1.5rem;
    transition: 0.4s;
    position: relative;
    min-height: calc(100vh - 10rem);

    @media ${device.laptop} {
      margin-left: ${({ $hide }) => ($hide ? '10rem' : '15vw')};
    }

    @media ${device.mobile} {
      padding-left: 0;
      padding-bottom: 120px;
    }

    ${({ filterHide }) =>
      filterHide &&
      css`
        margin-right: 15vw;
      `}
  }
`;
