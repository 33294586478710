// Elements
import { Link } from 'react-router-dom';
import { Grid, Tooltip } from '@mui/material';
import Button from '@components/atoms/Button/Button';

// Styles

// Styles
import { withStyles } from '@mui/styles';
import styled from 'styled-components';

export const StyledWrapper = styled(Grid)`
  && {
    margin-top: 0rem;
  }
`;

export const StyledButton = styled(Button)`
  && {
    padding: 1rem 2rem;
    margin-right: 1.5rem;
  }
`;

export const StyledLink = styled(Link)`
  && {
    color: ${({ theme }) => theme.color.darkGray};
  }
`;

export const StyledTooltip = withStyles(() => ({
  tooltip: {
    fontSize: 12,
  },
}))(Tooltip);
