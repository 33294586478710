// React
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';

// Services
import {
  fetchPWDPresence as fetchPWDPresenceService,
  savePWDAttendanceList as saveAttendanceListService,
} from '@services/programServices';

// React SVG
// import { ReactSVG } from 'react-svg';

// Formik
import { Formik } from 'formik';

// Moment
import { format } from 'date-fns';
import { pl } from 'date-fns/locale';

// Data
import { actionAttendance } from '@constants/selectLists';

// Actions
import { clearPWDPresenceAction as clearPresenceService } from '@actions/programActions';

// Assets
import PlaylistAddCheckOutlinedIcon from '@mui/icons-material/PlaylistAddCheckOutlined';
import CheckBoxOutlineBlankRoundedIcon from '@mui/icons-material/CheckBoxOutlineBlankRounded';
import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded';

// Elements
import {
  Box,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Rating,
  Tooltip,
} from '@mui/material';
import Heading from '@components/atoms/Heading/Heading';
import Select from '@components/atoms/Select/Select';
import Button from '@components/atoms/Button/Button';
import Checkbox from '@components/atoms/Checkbox/Checkbox';
import CalendarRange from '@components/atoms/CalendarRange/CalendarRange';

// Styles
import { useTheme } from '@mui/material/styles';
import {
  StyledHeading,
  // StyledButton,
  StyledTableBody,
  StyledTableHead,
  StyledTableRow,
  StyledTableCell,
  StyledWrapper,
  StyledTableCellSticky,
  StyledTableCellStickyRight,
  StyledTableText,
  StyledReactSVG,
} from './ProgramPresencePWD.styles';

// Component
const ProgramPresencePWD = ({ clearPresence, fetchPWDPresence, presence, saveAttendanceList }) => {
  const theme = useTheme();
  const { id } = useParams();
  const { beneficiaries, dates } = presence;
  const [unfinished, setUnfinished] = useState(false);
  const [selectValue, setSelectValue] = useState(1);
  const [logCheck, setLogCheck] = useState(false);
  const [rangeCalendarOpen, setRangeCalendarOpen] = useState(false);
  const [rangeCalendarDates, setRangeCalendarDates] = useState({});
  const [initialRangeCalendarDates, setInitialRangeCalendarDates] = useState({});

  const [filteredDates, setFilteredDates] = useState([]);
  const { startDate: rangeStartDate, endDate: rangeEndDate } = rangeCalendarDates;

  useEffect(() => {
    clearPresence();
  }, []);

  let oneDayAfterEndDate;

  if (rangeCalendarDates) {
    oneDayAfterEndDate = new Date(rangeEndDate);
    oneDayAfterEndDate.setDate(oneDayAfterEndDate.getDate() + 1);
  }

  const triggerFetchPWDPresence = useCallback(
    (myid, startDate, endDate) => fetchPWDPresence(myid, startDate, endDate),
    [fetchPWDPresence],
  );

  useEffect(() => {
    if (dates && dates.length) {
      setInitialRangeCalendarDates({
        startDate: new Date(dates[0].date),
        endDate: new Date(dates[dates.length - 1].date),
      });
    }
  }, [dates]);

  useEffect(() => {
    triggerFetchPWDPresence(id, null, null);
  }, [id, triggerFetchPWDPresence]);

  useEffect(() => {
    if (rangeCalendarDates && rangeCalendarDates.startDate && rangeCalendarDates.endDate) {
      setFilteredDates(
        dates &&
          dates.filter((d) => {
            const dTime = new Date(d.date).getTime();
            if (
              (rangeCalendarDates &&
                rangeCalendarDates.startDate &&
                rangeCalendarDates.endDate &&
                (dTime < rangeStartDate.getTime() || dTime > oneDayAfterEndDate.getTime())) ||
              (unfinished && (d.status === 2 || d.status === 3))
            ) {
              return false;
            }
            return true;
          }),
      );
    } else {
      setFilteredDates(dates);
    }

    //
  }, [dates, rangeCalendarDates, unfinished]);

  useEffect(() => {
    // console.log(rangeCalendarDates, id);
    if (rangeCalendarDates.startDate && rangeCalendarDates.endDate) {
      triggerFetchPWDPresence(id, rangeCalendarDates.startDate, rangeCalendarDates.endDate);
    }
  }, [rangeCalendarDates, id]);

  // console.log(
  //   rangeCalendarDates,
  //   initialRangeCalendarDates,
  //   rangeCalendarDates.startDate && rangeCalendarDates.endDate
  //     ? rangeCalendarDates
  //     : initialRangeCalendarDates,
  // );

  return (
    <>
      {!logCheck ? (
        <>
          <Box display="flex" alignItems="center">
            <Button
              variant={unfinished ? 'outlined' : 'text'}
              startIcon={<PlaylistAddCheckOutlinedIcon />}
              onClick={() => {
                setUnfinished(!unfinished);
              }}
            >
              Tylko niewypełnione
            </Button>
            {dates &&
              dates.length > 0 &&
              initialRangeCalendarDates &&
              initialRangeCalendarDates.startDate && (
                <CalendarRange
                  initialDateRange={initialRangeCalendarDates}
                  open={rangeCalendarOpen}
                  setRangeCalendarOpen={setRangeCalendarOpen}
                  setRangeCalendarDates={setRangeCalendarDates}
                  rangeCalendarDates={
                    rangeCalendarDates.startDate && rangeCalendarDates.endDate
                      ? rangeCalendarDates
                      : initialRangeCalendarDates
                  }
                  // minDate={new Date(dates[0].date) || null}
                  // maxDate={new Date(dates[dates.length - 1].date) || null}
                />
              )}
          </Box>
          <TableContainer>
            <Table>
              <StyledTableHead>
                <TableRow>
                  <StyledTableCellSticky>
                    <Heading.Body2>Nazwisko Imię</Heading.Body2>
                  </StyledTableCellSticky>
                  {filteredDates &&
                    filteredDates.map(({ attendance_list_id, date, status }) => {
                      return (
                        <StyledTableCell key={attendance_list_id} align="center">
                          <StyledHeading status={status}>
                            {format(new Date(date), 'eeee', { locale: pl })} <br />
                            {format(new Date(date), 'dd/MM/yyyy', { locale: pl })}
                          </StyledHeading>
                        </StyledTableCell>
                      );
                    })}
                  <StyledTableCellStickyRight>
                    <Select
                      data={actionAttendance}
                      selectValue={selectValue}
                      name="selectValue"
                      onChange={(text, value) => setSelectValue(value)}
                      noBorder
                    />
                  </StyledTableCellStickyRight>
                </TableRow>
              </StyledTableHead>
              <StyledTableBody>
                {beneficiaries &&
                  beneficiaries.map((beneficiaryRow, index) => {
                    const { beneficiary, /* attendance, */ calc_attendance } = beneficiaryRow;

                    // const presenceIcon = (paramID) => {
                    //   switch (attendance[paramID]) {
                    //     case -1:
                    //       return <ReactSVG src="/img/absence.svg" />;
                    //     case 1:
                    //       return <ReactSVG src="/img/attendance.svg" />;
                    //     case 2:
                    //       return <ReactSVG src="/img/excused-absence.svg" />;
                    //     default:
                    //       return <></>;
                    //   }
                    // };

                    const probabilityPresenceIcon = (paramID) => {
                      switch (calc_attendance[paramID]) {
                        case -1:
                          return <StyledReactSVG src="/img/absence.svg" />;
                        case 1:
                          return <StyledReactSVG src="/img/attendance.svg" />;
                        case 2:
                          return <StyledReactSVG src="/img/excused-absence.svg" />;
                        case -2:
                          return <StyledReactSVG src="/img/not-applicable.svg" />;

                        default:
                          return <></>;
                      }
                    };

                    return (
                      <TableRow key={beneficiary.id}>
                        <StyledTableCellSticky>
                          <Tooltip
                            title={
                              <span style={{ fontSize: '14px' }}>
                                {beneficiary.dates_in_program_end}
                              </span>
                            }
                            placement="top"
                          >
                            <Heading.Body2
                              style={{
                                opacity: beneficiary.dates_in_program_end.includes(' do') ? 0.5 : 1,
                              }}
                            >
                              {index + 1}. {beneficiary.surname} {beneficiary.name}
                            </Heading.Body2>
                          </Tooltip>
                        </StyledTableCellSticky>
                        {filteredDates &&
                          filteredDates.map((item) => {
                            return (
                              <StyledTableCell align="center" key={item.attendance_list_id}>
                                <Box display="flex" justifyContent="center" alignItems="center">
                                  {/* {presenceIcon(item.date)} */}
                                  {probabilityPresenceIcon(item.date)}
                                </Box>
                              </StyledTableCell>
                            );
                          })}
                        <StyledTableCellStickyRight>
                          <StyledHeading status>
                            {(() => {
                              switch (selectValue) {
                                case 1:
                                  return Object.keys(calc_attendance).reduce(
                                    (sum, key) =>
                                      sum +
                                      (calc_attendance[key] != null && calc_attendance[key] === 1
                                        ? 1
                                        : 0),
                                    0,
                                  );
                                case 2:
                                  return Object.keys(calc_attendance).reduce(
                                    (sum, key) =>
                                      sum +
                                      (calc_attendance[key] != null && calc_attendance[key] === 2
                                        ? 1
                                        : 0),
                                    0,
                                  );
                                case -1:
                                  return Object.keys(calc_attendance).reduce(
                                    (sum, key) =>
                                      sum +
                                      (calc_attendance[key] != null && calc_attendance[key] === -1
                                        ? 1
                                        : 0),
                                    0,
                                  );
                                default:
                                  return null;
                              }
                            })()}
                          </StyledHeading>
                        </StyledTableCellStickyRight>
                      </TableRow>
                    );
                  })}
                <StyledTableRow>
                  <StyledTableCellSticky colSpan={1}>
                    <Heading.Subtitle2 align="center">Suma obecności</Heading.Subtitle2>
                  </StyledTableCellSticky>
                  {filteredDates &&
                    filteredDates.map(({ status, date }) => {
                      return (
                        <StyledTableCell key={date}>
                          <Heading.Subtitle2 align="center">
                            {status
                              ? beneficiaries.reduce(
                                  (sum, item) =>
                                    sum +
                                    (item.calc_attendance[date] === 1
                                      ? item.calc_attendance[date]
                                      : 0),
                                  0,
                                )
                              : '0'}
                          </Heading.Subtitle2>
                        </StyledTableCell>
                      );
                    })}
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCellSticky colSpan={1} align="center" />
                  {filteredDates &&
                    filteredDates.map(({ status, attendance_list_id /* , date */ }) => {
                      return status ? (
                        <StyledTableCell key={attendance_list_id} align="center">
                          {/* <StyledButton
                            onClick={() =>
                              setLogCheck({
                                id: attendance_list_id,
                                name: `${moment(new Date(date))
                                  // .locale('pl')
                                  .format('dddd')}, ${date}`,
                              })
                            }
                            align="center"
                            variant="contained"
                          >
                            {status === 1 ? 'Sprawdź' : 'Edytuj'}
                          </StyledButton> */}
                        </StyledTableCell>
                      ) : null;
                    })}
                </StyledTableRow>
              </StyledTableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <StyledWrapper container spacing={2}>
          <Grid item xs={12}>
            <Heading.Body1>{logCheck.name}</Heading.Body1>
          </Grid>
          <Grid item xs={12}>
            <Formik
              initialValues={{
                attendance: beneficiaries.reduce((map, row) => {
                  const newMap = map;
                  newMap[row.beneficiary.cwp_id] = row.attendance[logCheck.id]
                    ? row.attendance[logCheck.id]
                    : row.calc_attendance[logCheck.id];
                  return newMap;
                }, {}),
                educational: beneficiaries.reduce((map, row) => {
                  const newMap = map;
                  newMap[row.beneficiary.cwp_id] =
                    logCheck && row.educational && row.educational[logCheck.id]
                      ? row.educational[logCheck.id]
                      : row.calc_educational[logCheck.id] || 0;
                  return newMap;
                }, {}),
                social: beneficiaries.reduce((map, row) => {
                  const newMap = map;
                  newMap[row.beneficiary.cwp_id] =
                    logCheck && row.social && row.social[logCheck.id]
                      ? row.social[logCheck.id]
                      : row.calc_social[logCheck.id] || 0;
                  return newMap;
                }, {}),
                emotional: beneficiaries.reduce((map, row) => {
                  const newMap = map;
                  newMap[row.beneficiary.cwp_id] =
                    logCheck && row.emotional && row.emotional[logCheck.id]
                      ? row.emotional[logCheck.id]
                      : row.calc_emotional[logCheck.id] || 0;
                  return newMap;
                }, {}),
              }}
              enableReinitialize
              onSubmit={(values) => {
                saveAttendanceList(logCheck.id, values).then(() => {
                  fetchPWDPresence(id, rangeCalendarDates.startDate, rangeCalendarDates.endDate);
                  setLogCheck(false);
                });
              }}
            >
              {({ values, handleSubmit, setFieldValue }) => {
                return (
                  <>
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <StyledTableCell>
                              <Heading.Body2>L.p.</Heading.Body2>
                            </StyledTableCell>
                            <StyledTableCell>
                              <Heading.Body2>Nazwisko</Heading.Body2>
                            </StyledTableCell>
                            <StyledTableCell>
                              <Heading.Body2>Imię</Heading.Body2>
                            </StyledTableCell>
                            <StyledTableCell>
                              <Box display="flex" justifyContent="center" alignItems="center">
                                <Heading.Body2>Na podstawie działań</Heading.Body2>
                              </Box>
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Checkbox
                                value={false}
                                label="Obecność"
                                isEditable
                                radio
                                color="blue"
                                onChange={(e) =>
                                  beneficiaries.map((row) =>
                                    setFieldValue(
                                      `attendance[${row.beneficiary.cwp_id}]`,
                                      e.target.checked ? 1 : 0,
                                    ),
                                  )
                                }
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Checkbox
                                value={false}
                                label="Nieobecność"
                                isEditable
                                radio
                                color="red"
                                onChange={(e) =>
                                  beneficiaries.map((row) =>
                                    setFieldValue(
                                      `attendance[${row.beneficiary.cwp_id}]`,
                                      e.target.checked ? -1 : 0,
                                    ),
                                  )
                                }
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Checkbox
                                value={false}
                                label="Usprawiedliwione"
                                isEditable
                                radio
                                color="yellow"
                                onChange={(e) =>
                                  beneficiaries.map((row) =>
                                    setFieldValue(
                                      `attendance[${row.beneficiary.cwp_id}]`,
                                      e.target.checked ? 2 : 0,
                                    ),
                                  )
                                }
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Checkbox
                                value={false}
                                label="Nie dotyczy"
                                isEditable
                                radio
                                color="grey"
                                onChange={(e) =>
                                  beneficiaries.map((row) =>
                                    setFieldValue(
                                      `attendance[${row.beneficiary.cwp_id}]`,
                                      e.target.checked ? -2 : 0,
                                    ),
                                  )
                                }
                              />
                            </StyledTableCell>
                            {presence.motivationalSystem && (
                              <>
                                <StyledTableCell align="center">
                                  <StyledTableText>Skala edukacyjna</StyledTableText>
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  <StyledTableText>Skala społeczna</StyledTableText>
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  <StyledTableText>Skala emocjonalna</StyledTableText>
                                </StyledTableCell>
                              </>
                            )}
                          </TableRow>
                        </TableHead>
                        <StyledTableBody>
                          {beneficiaries.map((row, index) => {
                            const probabilityPresenceIcon = (paramID) => {
                              switch (paramID) {
                                case -1:
                                  return <StyledReactSVG src="/img/absence.svg" />;
                                case 1:
                                  return <StyledReactSVG src="/img/attendance.svg" />;
                                case 2:
                                  return <StyledReactSVG src="/img/excused-absence.svg" />;
                                case -2:
                                  return <StyledReactSVG src="/img/not-applicable.svg" />;
                                default:
                                  return <></>;
                              }
                            };

                            return (
                              <TableRow key={row.beneficiary.cwp_id}>
                                <StyledTableCell align="center">
                                  <Heading.Body2> {index + 1}. </Heading.Body2>
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  <Heading.Body2> {row.beneficiary.surname}</Heading.Body2>
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  <Heading.Body2>{row.beneficiary.name}</Heading.Body2>
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {probabilityPresenceIcon(row.calc_attendance[logCheck.id])}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  <Checkbox
                                    value={
                                      values.attendance[row.beneficiary.cwp_id] !== null
                                        ? values.attendance[row.beneficiary.cwp_id] === 1
                                        : false
                                    }
                                    isEditable
                                    radio
                                    color="blue"
                                    onChange={(e) => {
                                      setFieldValue(
                                        `attendance[${row.beneficiary.cwp_id}]`,
                                        e.target.checked ? 1 : 0,
                                      );
                                    }}
                                  />
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  <Checkbox
                                    value={
                                      values.attendance[row.beneficiary.cwp_id] !== null
                                        ? values.attendance[row.beneficiary.cwp_id] === -1
                                        : false
                                    }
                                    isEditable
                                    radio
                                    color="red"
                                    onChange={(e) => {
                                      setFieldValue(
                                        `attendance[${row.beneficiary.cwp_id}]`,
                                        e.target.checked ? -1 : 0,
                                      );
                                    }}
                                  />
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  <Checkbox
                                    value={
                                      values.attendance[row.beneficiary.cwp_id] !== null
                                        ? values.attendance[row.beneficiary.cwp_id] === 2
                                        : false
                                    }
                                    isEditable
                                    radio
                                    color="yellow"
                                    onChange={(e) => {
                                      setFieldValue(
                                        `attendance[${row.beneficiary.cwp_id}]`,
                                        e.target.checked ? 2 : 0,
                                      );
                                    }}
                                  />
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  <Checkbox
                                    value={
                                      values.attendance[row.beneficiary.cwp_id] !== null
                                        ? values.attendance[row.beneficiary.cwp_id] === -2
                                        : false
                                    }
                                    isEditable
                                    radio
                                    color="grey"
                                    onChange={(e) => {
                                      setFieldValue(
                                        `attendance[${row.beneficiary.cwp_id}]`,
                                        e.target.checked ? -2 : 0,
                                      );
                                    }}
                                  />
                                </StyledTableCell>
                                {presence.motivationalSystem && (
                                  <>
                                    <StyledTableCell align="center">
                                      <Rating
                                        readOnly={values.attendance[row.beneficiary.cwp_id] !== 1}
                                        name={`educational[${row.beneficiary.cwp_id}]`}
                                        icon={
                                          <CheckBoxRoundedIcon
                                            style={{
                                              fontSize: 22,
                                              color:
                                                values.attendance[row.beneficiary.cwp_id] === 1
                                                  ? theme.palette.color.blue.main
                                                  : theme.palette.grey[400],
                                            }}
                                          />
                                        }
                                        emptyIcon={
                                          <CheckBoxOutlineBlankRoundedIcon
                                            style={{
                                              fontSize: 22,
                                              color:
                                                values.attendance[row.beneficiary.cwp_id] === 1
                                                  ? theme.palette.color.blue.main
                                                  : theme.palette.grey[400],
                                            }}
                                          />
                                        }
                                        max={4}
                                        value={
                                          values.educational[row.beneficiary.cwp_id] !== null
                                            ? values.educational[row.beneficiary.cwp_id]
                                            : 0
                                        }
                                        onChange={(e, value) => {
                                          setFieldValue(
                                            `educational[${row.beneficiary.cwp_id}]`,
                                            value || 0,
                                          );
                                        }}
                                      />
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                      <Rating
                                        readOnly={values.attendance[row.beneficiary.cwp_id] !== 1}
                                        name={`social[${row.beneficiary.cwp_id}]`}
                                        icon={
                                          <CheckBoxRoundedIcon
                                            style={{
                                              fontSize: 22,
                                              color:
                                                values.attendance[row.beneficiary.cwp_id] === 1
                                                  ? theme.palette.color.yellow.main
                                                  : theme.palette.grey[400],
                                            }}
                                          />
                                        }
                                        emptyIcon={
                                          <CheckBoxOutlineBlankRoundedIcon
                                            style={{
                                              fontSize: 22,
                                              color:
                                                values.attendance[row.beneficiary.cwp_id] === 1
                                                  ? theme.palette.color.yellow.main
                                                  : theme.palette.grey[400],
                                            }}
                                          />
                                        }
                                        max={4}
                                        value={
                                          values.social[row.beneficiary.cwp_id] !== null
                                            ? values.social[row.beneficiary.cwp_id]
                                            : 0
                                        }
                                        onChange={(e, value) => {
                                          setFieldValue(
                                            `social[${row.beneficiary.cwp_id}]`,
                                            value || 0,
                                          );
                                        }}
                                      />
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                      <Rating
                                        readOnly={values.attendance[row.beneficiary.cwp_id] !== 1}
                                        name={`emotional[${row.beneficiary.cwp_id}]`}
                                        icon={
                                          <CheckBoxRoundedIcon
                                            style={{
                                              fontSize: 22,
                                              color:
                                                values.attendance[row.beneficiary.cwp_id] === 1
                                                  ? theme.palette.color.red.main
                                                  : theme.palette.grey[400],
                                            }}
                                          />
                                        }
                                        emptyIcon={
                                          <CheckBoxOutlineBlankRoundedIcon
                                            style={{
                                              fontSize: 22,
                                              color:
                                                values.attendance[row.beneficiary.cwp_id] === 1
                                                  ? theme.palette.color.red.main
                                                  : theme.palette.grey[400],
                                            }}
                                          />
                                        }
                                        max={4}
                                        value={
                                          values.emotional[row.beneficiary.cwp_id] !== null
                                            ? values.emotional[row.beneficiary.cwp_id]
                                            : 0
                                        }
                                        onChange={(e, value) => {
                                          setFieldValue(
                                            `emotional[${row.beneficiary.cwp_id}]`,
                                            value || 0,
                                          );
                                        }}
                                      />
                                    </StyledTableCell>
                                  </>
                                )}
                              </TableRow>
                            );
                          })}
                        </StyledTableBody>
                      </Table>
                    </TableContainer>
                    <Grid item xs={12} container justifyContent="flex-end">
                      <Button onClick={() => setLogCheck(false)}>WRÓĆ</Button>
                      <Button onClick={handleSubmit} variant="outlined">
                        ZAPISZ
                      </Button>
                    </Grid>
                  </>
                );
              }}
            </Formik>
          </Grid>
        </StyledWrapper>
      )}
    </>
  );
};

ProgramPresencePWD.propTypes = {
  clearPresence: PropTypes.func,
  fetchPWDPresence: PropTypes.func,
  presence: PropTypes.shape({
    beneficiaries: PropTypes.arrayOf(PropTypes.any),
    dates: PropTypes.arrayOf(PropTypes.any),
    motivationalSystem: PropTypes.bool,
  }),
  saveAttendanceList: PropTypes.func,
};

ProgramPresencePWD.defaultProps = {
  clearPresence: null,
  fetchPWDPresence: null,
  presence: { active: [], beneficiaries: [], dates: [], motivationalSystem: null },
  saveAttendanceList: null,
};

const mapStateToProps = ({ programReducer }) => ({
  presence: programReducer.presence,
});

const mapDispatchToProps = (dispatch) => ({
  clearPresence: () => dispatch(clearPresenceService()),
  fetchPWDPresence: (id, startDate, endDate) =>
    dispatch(fetchPWDPresenceService(id, startDate, endDate)),
  saveAttendanceList: (id, values) => dispatch(saveAttendanceListService(id, values)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProgramPresencePWD);
