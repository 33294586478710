// Styles
import styled from 'styled-components';

export const StyledBox = styled.div`
  && {
    position: relative;
    width: 100%;

    background-color: ${({ theme }) => theme.color.blue};
  }
`;
