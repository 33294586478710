// React
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';

// React SVG
import { ReactSVG } from 'react-svg';

// Formik
import { Formik } from 'formik';

import { format } from 'date-fns';
import { pl } from 'date-fns/locale';

// Services
import {
  fetchActionPresence as fetchActionPresenceService,
  saveAttendanceList as saveAttendanceListService,
  saveEstimatedParticipants as saveEstimatedParticipantsService,
} from '@services/actionServices';

// Actions
import { clearPresenceAction as clearPresenceService } from '@actions/actionActions';

// Data
import { actionAttendance } from '@constants/selectLists';

// Assets
import PlaylistAddCheckOutlinedIcon from '@mui/icons-material/PlaylistAddCheckOutlined';
import CheckBoxOutlineBlankRoundedIcon from '@mui/icons-material/CheckBoxOutlineBlankRounded';
import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded';

// Elements
import {
  Box,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Rating,
  Tooltip,
} from '@mui/material';
import Input from '@components/atoms/Input/Input';
import Heading from '@components/atoms/Heading/Heading';
import Select from '@components/atoms/Select/Select';
import Button from '@components/atoms/Button/Button';
import Checkbox from '@components/atoms/Checkbox/Checkbox';
import CalendarRange from '@components/atoms/CalendarRange/CalendarRange';

// Styles
import { useTheme } from '@mui/material/styles';
import {
  StyledHeading,
  StyledButton,
  StyledTableBody,
  StyledTableHead,
  StyledTableRow,
  StyledTableCell,
  StyledWrapper,
  StyledTableCellSticky,
  StyledTableCellStickyRight,
  StyledTableText,
} from './ActionPresence.styles';

// Component
const ActionPresence = ({
  clearPresence,
  fetchPresence,
  presenceType,
  presence,
  saveAttendanceList,
  saveEstimatedParticipants,
}) => {
  const { id } = useParams();
  const { beneficiaries, dates, wasAnonymized } = presence;
  const theme = useTheme();
  const [unfinished, setUnfinished] = useState(false);
  const [selectValue, setSelectValue] = useState(1);
  const [logCheck, setLogCheck] = useState(false);
  const [rangeCalendarOpen, setRangeCalendarOpen] = useState(false);
  const [rangeCalendarDates, setRangeCalendarDates] = useState({});
  const [initialRangeCalendarDates, setInitialRangeCalendarDates] = useState({});
  const [filteredDates, setFilteredDates] = useState([]);
  const { startDate: rangeStartDate, endDate: rangeEndDate } = rangeCalendarDates;
  const [attendancePeople, setAttendancePeople] = useState({});

  useEffect(() => {
    const newState = {};
    filteredDates.map(
      ({ attendance_list_id, estimatedParticipants, estimatedParticipantsNumber }) => {
        newState[attendance_list_id] = {
          precise: !!estimatedParticipants,
          number: estimatedParticipantsNumber ?? 0,
        };
        return true;
      },
    );

    setAttendancePeople(newState);
  }, [filteredDates]);

  let oneDayAfterEndDate;

  if (rangeCalendarDates) {
    oneDayAfterEndDate = new Date(rangeEndDate);
    oneDayAfterEndDate.setDate(oneDayAfterEndDate.getDate() + 1);
  }

  useEffect(() => {
    clearPresence();
  }, []);

  const triggerFetchPresence = useCallback(
    (myid, startDate, endDate) => fetchPresence(myid, startDate, endDate),
    [fetchPresence],
  );

  useEffect(() => {
    if (rangeCalendarDates.startDate && rangeCalendarDates.endDate) {
      triggerFetchPresence(id, rangeCalendarDates.startDate, rangeCalendarDates.endDate);
    }
  }, [rangeCalendarDates, id]);

  useEffect(() => {
    if (dates && dates.length) {
      setInitialRangeCalendarDates({
        startDate: new Date(dates[0].date),
        endDate: new Date(
          new Date(dates[dates.length - 1].date).setDate(
            new Date(dates[dates.length - 1].date).getDate() + 1,
          ),
        ),
      });
      // setInitialRangeCalendarDates({
      //   startDate: new Date(dates[0].date),
      //   endDate: new Date(dates[dates.length - 1].date),
      // });
    }
  }, [dates]);

  useEffect(() => {
    setRangeCalendarDates({});
    triggerFetchPresence(id, null, null);
  }, [id]);

  useEffect(() => {
    if (rangeCalendarDates.startDate && rangeCalendarDates.endDate) {
      setFilteredDates(
        dates.filter((d) => {
          const dTime = new Date(d.date).getTime();
          if (
            (rangeCalendarDates &&
              rangeCalendarDates.startDate &&
              rangeCalendarDates.endDate &&
              (dTime < rangeStartDate.getTime() || dTime > oneDayAfterEndDate.getTime())) ||
            (unfinished && (d.status === 2 || d.status === 3))
          ) {
            return false;
          }

          return true;
        }),
      );
    } else {
      setFilteredDates(dates);
    }
  }, [dates, rangeCalendarDates, unfinished]);

  return (
    <>
      {!logCheck ? (
        <>
          <Box display="flex" alignItems="center">
            <Button
              variant={unfinished ? 'outlined' : 'text'}
              startIcon={<PlaylistAddCheckOutlinedIcon />}
              onClick={() => {
                setUnfinished(!unfinished);
              }}
            >
              Tylko niewypełnione
            </Button>
            {dates && initialRangeCalendarDates && initialRangeCalendarDates.startDate && (
              <CalendarRange
                initialDateRange={initialRangeCalendarDates}
                open={rangeCalendarOpen}
                setRangeCalendarOpen={setRangeCalendarOpen}
                setRangeCalendarDates={setRangeCalendarDates}
                rangeCalendarDates={
                  rangeCalendarDates.startDate && rangeCalendarDates.endDate
                    ? rangeCalendarDates
                    : initialRangeCalendarDates
                }
                // minDate={new Date(dates[0].date) || null}
                // maxDate={new Date(dates[dates.length - 1].date) || null}
              />
            )}
          </Box>
          {dates &&
            initialRangeCalendarDates &&
            initialRangeCalendarDates.startDate &&
            rangeCalendarDates &&
            dates.length === 0 && (
              <p>
                Brak danych o obecności w wybranym okresie{' '}
                {rangeCalendarDates &&
                  rangeCalendarDates.startDate &&
                  rangeCalendarDates.startDate.toLocaleDateString()}{' '}
                -{' '}
                {rangeCalendarDates &&
                  rangeCalendarDates.endDate &&
                  rangeCalendarDates.endDate.toLocaleDateString()}
                . Wybierz inny zakres dat, aby zobaczyć obecność uczestników.
              </p>
            )}
          <TableContainer>
            <Table>
              <StyledTableHead>
                <TableRow>
                  <StyledTableCellSticky>
                    <Heading.Body2>Nazwisko Imię</Heading.Body2>
                  </StyledTableCellSticky>
                  {filteredDates.map(({ attendance_list_id, date, status }) => {
                    return (
                      <StyledTableCell key={attendance_list_id} align="center">
                        <StyledHeading status={status}>
                          {format(new Date(date), 'eeee', { locale: pl })} <br />
                          {format(new Date(date), 'dd/MM/yyyy', { locale: pl })}
                        </StyledHeading>
                      </StyledTableCell>
                    );
                  })}
                  <StyledTableCellStickyRight>
                    <Select
                      data={actionAttendance}
                      selectValue={selectValue}
                      name="selectValue"
                      onChange={(text, value) => setSelectValue(value)}
                      noBorder
                    />
                  </StyledTableCellStickyRight>
                </TableRow>
              </StyledTableHead>
              <StyledTableBody>
                {beneficiaries.map((beneficiaryRow, index) => {
                  const { beneficiary, attendance } = beneficiaryRow;
                  return (
                    <TableRow key={beneficiary.cwa_id}>
                      <StyledTableCellSticky>
                        <Tooltip
                          title={
                            <span style={{ fontSize: '14px' }}>
                              {beneficiary.dates_in_action_end}
                            </span>
                          }
                          placement="top"
                        >
                          <Heading.Body2
                            style={{
                              opacity:
                                beneficiary.dates_in_action_end &&
                                beneficiary.dates_in_action_end.includes(' do')
                                  ? 0.5
                                  : 1,
                            }}
                          >
                            {index + 1}. {beneficiary.surname} {beneficiary.name}
                          </Heading.Body2>
                        </Tooltip>
                      </StyledTableCellSticky>
                      {filteredDates.map((item) => {
                        if (beneficiaryRow.active[item.attendance_list_id]) {
                          switch (attendance[item.attendance_list_id]) {
                            case -1:
                              return (
                                <StyledTableCell align="center" key={item.attendance_list_id}>
                                  <ReactSVG src="/img/absence.svg" />
                                </StyledTableCell>
                              );
                            case 1:
                              return (
                                <StyledTableCell align="center" key={item.attendance_list_id}>
                                  <ReactSVG src="/img/attendance.svg" />
                                </StyledTableCell>
                              );
                            case 2:
                              return (
                                <StyledTableCell align="center" key={item.attendance_list_id}>
                                  <ReactSVG src="/img/excused-absence.svg" />
                                </StyledTableCell>
                              );
                            case -2:
                              return (
                                <StyledTableCell align="center" key={item.attendance_list_id}>
                                  <ReactSVG src="/img/not-applicable.svg" />
                                </StyledTableCell>
                              );
                            default:
                              return <StyledTableCell key={item.attendance_list_id} />;
                          }
                        }

                        return (
                          <StyledTableCell align="center" key={item.attendance_list_id}>
                            -
                          </StyledTableCell>
                        );
                      })}
                      <StyledTableCellStickyRight>
                        <StyledHeading status>
                          {(() => {
                            switch (selectValue) {
                              case 1:
                                return Object.keys(attendance).reduce(
                                  (sum, key) =>
                                    sum +
                                    (attendance[key] != null && attendance[key] === 1 ? 1 : 0),
                                  0,
                                );
                              case 2:
                                return Object.keys(attendance).reduce(
                                  (sum, key) =>
                                    sum +
                                    (attendance[key] != null && attendance[key] === 2 ? 1 : 0),
                                  0,
                                );
                              case -1:
                                return Object.keys(attendance).reduce(
                                  (sum, key) =>
                                    sum +
                                    (attendance[key] != null && attendance[key] === -1 ? 1 : 0),
                                  0,
                                );
                              default:
                                return null;
                            }
                          })()}
                        </StyledHeading>
                      </StyledTableCellStickyRight>
                    </TableRow>
                  );
                })}
                <StyledTableRow>
                  <StyledTableCellSticky colSpan={1}>
                    <Heading.Subtitle2 align="center">Suma obecności</Heading.Subtitle2>
                  </StyledTableCellSticky>
                  {filteredDates.map(({ status, attendance_list_id }) => {
                    return (
                      <StyledTableCell key={attendance_list_id}>
                        <Heading.Subtitle2 align="center">
                          {status
                            ? beneficiaries.reduce(
                                (sum, item) =>
                                  sum +
                                  (item.attendance[attendance_list_id] === 1
                                    ? item.attendance[attendance_list_id]
                                    : 0),
                                0,
                              )
                            : '0'}
                        </Heading.Subtitle2>
                      </StyledTableCell>
                    );
                  })}
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCellSticky colSpan={1} align="center" />
                  {filteredDates.map(({ status, attendance_list_id, date }) => {
                    return status && !wasAnonymized ? (
                      <StyledTableCell key={attendance_list_id} align="center">
                        {presenceType === 'OPEN_PARTY' && attendancePeople[attendance_list_id] && (
                          <Checkbox
                            isEditable
                            label="Szacunkowo"
                            value={attendancePeople[attendance_list_id].precise}
                            onChange={() => {
                              setAttendancePeople((prevState) => ({
                                ...prevState,
                                [attendance_list_id]: {
                                  ...prevState[attendance_list_id],
                                  precise: !attendancePeople[attendance_list_id].precise,
                                },
                              }));
                              if (attendancePeople[attendance_list_id].precise) {
                                saveEstimatedParticipants(attendance_list_id, {
                                  number: 0,
                                  precise: !attendancePeople[attendance_list_id].precise,
                                });
                              }
                            }}
                          />
                        )}
                        {attendancePeople &&
                        attendancePeople[attendance_list_id] &&
                        !attendancePeople[attendance_list_id].precise ? (
                          <StyledButton
                            onClick={() =>
                              setLogCheck({
                                id: attendance_list_id,
                                name: `${format(new Date(date), 'dddd', { locale: pl })}, ${date}`,
                              })
                            }
                            align="center"
                            variant="contained"
                          >
                            {status === 1 ? 'Sprawdź' : 'Edytuj'}
                          </StyledButton>
                        ) : (
                          <>
                            {attendancePeople[attendance_list_id] && (
                              <Box display="flex">
                                <Input
                                  disabled={!attendancePeople[attendance_list_id].editable}
                                  label="Liczba osób"
                                  variant="filled"
                                  type="number"
                                  min={0}
                                  step={1}
                                  style={{ width: '8rem' }}
                                  value={
                                    attendancePeople &&
                                    attendancePeople[attendance_list_id] &&
                                    attendancePeople[attendance_list_id].number
                                  }
                                  onChange={(e) => {
                                    setAttendancePeople((prevState) => ({
                                      ...prevState,
                                      [attendance_list_id]: {
                                        ...prevState[attendance_list_id],
                                        number: e.target.value,
                                      },
                                    }));
                                  }}
                                />
                                {attendancePeople[attendance_list_id].editable ? (
                                  <StyledButton
                                    onClick={() => {
                                      saveEstimatedParticipants(
                                        attendance_list_id,
                                        attendancePeople[attendance_list_id],
                                      );
                                      setAttendancePeople((prevState) => ({
                                        ...prevState,
                                        [attendance_list_id]: {
                                          ...prevState[attendance_list_id],
                                          editable: false,
                                        },
                                      }));
                                    }}
                                    align="center"
                                    variant="contained"
                                    style={{ marginLeft: 16 }}
                                  >
                                    Zapisz
                                  </StyledButton>
                                ) : (
                                  <StyledButton
                                    onClick={() => {
                                      setAttendancePeople((prevState) => ({
                                        ...prevState,
                                        [attendance_list_id]: {
                                          ...prevState[attendance_list_id],
                                          editable: true,
                                        },
                                      }));
                                    }}
                                    align="center"
                                    variant="contained"
                                    style={{ marginLeft: 16 }}
                                  >
                                    Edytuj
                                  </StyledButton>
                                )}
                              </Box>
                            )}
                          </>
                        )}
                      </StyledTableCell>
                    ) : null;
                  })}
                </StyledTableRow>
              </StyledTableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <StyledWrapper container spacing={2}>
          <Grid item xs={12}>
            <Heading.Body1>{logCheck.name}</Heading.Body1>
          </Grid>
          <Grid item xs={12}>
            <Formik
              initialValues={{
                attendance: beneficiaries.reduce((map, row) => {
                  const newMap = map;
                  newMap[row.beneficiary.cwa_id] = row.attendance[logCheck.id];
                  return newMap;
                }, {}),
                educational: beneficiaries.reduce((map, row) => {
                  const newMap = map;
                  newMap[row.beneficiary.cwa_id] =
                    logCheck && row.educational && row.educational[logCheck.id]
                      ? row.educational[logCheck.id]
                      : 0;
                  return newMap;
                }, {}),
                social: beneficiaries.reduce((map, row) => {
                  const newMap = map;
                  newMap[row.beneficiary.cwa_id] =
                    logCheck && row.social && row.social[logCheck.id] ? row.social[logCheck.id] : 0;
                  return newMap;
                }, {}),
                emotional: beneficiaries.reduce((map, row) => {
                  const newMap = map;
                  newMap[row.beneficiary.cwa_id] =
                    logCheck && row.emotional && row.emotional[logCheck.id]
                      ? row.emotional[logCheck.id]
                      : 0;
                  return newMap;
                }, {}),
              }}
              enableReinitialize
              onSubmit={(values) => {
                saveAttendanceList(logCheck.id, values).then(() => {
                  fetchPresence(id, rangeCalendarDates.startDate, rangeCalendarDates.endDate);
                  setLogCheck(false);
                });
              }}
            >
              {({ values, handleSubmit, setFieldValue }) => {
                return (
                  <>
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <StyledTableCell>
                              <Heading.Body2>L.p.</Heading.Body2>
                            </StyledTableCell>
                            <StyledTableCell>
                              <Heading.Body2>Nazwisko</Heading.Body2>
                            </StyledTableCell>
                            <StyledTableCell>
                              <Heading.Body2>Imię</Heading.Body2>
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Checkbox
                                value={false}
                                label="Obecność"
                                isEditable
                                radio
                                color="blue"
                                onChange={(e) =>
                                  beneficiaries.map((row) => {
                                    if (row.active[logCheck.id]) {
                                      setFieldValue(
                                        `attendance[${row.beneficiary.cwa_id}]`,
                                        e.target.checked ? 1 : 0,
                                      );
                                    }
                                    return true;
                                  })
                                }
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Checkbox
                                value={false}
                                label="Nieobecność"
                                isEditable
                                radio
                                color="red"
                                onChange={(e) =>
                                  beneficiaries.map((row) => {
                                    if (row.active[logCheck.id]) {
                                      setFieldValue(
                                        `attendance[${row.beneficiary.cwa_id}]`,
                                        e.target.checked ? -1 : 0,
                                      );
                                    }
                                    return true;
                                  })
                                }
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Checkbox
                                value={false}
                                label="Usprawiedliwione"
                                isEditable
                                radio
                                color="yellow"
                                onChange={(e) =>
                                  beneficiaries.map((row) => {
                                    if (row.active[logCheck.id]) {
                                      setFieldValue(
                                        `attendance[${row.beneficiary.cwa_id}]`,
                                        e.target.checked ? 2 : 0,
                                      );
                                    }
                                    return true;
                                  })
                                }
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Checkbox
                                value={false}
                                label="Nie dotyczy"
                                isEditable
                                radio
                                color="grey"
                                onChange={(e) =>
                                  beneficiaries.map((row) => {
                                    if (row.active[logCheck.id]) {
                                      setFieldValue(
                                        `attendance[${row.beneficiary.cwa_id}]`,
                                        e.target.checked ? -2 : 0,
                                      );
                                    }
                                    return true;
                                  })
                                }
                              />
                            </StyledTableCell>
                            {presenceType === 'ATTENDANCE' && presence.motivationalSystem && (
                              <>
                                <StyledTableCell align="center">
                                  <StyledTableText>Skala edukacyjna</StyledTableText>
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  <StyledTableText>Skala społeczna</StyledTableText>
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  <StyledTableText>Skala emocjonalna</StyledTableText>
                                </StyledTableCell>
                              </>
                            )}
                          </TableRow>
                        </TableHead>
                        <StyledTableBody>
                          {beneficiaries.map((row, index) => (
                            <TableRow key={row.beneficiary.cwa_id}>
                              <StyledTableCell align="center">
                                <Heading.Body2> {index + 1}. </Heading.Body2>
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                <Heading.Body2> {row.beneficiary.surname} </Heading.Body2>
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                <Heading.Body2>{row.beneficiary.name} </Heading.Body2>
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {row.active[logCheck.id] && (
                                  <Checkbox
                                    value={
                                      values.attendance[row.beneficiary.cwa_id] !== null
                                        ? values.attendance[row.beneficiary.cwa_id] === 1
                                        : false
                                    }
                                    isEditable
                                    radio
                                    color="blue"
                                    onChange={(e) => {
                                      setFieldValue(
                                        `attendance[${row.beneficiary.cwa_id}]`,
                                        e.target.checked ? 1 : 0,
                                      );
                                    }}
                                  />
                                )}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {row.active[logCheck.id] && (
                                  <Checkbox
                                    value={
                                      values.attendance[row.beneficiary.cwa_id] !== null
                                        ? values.attendance[row.beneficiary.cwa_id] === -1
                                        : false
                                    }
                                    isEditable
                                    radio
                                    color="red"
                                    onChange={(e) => {
                                      setFieldValue(
                                        `attendance[${row.beneficiary.cwa_id}]`,
                                        e.target.checked ? -1 : 0,
                                      );
                                    }}
                                  />
                                )}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {row.active[logCheck.id] && (
                                  <Checkbox
                                    value={
                                      values.attendance[row.beneficiary.cwa_id] !== null
                                        ? values.attendance[row.beneficiary.cwa_id] === 2
                                        : false
                                    }
                                    isEditable
                                    radio
                                    color="yellow"
                                    onChange={(e) => {
                                      setFieldValue(
                                        `attendance[${row.beneficiary.cwa_id}]`,
                                        e.target.checked ? 2 : 0,
                                      );
                                    }}
                                  />
                                )}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {row.active[logCheck.id] && (
                                  <Checkbox
                                    value={
                                      values.attendance[row.beneficiary.cwa_id] !== null
                                        ? values.attendance[row.beneficiary.cwa_id] === -2
                                        : false
                                    }
                                    isEditable
                                    radio
                                    color="grey"
                                    onChange={(e) => {
                                      setFieldValue(
                                        `attendance[${row.beneficiary.cwa_id}]`,
                                        e.target.checked ? -2 : 0,
                                      );
                                    }}
                                  />
                                )}
                              </StyledTableCell>
                              {presenceType === 'ATTENDANCE' && presence.motivationalSystem && (
                                <>
                                  <StyledTableCell align="center">
                                    {row.active[logCheck.id] && (
                                      <Rating
                                        readOnly={values.attendance[row.beneficiary.cwa_id] !== 1}
                                        name={`educational[${row.beneficiary.cwa_id}]`}
                                        icon={
                                          <CheckBoxRoundedIcon
                                            style={{
                                              fontSize: 22,
                                              color:
                                                values.attendance[row.beneficiary.cwa_id] === 1
                                                  ? theme.palette.color.blue.main
                                                  : theme.palette.grey[400],
                                            }}
                                          />
                                        }
                                        emptyIcon={
                                          <CheckBoxOutlineBlankRoundedIcon
                                            style={{
                                              fontSize: 22,
                                              color:
                                                values.attendance[row.beneficiary.cwa_id] === 1
                                                  ? theme.palette.color.blue.main
                                                  : theme.palette.grey[400],
                                            }}
                                          />
                                        }
                                        max={4}
                                        value={
                                          values.educational[row.beneficiary.cwa_id] !== null
                                            ? values.educational[row.beneficiary.cwa_id]
                                            : 0
                                        }
                                        onChange={(e, value) => {
                                          setFieldValue(
                                            `educational[${row.beneficiary.cwa_id}]`,
                                            value || 0,
                                          );
                                        }}
                                      />
                                    )}
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    {row.active[logCheck.id] && (
                                      <Rating
                                        readOnly={values.attendance[row.beneficiary.cwa_id] !== 1}
                                        name={`social[${row.beneficiary.cwa_id}]`}
                                        icon={
                                          <CheckBoxRoundedIcon
                                            style={{
                                              fontSize: 22,
                                              color:
                                                values.attendance[row.beneficiary.cwa_id] === 1
                                                  ? theme.palette.color.yellow.main
                                                  : theme.palette.grey[400],
                                            }}
                                          />
                                        }
                                        emptyIcon={
                                          <CheckBoxOutlineBlankRoundedIcon
                                            style={{
                                              fontSize: 22,
                                              color:
                                                values.attendance[row.beneficiary.cwa_id] === 1
                                                  ? theme.palette.color.yellow.main
                                                  : theme.palette.grey[400],
                                            }}
                                          />
                                        }
                                        max={4}
                                        value={
                                          values.social[row.beneficiary.cwa_id] !== null
                                            ? values.social[row.beneficiary.cwa_id]
                                            : 0
                                        }
                                        onChange={(e, value) => {
                                          setFieldValue(
                                            `social[${row.beneficiary.cwa_id}]`,
                                            value || 0,
                                          );
                                        }}
                                      />
                                    )}
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    {row.active[logCheck.id] && (
                                      <Rating
                                        readOnly={values.attendance[row.beneficiary.cwa_id] !== 1}
                                        name={`emotional[${row.beneficiary.cwa_id}]`}
                                        icon={
                                          <CheckBoxRoundedIcon
                                            style={{
                                              fontSize: 22,
                                              color:
                                                values.attendance[row.beneficiary.cwa_id] === 1
                                                  ? theme.palette.color.red.main
                                                  : theme.palette.grey[400],
                                            }}
                                          />
                                        }
                                        emptyIcon={
                                          <CheckBoxOutlineBlankRoundedIcon
                                            style={{
                                              fontSize: 22,
                                              color:
                                                values.attendance[row.beneficiary.cwa_id] === 1
                                                  ? theme.palette.color.red.main
                                                  : theme.palette.grey[400],
                                            }}
                                          />
                                        }
                                        max={4}
                                        value={
                                          values.emotional[row.beneficiary.cwa_id] !== null
                                            ? values.emotional[row.beneficiary.cwa_id]
                                            : 0
                                        }
                                        onChange={(e, value) => {
                                          setFieldValue(
                                            `emotional[${row.beneficiary.cwa_id}]`,
                                            value || 0,
                                          );
                                        }}
                                      />
                                    )}
                                  </StyledTableCell>
                                </>
                              )}
                            </TableRow>
                          ))}
                        </StyledTableBody>
                      </Table>
                    </TableContainer>

                    <Grid item xs={12} container justifyContent="flex-end">
                      <Button onClick={() => setLogCheck(false)}>WRÓĆ</Button>
                      <Button onClick={handleSubmit} variant="outlined">
                        ZAPISZ
                      </Button>
                    </Grid>
                  </>
                );
              }}
            </Formik>
          </Grid>
        </StyledWrapper>
      )}
    </>
  );
};

ActionPresence.propTypes = {
  clearPresence: PropTypes.func,
  fetchPresence: PropTypes.func,
  presence: PropTypes.shape({
    active: PropTypes.arrayOf(PropTypes.any),
    beneficiaries: PropTypes.arrayOf(PropTypes.any),
    dates: PropTypes.arrayOf(PropTypes.any),
    motivationalSystem: PropTypes.bool,
    wasAnonymized: PropTypes.bool,
  }),
  presenceType: PropTypes.string,
  saveAttendanceList: PropTypes.func,
  saveEstimatedParticipants: PropTypes.func,
};

ActionPresence.defaultProps = {
  clearPresence: null,
  fetchPresence: null,
  presence: {
    active: [],
    beneficiaries: [],
    dates: [],
    motivationalSystem: null,
    wasAnonymized: false,
  },
  presenceType: '',
  saveAttendanceList: null,
  saveEstimatedParticipants: null,
};

const mapStateToProps = ({ actionReducer }) => ({
  presenceType: actionReducer.action.build_in_type,
  presence: actionReducer.presence,
});

const mapDispatchToProps = (dispatch) => ({
  clearPresence: () => dispatch(clearPresenceService()),
  fetchPresence: (id, startDate, endDate) =>
    dispatch(fetchActionPresenceService(id, startDate, endDate)),
  saveAttendanceList: (id, values) => dispatch(saveAttendanceListService(id, values)),
  saveEstimatedParticipants: (id, value) => dispatch(saveEstimatedParticipantsService(id, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ActionPresence);
