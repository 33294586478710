export const groupBy = [
  {
    id: 1,
    label: 'Programu',
    name: 'program',
    value: false,
  },
  {
    id: 2,
    label: 'Organizacji',
    name: 'organization',
    value: false,
  },
  {
    id: 3,
    label: 'Rodzaj działania',
    name: 'action_type',
    value: false,
  },
  {
    id: 4,
    label: 'Nazwa działania',
    name: 'action_name',
    value: false,
  },
  {
    id: 5,
    label: 'Szkoły',
    name: 'school',
    value: false,
  },
  {
    id: 6,
    label: 'PWD dziecka',
    name: 'pwd',
    value: false,
  },
  {
    id: 7,
    label: 'Intensywność wsparcia',
    name: 'support',
    value: false,
  },
];

export const filterBy = [
  {
    id: 1,
    label: 'Program',
    name: 'program',
  },
  {
    id: 2,
    label: 'Organizacja',
    name: 'organization',
  },
  {
    id: 18,
    label: 'Kategoria zadanie',
    name: 'task_type',
  },
  {
    id: 3,
    label: 'Zadanie',
    name: 'task',
  },
  {
    id: 16,
    label: 'Działanie organizacji',
    name: 'action_organization',
  },
  {
    id: 4,
    label: 'Typ działania',
    name: 'action_buildintype',
  },
  {
    id: 17,
    label: 'Grupa działań',
    name: 'action_group',
  },
  {
    id: 5,
    label: 'Nazwa działania',
    name: 'action_name',
  },
  {
    id: 6,
    label: 'Szkoła',
    name: 'school',
  },
  {
    id: 7,
    label: 'PWD',
    name: 'pwd',
  },
  // {
  //   id: 8,
  //   label: 'Intensywność wsparcia',
  //   name: 'support',
  // },
  {
    id: 9,
    label: 'Dzielnica',
    name: 'district',
  },
  {
    id: 10,
    label: 'Wskaźniki',
    name: 'indicator',
  },
  {
    id: 11,
    label: 'Przedział czasowy pomiaru',
    name: 'range_date',
  },
  {
    id: 12,
    label: 'I Pomiar',
    name: 'metric_1',
  },
  {
    id: 13,
    label: 'II Pomiar',
    name: 'metric_2',
  },
  {
    id: 14,
    label: 'Rodzaj wskaźnika',
    name: 'indicatorKind',
  },
  {
    id: 15,
    label: 'Beneficjenci',
    name: 'beneficiaries',
  },
];

export const switchBy = [
  {
    id: 1,
    label: 'Uczestnicy 3 lub więcej rodzajów wsparcia',
    name: 'three_or_more',
    value: false,
  },
  {
    id: 2,
    label: 'Uczestnicy działań kluczowych',
    name: 'key_action',
    value: false,
  },
  {
    id: 3,
    label: 'Uczestnicy wytypowani przez szkoły',
    name: 'assigned_to_program_by_school',
    value: false,
  },
  {
    id: 4,
    label: 'Tylko uczestnicy o wsparciu regularnym',
    name: 'wsparcie_regularne',
    value: false,
  },
];
