/* eslint-disable react/no-array-index-key */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import Week from './Week';
import { defaultUtils as utils } from './dateUtils';

class Weeks extends Component {
  renderWeeks = () => {
    const weekArray = utils.getWeekArray(
      this.props.displayDate,
      1, // ustalamy poniedziałek jako pierwszy dzień tygodnia
    );

    return weekArray.map(
      (s, i) => (
        <Week
          key={i}
          week={s}
          selectedDates={this.props.selectedDates}
          disabledDates={this.props.disabledDates}
          onSelect={this.props.onSelect}
          minDate={this.props.minDate}
          maxDate={this.props.maxDate}
          readOnly={this.props.readOnly}
        />
      ),
      this,
    );
  };

  render() {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          lineHeight: '1.25',
          position: 'relative',
        }}
      >
        {this.renderWeeks(this.props.displayDate)}
      </div>
    );
  }
}

export default Weeks;
