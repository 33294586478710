// React
import React from 'react';
import PropTypes from 'prop-types';

// Elements
import Heading from '@components/atoms/Heading/Heading';

// Styles
import { StyledBox } from './ChartItem.styles';

const ChartItem = ({ title, data, percentage }) => (
  <StyledBox style={{ height: `${percentage}%` }}>
    <Heading.ChartValue>{data}</Heading.ChartValue>
    <Heading.ChartDesc>{title}</Heading.ChartDesc>
  </StyledBox>
);

ChartItem.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.number.isRequired,
  percentage: PropTypes.number.isRequired,
};

export default ChartItem;
